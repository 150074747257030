import React, { useEffect, useRef, useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getExpandedRowModel,
} from '@tanstack/react-table';
import { useStateCtx } from '../context/appContext';
import {
  NumberCell,
  handleBreakEvenOperationDeleteConfirm,
  handleDeleteConfirm,
} from '../utils/tableHandlers';
import { useAuth } from '../context/auth';
import {
  ConfirmationModalContent,
} from '../utils/tableHelpers';

import { toast } from 'react-toastify';
import { calculateLeftOffset, calculateWidth, groupTradesByMonth, groupTradesByTicker } from '../utils/mathHelpers';
import { BsQuestionOctagon } from 'react-icons/bs';
import TableFooter from '../components/Table/TableFooter';
import TableHeader from '../components/Table/TableHeader';
import TableActionControls from '../components/TableToolBar/TableActionControls';
import SearchAndColumnToggle from '../components/TableToolBar/SearchAndColumnToggle';
import DateRangePicker from '../components/TableToolBar/DateRangePicker';
import { MdHistory } from 'react-icons/md';
import { fetchClosedTrades } from '../services/historyTradesSevices';
import { fetchCapitalByDateRange } from '../services/capitalServices';
import { guidedTutorialHandler } from '../utils/GuidedTutorialFun';
import { BreakEvenColumns } from '../constants/BreakEvenColumns';
import { createNewBreakEvenTrade } from '../constants/newRowsData';
import BreakEvenTableBody from '../components/Table/BreakEvenBody';
import { fetchBreakEven, saveBreakEvenOperation, updateBreakEvenOperation } from '../services/breakevenServices';
import { guidedBreakEven } from '../constants/guidedTutorialData';

function BreakEven() {

  // CONTEXT
  // const { } = useAuth();
  const { token, logout, expanded, setExpanded, visitedPages, markPageAsVisited, visitedPagesLoading } = useAuth();
  const { screenSize, activeMenu, currentColor } = useStateCtx();
  // STATES
  const [data, setData] = useState([]); // use this as your data source
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [contextMenuState, setContextMenuState] = useState(false);
  const [colorsKeyMapState, setColorsKeyMapState] = useState(false)
  const [columnVisibility, setColumnVisibility] = useState({});

  const [columnOrder, setColumnOrder] = useState([]);
  const [sort, setSort] = useState(true);
  const [newRow, setNewRow] = useState(false);
  const [footerSums, setFooterSums] = useState({
    totalPL: 0,
  });
  const pageName = 'BreakEven'; // Define a unique name for this page

  useEffect(() => {
    if (!visitedPagesLoading) {
      // Check if the page has not been visited and trigger the tutorial
      if (!visitedPages[pageName] || visitedPages[pageName] === false) {
        guidedTutorialHandler(guidedBreakEven);
        markPageAsVisited(pageName); // Mark the page as visited
      }
    }
  }, [visitedPages, markPageAsVisited, pageName, visitedPagesLoading]); // Dependencies for useEffect


  const handleDelete = (trade) => {
    setModalState({ isOpen: true, trade });
  };
  const useSkipper = () => {
    const shouldSkipRef = React.useRef(true);
    const shouldSkip = shouldSkipRef.current;

    // Wrap a function with this to skip a pagination reset temporarily
    const skip = React.useCallback(() => {
      shouldSkipRef.current = false;
    }, []);

    useEffect(() => {
      shouldSkipRef.current = true;
    });

    return [shouldSkip, skip];
  };
  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();

  const updateData = React.useCallback(async ({ rowId, rowIndex, columnId, value }) => {
    // Use skip function to preserve table state like pagination
    skipAutoResetPageIndex();

    let previousValue;

    // Optimistically update the state
    setData(oldData => oldData.map(group => {
      if (group.id === rowId) {
        previousValue = group[columnId];
        return { ...group, [columnId]: value };
      }

      if (group.isGroup) {
        const subRows = group.subRows.map((subRow, index) => {
          // Ignore updates to the first two rows which are static
          if (index < 2) return subRow;

          // Optimistically update the row if it matches the rowId or is a newly added row without a stable ID
          if (subRow?.id?.includes("new") && rowIndex === index) {
            previousValue = subRow[columnId];
            return { ...subRow, [columnId]: value };
          }

          if (subRow._id === rowId && rowIndex === index) {
            previousValue = subRow[columnId];
            // Optimistically update the existing subRow
            const updatedSubRow = { ...subRow, [columnId]: value };

            // Call the backend to update the existing subRow
            updateBreakEvenOperation({ rowId: subRow._id, columnId, value, token })
              .then(updatedRow => {
                toast.success('Cell updated successfully');
              })
              .catch(error => {
                console.error('Failed to update row in backend:', error);
                // Revert to the previous value if the backend call fails
                setData(oldData => oldData.map(group => {
                  if (group.id === rowId) {
                    return { ...group, [columnId]: previousValue };
                  }

                  if (group.isGroup) {
                    const subRows = group.subRows.map((subRow, index) => {
                      if (index < 2) return subRow;

                      if (subRow._id === rowId && rowIndex === index) {
                        return { ...subRow, [columnId]: previousValue };
                      }

                      return subRow;
                    });
                    return { ...group, subRows };
                  }
                  return group;
                }));
              });

            return updatedSubRow;
          }

          return subRow;
        });
        return { ...group, subRows };
      }
      return group;
    }));
  }, [setData, skipAutoResetPageIndex, token]);

  const columns = BreakEvenColumns({ handleDelete, updateData, columnVisibility, footerSums });
  const defaultColumn = {
    cell: (info) => {
      return (
        <NumberCell
          key={info.row.id}
          updateData={updateData}
          row={info.row}
          getValue={info.row.getValue}
          columnId={info.column.id}
          page={'BreakEven'}
        />
      );
    },
  };

  const [modalState, setModalState] = useState({ isOpen: false, trade: null });

  const originalDataRef = useRef([]);

  useEffect(() => {
    // Function to filter subRows based on the globalFilter
    const filterSubRows = (subRows, filterValue) => {
      if (!filterValue) return subRows;
      const lowerCaseFilterValue = filterValue.toLowerCase();

      return subRows.filter(subRow =>
        Object.values(subRow).some(value =>
          String(value).toLowerCase().includes(lowerCaseFilterValue)
        )
      );
    };

    // Function to filter data (including subRows) based on the globalFilter
    const filterData = (data, filterValue) => {
      if (!filterValue) return data;

      return data.map(row => {
        // Check if row has subRows
        if (row.subRows) {
          // Filter subRows
          const filteredSubRows = filterSubRows(row.subRows, filterValue);

          // Return a new row object with filtered subRows
          return { ...row, subRows: filteredSubRows };
        }

        // Return the row unchanged if it doesn't have subRows
        return row;
      });
    };

    // Apply filter to the original data if globalFilter is not empty, otherwise, reset to original data
    const filteredData = globalFilter ? filterData(originalDataRef.current, globalFilter) : originalDataRef.current;
    setData(filteredData); // Update the state with filtered or original data
  }, [globalFilter, originalDataRef]);

  useEffect(() => {
    fetchData();
  }, []);
  const createNewBreakEvenTrade = (groupId) => {
    return {
      id: `${groupId}-new-${Date.now()}`,
      breakEvenType: '',
      breakEvenShares: 0,  // example value
      breakEvenStrike: 0,   // example value
      breakEvenFinalPL: 0    // example value
    };
  };
  const table = useReactTable({
    data,
    defaultColumn,
    columns,
    state: {
      columnVisibility,
      columnOrder,
      sorting,
      globalFilter,
      expanded,
    },
    getSubRows: (row) => row.subRows || [],
    onExpandedChange: setExpanded,
    enableSorting: sort,
    onGlobalFilterChange: setGlobalFilter,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    columnResizeMode: 'onChange',
    enableColumnResizing: true,
    onColumnOrderChange: setColumnOrder,
    autoResetPageIndex,
    meta: {
      updateData,
      addRow: (groupIndex, groupId) => {
        const newTrade = createNewBreakEvenTrade(groupId);  // Create a new row based on groupId

        setData(currentData => {
          const newData = [...currentData];
          // Find the group by index if groupIndex is valid
          if (groupIndex >= 0 && groupIndex < newData.length && newData[groupIndex].id === groupId) {
            newData[groupIndex] = {
              ...newData[groupIndex],
              subRows: [...newData[groupIndex].subRows, newTrade]
            };
          } else {
            return newData.map(group => {
              if (group.id === groupId) {
                return { ...group, subRows: [...group.subRows, newTrade] };
              }
              return group;
            });
          }
          return newData;
        });

        setExpanded(prev => ({ ...prev, [groupIndex]: true })); // Automatically expand the group where the row was added
      },
      cancelNewOperation: (groupIndex, groupId) => {
        setData(currentData => {
          if (currentData[groupIndex] && currentData[groupIndex].id === groupId) {
            const newData = [...currentData];
            // Remove the last subRow from the group at groupIndex
            newData[groupIndex].subRows.pop();
            return newData;
          }
          return currentData; // Return unchanged data if condition not met
        });
      },
      saveOperation: (groupIndex, groupId) => {
        setData(currentData => {
          // Create a shallow copy of the current data array
          const newData = currentData.map(group => ({ ...group }));

          // Find the group that matches the provided groupId
          const group = newData[groupIndex];

          if (group && group.id === groupId) {
            // Assume the last row is the new row to be saved
            const newRow = group.subRows.pop(); // Temporarily remove to send for saving

            // Placeholder for sending data to the backend
            saveBreakEvenOperation(newRow, groupId, token).then(response => {
              // Assuming the backend returns the saved row with any additional necessary data

              // Push the confirmed row back into the subRows
              group.subRows.push(response);

              // Update the state with the new data
              setData([...newData]); // Ensure a new array reference is passed to setData
            }).catch(error => {
              console.error('Failed to save new row:', error);

              // // Optionally re-add the newRow to subRows if saving failed
              // group.subRows.push(newRow);


              // // Update the state with the reverted data
              // setData([...newData]); // Ensure a new array reference is passed to setData
            });

            return newData; // Return updated data array
          }
          return currentData; // Return unchanged data if condition not met
        })
      }

    }
  });

  const fetchData = async () => {
    try {
      const trades = await fetchBreakEven(token);
      // Call the function to load data
      originalDataRef.current = groupTradesByTicker(trades) || [];
      setData(originalDataRef.current);
    } catch (error) {
      console.error('Error fetching trades:', error);
      if (error.response.data.error === 'Invalid token.') logout();
    }
  };


  const handleDeleteTrade = (tradeId) => {
    setData(oldData => oldData.filter(group => group.id !== tradeId));
  };
  const editableColumns = [
    'color',
    'putCall',
    'ticker',
    'tradeDate',
    'expirationDate',
    'strike',
    'numberOfOptions',
    'closingTradeType',
    'finalPL',
  ];

  // Render the table
  return (
    <div
      style={{ width: calculateWidth(activeMenu, screenSize) }}
      className='flex flex-col  bg-light-background p-4  h-full mx-auto '
    >
      <div className='flex w-full items-center justify-between mb-6 mt-2 gap-4'>

        <h1 className='text-3xl font-semibold  text-light-text'>
          Break Even Calculator
        </h1>
        <button className='animate-scale-once mr-4 ' onClick={() => guidedTutorialHandler(guidedBreakEven)}><BsQuestionOctagon className='w-9 h-9 fill-orange-500' /></button>
      </div>
      <ConfirmationModalContent
        isOpen={modalState.isOpen}
        title='Delete Trade'
        message='Are you sure you want to delete this trade? This action cannot be undone.'
        onConfirm={() =>
          handleBreakEvenOperationDeleteConfirm({
            modalState,
            setModalState,
            token,
            setData,
            logout,
            page: 'BreakEven',
          })
        }
        onCancel={() => {
          setModalState({ isOpen: false, trade: null });
        }}
      />
      <div id='toolbar' className="flex flex-col md:flex-row flex-wrap justify-between items-center mb-4 gap-2  bg-gray-200  px-4 py-2 rounded-lg shadow-md " >

        <SearchAndColumnToggle
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          contextMenuState={contextMenuState}
          setContextMenuState={setContextMenuState}
          table={table}
          page={'BreakEven'}
        />
        <TableActionControls
          // title={'Add BreakEven trade'}
          icon={<MdHistory className="w-5 h-5" />}
          newRow={newRow}
          // handleSave={handleHistorySave}
          data={data}
          token={token}
          setData={setData}
          setNewRow={setNewRow}
          setSort={setSort}
          logout={logout}
          fetchData={fetchData}
          setColorsKeyMapState={setColorsKeyMapState}
          colorsKeyMapState={colorsKeyMapState}
          table={table}
        />

      </div>

      <div className='my-2 overflow-x-auto'>
        <div className='py-1 align-middle  inline-block '>
          <div className=' shadow  border-b border-gray-200  rounded-2xl'>
            <div className=' rounded-t-3xl '>
              <table
                id='tableContainer'
                style={{
                  width: table.getCenterTotalSize(),
                }}
                className=' divide-y  divide-gray-200 table rounded-2xl '
              >
                <TableHeader
                  headerGroups={table.getHeaderGroups()}
                  screenSize={screenSize} // This value should come from your state or context
                  calculateLeftOffset={calculateLeftOffset} // This function should be defined or imported
                  columnVisibility={columnVisibility} // This state should be managed in the parent component
                  newRow={newRow} // This state should be managed in the parent component
                  editableColumns={editableColumns} // This array should be defined or imported
                  currentColor={currentColor} // This value should come from your state or context
                  page={'breakEven'}
                />
                <BreakEvenTableBody
                  data={data}
                  page='BreakEven'
                  onDeleteTrade={handleDeleteTrade}
                  // tradingCapitalData={capitalData}
                  // setTradingCapitalData={setCapitalData}
                  table={table}
                  setFooterSums={setFooterSums}
                  // screenSize={screenSize} // Pass the screenSize state
                  // getTradeStatus={getTradeStatus} // Pass the getTradeStatus function
                  newRow={newRow} // Pass the newRow state
                />
                {/* <TableFooter
                  footerGroups={table.getFooterGroups()}
                  page='BreakEven'
                /> */}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default BreakEven;