import React, { useEffect, useState } from 'react';
import DashboardHeader from './DashboardHeader';
import { MdAddTask } from 'react-icons/md';

const WatchListItem = ({ ticker, currentPrice, prevPrice }) => {
  const change = currentPrice - prevPrice;
  const changePercent = prevPrice
    ? ((change / prevPrice) * 100)?.toFixed(2)
    : 0;

  return (
    <div className='flex justify-between items-center px-3 py-2 bg-white rounded-lg shadow mb-2'>
      <span className='text-sm font-medium'>{ticker}</span>
      <span
        className={`text-sm font-medium ${
          change >= 0 ? 'text-green-600' : 'text-red-600'
        }`}
      >
        ${currentPrice?.toFixed(2)}
      </span>
      <span
        className={`text-sm font-medium ${
          change >= 0 ? 'text-green-600' : 'text-red-600'
        }`}
      >
        ${change >= 0 ? '+' : ''}
        {change?.toFixed(2)}
      </span>
      <span
        className={`text-sm font-medium ${
          change >= 0 ? 'text-green-600' : 'text-red-600'
        }`}
      >
        {changePercent}%
      </span>
    </div>
  );
};

const WatchList = ({ watchListData }) => {
  const [watchList, setWatchList] = useState(watchListData);
  const [sortField, setSortField] = useState('ticker');
  const [sortAscending, setSortAscending] = useState(true);

  const handleSortFieldChange = (field) => {
    setSortField(field);
  };

  const toggleSortOrder = () => {
    setSortAscending(!sortAscending);
  };
  useEffect(() => {
    const sortedList = [...watchListData].sort((a, b) => {
      let valA, valB;

      switch (sortField) {
        case 'ticker':
          valA = a[sortField].toUpperCase();
          valB = b[sortField].toUpperCase();
          break;
        case 'price':
          valA = a['currentPrice'];
          valB = b['currentPrice'];
          break;
        case 'change':
          valA = a['currentPrice'] - a['prevPrice'];
          valB = b['currentPrice'] - b['prevPrice'];
          break;
        case 'changePercent':
          valA = a['prevPrice']
            ? ((a['currentPrice'] - a['prevPrice']) / a['prevPrice']) * 100
            : 0;
          valB = b['prevPrice']
            ? ((b['currentPrice'] - b['prevPrice']) / b['prevPrice']) * 100
            : 0;
          break;
        default:
          valA = a[sortField];
          valB = b[sortField];
      }

      if (sortAscending) {
        return valA > valB ? 1 : valA < valB ? -1 : 0;
      } else {
        return valA < valB ? 1 : valA > valB ? -1 : 0;
      }
    });
    setWatchList(sortedList);
  }, [watchListData, sortField, sortAscending]);

  return (
    <div id='watchList' className='mb-8 w-full'>
      <DashboardHeader
        title='Watch List'
        sortOptions={[
          { value: 'ticker', label: 'Ticker' },
          { value: 'price', label: 'Price' },
          { value: 'change', label: 'Change' },
          { value: 'changePercent', label: 'Change (%)' },
        ]}
        sortField={sortField}
        sortAscending={sortAscending}
        handleSortFieldChange={handleSortFieldChange}
        toggleSortOrder={toggleSortOrder}
      />
      <div className='relative flex justify-between items-center px-3 py-2 bg-gray-200/50 rounded-t-lg shadow-sm z-10 text-sm font-semibold'>
        <span className=''>Ticker</span>
        <span>Price</span>
        <span>change</span>
        <span>Change (%)</span>
      </div>
      <div className='relative mt-1 h-96 max-h-96  overflow-y-auto scrollbar-none'>
        <div className='space-y-2 py-3 h-full'>
          {watchList.length === 0 ? (
            <div className='flex flex-col items-center justify-center h-full p-4'>
              <MdAddTask size={64} className='text-gray-400' />
              <p className='mt-2 text-sm text-gray-500'>
                No watched ticker in the watchlist page
              </p>
            </div>
          ) : (
            watchList.map((item, index) => (
              <WatchListItem key={index} {...item} />
            ))
          )}
        </div>
        {/* <div className='sticky bottom-0 left-0 w-full h-16 bg-gradient-to-t from-white via-transparent to-transparent pointer-events-none'></div> */}
      </div>
    </div>
  );
};
export default WatchList;
