import { updateTradeCellByRowId } from '../../services/tradesServices';

// onSubmit.js
export const onSubmit = async ({
  row,
  columnId,
  newValue,
  token,
  updateData,
  currentValue,
  page
}) => {
  const rowId = row?.original._id;
  const rowIndex = row?.index;
  console.log(newValue,currentValue,row)
  if (newValue === currentValue && !row) return; // Prevent unnecessary updates

  try {
    if (rowId && newValue !== currentValue && page !== 'breakEven') {
      await updateTradeCellByRowId({ row, columnId, newValue, token });
      updateData({ rowIndex, rowId, columnId, value: newValue });
    }
    updateData({ rowIndex, rowId, columnId, value: newValue });
  } catch (error) {
    console.error('Error updating cell:', error);
  }
};
