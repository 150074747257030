import axios from 'axios';

// Base URL for your API

// Fetch capital data for a specific year
export const fetchCapital = async (year, token) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/capitalsList/${year}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data.capitals.length > 0
      ? response.data.capitals[0].capitals
      : []; // Assuming the response has a 'capitals' key
  } catch (error) {
    console.error('Error fetching capital data:', error);
    // Optionally throw the error again if you want to handle it further up the chain
    throw error;
  }
};

// Fetch capital data for a specific date range
export const fetchCapitalByDateRange = async (startDate, endDate, token) => {
  try {
    // Format dates to strings that your backend expects, e.g., YYYY-MM-DD
    const formattedStartDate = startDate;
    const formattedEndDate = endDate;

    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/capitalForDateRange`,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: { startDate: formattedStartDate, endDate: formattedEndDate },
      }
    );

    return response.data.capitals; // Assuming the response has a 'capitals' key
  } catch (error) {
    console.error('Error fetching capital by date range:', error);
    throw error; // Re-throw the error to handle it in the calling component
  }
};
export const updateCapitalData = async (year, monthIndex, capital, token) => {
  try {
    const payload = {
      year,
      monthIndex,
      capital,
    };

    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/updateTradingCapital`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    // Assuming the API responds with the updated capital data for the year
    return response.data; // You might need to adjust this based on your actual API response
  } catch (error) {
    console.error('Error updating trading capital:', error);
    throw error; // Re-throw the error to handle it in the calling component
  }
};
