import axios from 'axios';

export const fetchDashboardWatchListData = async (token) => {
  try {
    // Replace with your actual backend URL
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/watchlist`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response && response.data) {
      return response.data;
    } else {
      throw new Error('Failed to retrieve data');
    }
  } catch (error) {
    console.error('Error fetching dashboard data:', error);
    // Handle the error appropriately - you can also choose to rethrow the error or handle it differently
    throw error;
  }
};

// Fetches data for ActiveTradesData, ActiveTrades, and TradesByMarketIndustryChart components
export const fetchPortfolioData = async (token) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/dashboard/portfolio-data`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    // The response should include data structured to meet the needs of the three components
    return response.data.data;
  } catch (error) {
    console.error('Error fetching portfolio data:', error);
    throw error; // Or handle this error as needed
  }
};
