import React, { useState, useEffect } from 'react';
import { format, eachYearOfInterval, endOfYear, startOfYear, eachMonthOfInterval, startOfMonth, endOfMonth, parseISO } from 'date-fns';

const DateRangePicker = ({ dateRange, setDateRange }) => {
  // Convert ISO string dates to Date objects
  const startDate = parseISO(dateRange.startDate);
  const endDate = parseISO(dateRange.endDate);

  // Current year for initializing the component
  const currentYear = new Date().getFullYear();

  const [selectedYear, setSelectedYear] = useState(startDate.getFullYear());
  const [selectedMonth, setSelectedMonth] = useState('all');
  const [isCustomRange, setIsCustomRange] = useState(false);

  // Format dates for the input fields
  const [customRange, setCustomRange] = useState({
    startDate: format(startDate, 'yyyy-MM-dd'),
    endDate: format(endDate, 'yyyy-MM-dd'),
  });

  useEffect(() => {
    // Update the custom range based on the selected year and month when not in custom range mode
    if (!isCustomRange) {
      const newStartDate = selectedMonth === 'all' ? startOfYear(new Date(selectedYear, 0)) : startOfMonth(new Date(selectedYear, selectedMonth));
      const newEndDate = selectedMonth === 'all' ? endOfYear(new Date(selectedYear, 11)) : endOfMonth(new Date(selectedYear, selectedMonth));
      
      setCustomRange({
        startDate: format(newStartDate, 'yyyy-MM-dd'),
        endDate: format(newEndDate, 'yyyy-MM-dd'),
      });
    }
  }, [selectedMonth, selectedYear, isCustomRange]);

  const handleApply = () => {
    setDateRange({
      startDate: customRange.startDate,
      endDate: customRange.endDate,
    });
  };

  const handleCustomRangeChange = (field, value) => {
    setCustomRange(prev => ({ ...prev, [field]: value }));
  };

  const yearOptions = eachYearOfInterval({ start: new Date(currentYear - 10, 0), end: new Date() }).sort((a, b) => b - a);
  const monthOptions = eachMonthOfInterval({ start: new Date(selectedYear, 0), end: new Date(selectedYear, 11) });
    // Calculate today's date in yyyy-MM-dd format for the max attribute
    const today = format(new Date(), 'yyyy-MM-dd');
  return (
    <div className="flex flex-col flex-1 lg:flex-grow-0 md:flex-row justify-center items-center  gap-2">
      <div className="flex items-center">
        <input type="checkbox" checked={isCustomRange} onChange={(e) => setIsCustomRange(e.target.checked)} className="form-checkbox rounded text-indigo-600 focus:ring-indigo-500" />
        <label htmlFor="customRangeCheckbox" className="ml-2 text-sm text-nowrap text-gray-700">Custom Range</label>
      </div>

      {isCustomRange ? (
        <div className="flex space-x-2">
          <input type="date" className="px-4 py-2 border rounded text-sm text-gray-700" value={customRange.startDate} onChange={(e) => handleCustomRangeChange('startDate', e.target.value)} />
          <input type="date" max={today} className="px-4 py-2 border rounded text-sm text-gray-700" value={customRange.endDate} onChange={(e) => handleCustomRangeChange('endDate', e.target.value)} />
        </div>
      ) : (
        <div className="flex space-x-2 text-sm h-9">
          <select className="px-4 border rounded cursor-pointer text-gray-700" value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
            <option className='text-sm' value="all">All Months</option>
            {monthOptions.map((month, index) => (
              <option className='text-sm' key={index} value={index}>{format(month, 'MMMM')}</option>
            ))}
          </select>
          <select className="px-4  border rounded cursor-pointer text-gray-700" value={selectedYear.toString()} onChange={(e) => setSelectedYear(parseInt(e.target.value, 10))}>
            {yearOptions.map(year => (
              <option className='text-sm' key={year.getFullYear()} value={year.getFullYear()}>{year.getFullYear()}</option>
            ))}
          </select>
        </div>
      )}
      <button className="px-2 py-1 bg-blue-600 hover:bg-blue-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-indigo-500" onClick={handleApply}>Apply</button>
    </div>
  );
};

export default DateRangePicker;
