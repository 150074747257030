import React, { useEffect, useState } from 'react';
import { FaRegSadTear } from 'react-icons/fa';
import DashboardHeader from './DashboardHeader';
import { differenceInCalendarDays, parseISO } from 'date-fns';
import { MdAddTask } from 'react-icons/md';

const TradeItem = ({
  tradeDate,
  expirationDate,
  ticker,
  strike,
  currentPrice,
  title,
}) => {
  const expirationDateParsed = parseISO(expirationDate);

  // Calculate the number of days from today to expirationDate
  let daysUntilExpiration = differenceInCalendarDays(
    expirationDateParsed,
    new Date()
  );

  // Determine display text based on the number of days until expiration
  let daysDisplay =
    daysUntilExpiration < 0 ? 'Expired' : `${daysUntilExpiration} days`;

  const strikePercentage = Math.min((strike / currentPrice) * 100, 100);

  const barGradient =
    title === 'PUTS'
      ? 'linear-gradient(to right, #098e48, #1dff86)'
      : 'linear-gradient(to right, #c76e2d, #f8af79)';

  const calculateBarSize = (strike, currentPrice) => {
    if (currentPrice > 0) {
      if (strike > currentPrice) {
        // When strike price is greater, calculate the bar size as the ratio of current price to strike price
        return (currentPrice / strike) * 100; // This will be less than 100%
      } else {
        // When current price is greater or equal, bar size is 100%
        return 100;
      }
    }
    return 0; // Return 0 if currentPrice is 0 or invalid to avoid division by zero
  };

  const getXAxisValues = (barSize, strike) => {
    // Display only the strike price when bar is too small
    if (barSize <= 5) {
      return [`$${currentPrice.toFixed(2)}`];
    }

    const thresholds = [20, 40, 60, 80];
    const valuesCount = thresholds.reduce(
      (count, threshold) => (barSize > threshold ? count + 1 : count),
      1
    );

    const spacing = 100 / (valuesCount - 1);
    return Array.from({ length: valuesCount }, (_, i) => {
      const value = currentPrice * ((i * spacing) / 100);
      return value ? `$${value.toFixed(2)}` : '$0.00';
    });
  };

  const barSize = calculateBarSize(strike, currentPrice);
  const xAxisValues = getXAxisValues(barSize);

  return (
    <div className='flex flex-col p-2 bg-white rounded-lg shadow mb-2'>
      <div className='flex justify-between items-center mb-1'>
        <span className='text-xs font-semibold'>{daysDisplay}</span>
      </div>
      <div className='flex items-center mb-4'>
        <span className='font-semibold mr-1'>{ticker.toUpperCase()}</span>
        <div className='w-1 h-6 border border-l-0 border-gray-400' />
        <div className='relative flex-grow'>
          <div className='w-full h-4 bg-gray-200 rounded-sm overflow-hidden'>
            <div
              className='absolute bg-white'
              style={{
                height: '100%',
                width: '2px',
                left: `${strikePercentage}%`,
              }}
            ></div>
            <div
              className='absolute h-full rounded-sm'
              style={{ width: `${barSize}%`, background: barGradient }}
            ></div>
            <div
              className='absolute bg-red-600'
              style={{
                height: '120%',
                width: '3px',
                left: `${strikePercentage}%`,
              }}
            ></div>
            <span
              className='absolute text-xs whitespace-nowrap text-red-600 font-bold'
              style={{
                left: `${strikePercentage}%`,
                bottom: '100%',
                transform: 'translateX(-50%) translateY(-2px)',
              }}
            >
              Strike {strike}
            </span>
          </div>
          <div
            style={{
              width: `${barSize}%`,
            }}
            className='absolute bottom-0 left-0  flex justify-between text-xs font-medium mt-2'
          >
            {xAxisValues.map((value, index) => {
              let leftPosition = `${index * (100 / (xAxisValues.length - 1))}%`;
              return (
                <div
                  key={index}
                  style={{ position: 'absolute', left: leftPosition }}
                >
                  <div
                    style={{
                      height: '6px',
                      width: '1px',
                      backgroundColor: 'black',
                      marginBottom: '2px',
                    }}
                  ></div>
                  <span
                    style={{
                      position: 'absolute',
                      left: '50%',
                      transform: 'translateX(-50%)',
                    }}
                  >
                    {value}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        <span className='text-xs ml-1 font-medium'>
          ${currentPrice?.toFixed(2)}
        </span>
      </div>
    </div>
  );
};

const TradeList = ({
  title,
  trades,
  onSortChange,
  sortField,
  sortAscending,
  type,
}) => {
  return (
    <div
      id={title === 'PUTS' ? 'putsSection' : 'callsSection'}
      className='mb-4'
    >
      <div className='relative flex justify-between items-center px-3 py-2 bg-gray-200 rounded-t-lg shadow-sm z-10 text-sm font-semibold'>
        <span
          className={`${title === 'PUTS' ? 'text-green-700' : 'text-red-600'}`}
        >
          {title}
        </span>
      </div>
      {trades.length === 0 ? (
        <div className='flex flex-col items-center justify-center h-full p-4'>
          <MdAddTask size={64} className='text-gray-400' />
          <p className='mt-2 text-sm text-gray-500'>
            Add active {title.toLowerCase()} trades in the portfolio page
          </p>
        </div>
      ) : (
        <div className={`relative mt-1 `}>
          <div className='space-y-2'>
            {trades.map((trade) => (
              <TradeItem key={trade._id} title={title} {...trade} />
            ))}
          </div>
          {/* <div className='sticky bottom-0 left-0 w-full h-10 bg-gradient-to-t from-white via-transparent to-transparent'></div> */}
        </div>
      )}
    </div>
  );
};

const sortTrades = (trades, sortField, sortAscending) => {
  const currentDate = new Date(); // For calculating days until expiration

  return [...trades].sort((a, b) => {
    let valA, valB;

    switch (sortField) {
      case 'ticker':
        valA = a[sortField].toUpperCase();
        valB = b[sortField].toUpperCase();
        break;
      case 'tradeDate':
        // Convert string dates to Date objects for comparison
        valA = new Date(a['tradeDate']);
        valB = new Date(b['tradeDate']);
        break;
      case 'expirationDate':
        // Calculate days until expiration relative to the current date
        valA = a['expirationDate']
          ? (new Date(a['expirationDate']) - currentDate) /
            (1000 * 60 * 60 * 24)
          : Infinity; // Use Infinity for trades without expirationDate to sort them last
        valB = b['expirationDate']
          ? (new Date(b['expirationDate']) - currentDate) /
            (1000 * 60 * 60 * 24)
          : Infinity;
        break;
      case 'currentPL':
        // Assuming 'currentPL' is already calculated and available
        valA = a['currentPL'];
        valB = b['currentPL'];
        break;
      // Add more cases as needed
      default:
        valA = a[sortField];
        valB = b[sortField];
    }

    if (sortAscending) {
      return valA > valB ? 1 : valA < valB ? -1 : 0;
    } else {
      return valA < valB ? 1 : valA > valB ? -1 : 0;
    }
  });
};

const ActiveTradesData = ({ trades }) => {
  const [sortedTrades, setSortedTrades] = useState(trades);
  const [sortField, setSortField] = useState('tradeDate');
  const [sortAscending, setSortAscending] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setSortedTrades(sortTrades([...trades], sortField, sortAscending));
  }, [trades, sortField, sortAscending]);

  const handleSortFieldChange = (field) => {
    if (field === sortField) {
      toggleSortOrder();
    } else {
      setSortField(field);
      setSortAscending(true); // Always set to ascending when changing the field
    }
  };

  const toggleSortOrder = () => {
    setSortAscending(!sortAscending);
  };

  const puts = sortedTrades.filter((trade) => trade.putCall === 'put');
  const calls = sortedTrades.filter((trade) => trade.putCall === 'call');

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '10px',
      padding: '20px',
      animation: 'fadeIn 0.5s',
      height: '80%',
      width: '75%',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: 1000,
      animation: 'fadeInOverlay 0.5s',
    },
  };
  return (
    <div className='h-full flex flex-col '>
      <DashboardHeader
        title='Active Trades Data'
        sortOptions={[
          { value: 'tradeDate', label: 'Trade Date' },
          { value: 'expirationDate', label: 'Expiration Date' },
          { value: 'ticker', label: 'Alphabetical' },
        ]}
        sortField={sortField}
        sortAscending={sortAscending}
        handleSortFieldChange={handleSortFieldChange}
        toggleSortOrder={toggleSortOrder}
      />
      <div id='activeTradesData' className='grid md:grid-cols-2 gap-4  flex-1'>
        {/* Display PUTS */}
        <TradeList title='PUTS' id='putsSection' trades={puts} />
        {/* Display CALLS */}
        <TradeList title='CALLS' id='callsSection' trades={calls} />
      </div>
    </div>
  );
};

export default ActiveTradesData;
