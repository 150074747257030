import React from 'react';

const AboutUs = () => {
  return (
    <div className='flex flex-col space-y-4 text-light-text bg-light-background p-8 rounded-lg shadow-md w-full h-full mx-auto'>
      <h1 className='text-3xl font-bold'>
        Options Wheel Strategy Tool : Version 2.80
      </h1>
      <p className='text-xl'>&copy; 2022 Wheel Options Traders</p>
      <p className='text-lg'>
        This excel workbook is available to our course members only. Please
        visit our website to join and pay for our course to download your own
        version. We hope you enjoy using it and find it useful.
      </p>
      <p className='text-lg'>
        This workbook cannot be used for commercial purposes. Additionally, it
        is not open source - please do not be tempted to share or distribute,
        either in its or original form or rebranded.
      </p>
      <p className='text-lg'>
        Please encourage others to join our course to download the file from our
        website. Thank you!
      </p>
      <a
        href='https://wheeloptionstraders.com/'
        className='text-blue-500 underline'
      >
        https://wheeloptionstraders.com/
      </a>

      <h2 className='text-2xl font-bold'>Contact / Support</h2>
      <p className='text-lg'>
        If you find any issues or would like to contact us, please use our
        contact form on our website:
      </p>
      <a
        href='https://wheeloptionstraders.com/contact/'
        className='text-blue-500 underline'
      >
        https://wheeloptionstraders.com/contact/
      </a>
      <p className='text-lg'>
        Note: When contacting us for support issues, please include the version
        of the operating system you are using as well as your version of Excel.
      </p>

      <h2 className='text-2xl font-bold'>COPYRIGHT AGREEMENT</h2>
      <p className='text-lg'>
        In downloading and using this excel workbook file I agree not to share
        or distribute or sell this workbook.
      </p>
      <p className='text-lg'>
        Any alterations I make to the file, including images, formulas, VBA code
        and any other aspect of this workbook will still not allow me to
        distribute or share this file in any way.
      </p>
      <p className='text-lg'>
        Any issues with this workbook arising from alterations I make to the
        file will not by covered by our support. Please make sure you know what
        you are doing before making any changes. Always keep back ups.
      </p>
      <p className='text-lg'>
        This workbook is protected by copyright. All rights reserved.
      </p>
      <p className='text-xl'>&copy; 2022 Wheel Options Traders</p>
    </div>
  );
};

export default AboutUs;
