import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  // Title,
  Tooltip,
  Legend
);

const options = {
  indexAxis: 'y',
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
  },
  scales: {
    x: { // Assuming the dynamic scale is needed for the horizontal axis in a bar chart
      beginAtZero: true,
      ticks: {
        // Set the minimum value for the scale
        stepSize: 1, // Adjust the step size as needed
      },
      // min: 1, // Set minimum value to 10
    },
  },
};


const TradesByMarketIndustryChart = ({ industryData }) => {
  const [filter, setFilter] = useState('both');

  const prepareChartData = () => {
    // Ensure industryData is an object before proceeding
    if (!industryData) return { labels: [], data: [] };

    let adjustedIndustryData = {};

    // Aggregate empty string industry under "Others"
    Object.entries(industryData).forEach(([industry, counts]) => {
        if (industry === "") {
            if (!adjustedIndustryData["Others"]) adjustedIndustryData["Others"] = { puts: 0, calls: 0 };
            adjustedIndustryData["Others"].puts += counts.puts;
            adjustedIndustryData["Others"].calls += counts.calls;
        } else {
            adjustedIndustryData[industry] = counts;
        }
    });

    const labels = Object.keys(adjustedIndustryData);
    const data = labels.map(label => {
        const counts = adjustedIndustryData[label];
        return filter === 'both' ? counts.puts + counts.calls : counts[filter];
    });

    return { labels, data };
};

  const chartData = prepareChartData();

  const data = {
    labels: chartData.labels,
    datasets: [
      {
        label: 'Number of Trades',
        data: chartData.data,
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        borderColor: 'rgb(53, 162, 235)',
        borderWidth: 1,
      },
    ],
  };

  return (
    <div id='activeTradesByIndustry' className='mb-8 w-full'>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-medium">ACTIVE TRADES BY MARKET INDUSTRY</h2>
        <select
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          className="border rounded p-1"
        >
          <option value="both">Both</option>
          <option value="puts">Puts</option>
          <option value="calls">Calls</option>
        </select>
      </div>
      <Bar options={options} data={data} />
    </div>
  );
};

export default TradesByMarketIndustryChart;
