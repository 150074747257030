import { differenceInCalendarDays, format } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

export function formatNumber(number, fraction = 2) {
  if (number === null || number === undefined) {
    return null;
  }

  return Number(number).toLocaleString('en-US', {
    maximumFractionDigits: fraction,
    minimumFractionDigits: fraction,
  });
}

export const mergeRefs = (...refs) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 1) return filteredRefs[0];
  return (inst) => {
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        ref(inst);
      } else if (ref) {
        ref.current = inst;
      }
    }
  };
};

export const calculateLeftOffset = (precedingColumns) => {
  return precedingColumns.reduce((acc, column) => acc + column.getSize(), 0);
};

export const calculateWidth = (activeMenu, screenSize) => {
  const sidebarWidth = 310;
  if (activeMenu) {
    return screenSize - sidebarWidth;
  } else {
    return screenSize;
  }
};

export const calculateFinalPL = (premium, closingPremium, numberOfOptions) => {
  return (premium - closingPremium) * numberOfOptions * 100;
};

export const calculateTradeSums = (data, getTradeStatus, calculateFinalPL) => {
  return data.reduce(
    (acc, row) => {
      const tradeStatus = getTradeStatus(row).value;

      if (tradeStatus === 'active' || tradeStatus === 'pending') {
        const premiumValue = parseFloat(
          row.getValue ? row.getValue('premium') : row['premium']
        );
        const numberOfOptionsValue = parseInt(
          row.getValue
            ? row.getValue('numberOfOptions')
            : row['numberOfOptions']
        );
        const strikeValue = parseFloat(
          row.getValue ? row.getValue('strike') : row['strike']
        );

        if (!isNaN(premiumValue) && !isNaN(numberOfOptionsValue)) {
          acc.totalOptionsValue += numberOfOptionsValue * premiumValue * 100;
        }
        const optionType = row.getValue
          ? row.getValue('putCall')
          : row['putCall'];

        if (optionType === 'put' && !isNaN(strikeValue)) {
          acc.totalIfAssigned += numberOfOptionsValue * 100 * strikeValue;
        }
      }

      if (tradeStatus === 'closed') {
        const premium = parseFloat(
          row.getValue ? row.getValue('premium') : row['premium']
        );
        const closingPremium = parseFloat(
          row.getValue ? row.getValue('closingPremium') : row['closingPremium']
        );
        const numberOfOptions = parseInt(
          row.getValue
            ? row.getValue('numberOfOptions')
            : row['numberOfOptions']
        );

        let finalPL = parseFloat(
          row.getValue ? row.getValue('finalPL') : row['finalPL']
        );
        // If finalPL is not a valid number or 0, calculate it
        if (isNaN(finalPL) || finalPL === 0) {
          finalPL = calculateFinalPL(premium, closingPremium, numberOfOptions);
        }
        acc.totalFinalPL += finalPL;
      }
      return acc;
    },
    { totalOptionsValue: 0, totalIfAssigned: 0, totalFinalPL: 0 }
  );
};

export const calculateHistoryFinalPL = (trades) => {
  return trades.reduce((total, trade) => {
    return total + (Number(trade.pnlTotal) || 0);
  }, 0);
};

export const calculateTotalCapital = (capitalData) => {
  let totalCapital = 0;

  // Loop through each year's data
  capitalData.forEach((yearData) => {
    const capitals = yearData.capitals;

    // Sum the capital for each month in the year
    Object.keys(capitals).forEach((month) => {
      totalCapital += capitals[month];
    });
  });

  return totalCapital;
};

export const calculateROI = (totalFinalPL, totalCapital) => {
  // Check if totalFinalPL is a string and replace commas, else just use the numeric value
  const totalFinalPLNum =
    typeof totalFinalPL === 'string'
      ? parseFloat(totalFinalPL.replace(/,/g, ''))
      : totalFinalPL;

  // Ensure totalCapital is treated as a number
  const totalCapitalNum = parseFloat(totalCapital);

  // Prevent division by zero and calculate ROI
  if (
    totalCapitalNum === 0 ||
    isNaN(totalCapitalNum) ||
    isNaN(totalFinalPLNum)
  ) {
    return 0; // Return a string representing 0.00% ROI if input is invalid or totalCapital is zero
  }

  const roi = (totalFinalPLNum / totalCapitalNum) * 100;
  return formatNumber(roi, 0); // Returns the ROI as a string rounded to two decimal places
};

export const calculateData = (tradesData, capitalsData) => {
  let monthsWithTrades = new Set(
    tradesData.map((trade) => new Date(trade.closingDate).getMonth())
  );

  let monthlyData = Array.from({ length: 12 }).map((_, month) => ({
    pnl: 0,
    trades: 0,
    capital: monthsWithTrades.has(month)
      ? parseFloat(capitalsData[0]?.capitals[month]) || 0
      : 0,
    cumulativePnL: 0,
    // Initialize cumulativeROI with 0 for the calculation of additive ROI
    cumulativeROI: 0,
    cumulativeTrades: 0,
  }));

  tradesData.forEach((trade) => {
    const tradeMonth = new Date(trade.closingDate).getMonth();
    const finalPL = parseFloat(trade.finalPL);
    monthlyData[tradeMonth].pnl += finalPL;
    monthlyData[tradeMonth].trades += 1;
  });

  let cumulativePnL = 0;
  let cumulativeTrades = 0;
  let cumulativeROI = 0; // Variable to hold the additive cumulative ROI

  monthlyData.forEach((data, month) => {
    cumulativePnL += data.pnl;
    cumulativeTrades += data.trades;

    // Calculate monthly ROI
    const roi = data.capital > 0 ? (data.pnl / data.capital) * 100 : 0;

    // Update the cumulative ROI by adding the current month's ROI
    cumulativeROI += roi;

    // Store cumulative values
    data.cumulativePnL = cumulativePnL;
    data.cumulativeROI = cumulativeROI; // Use the updated cumulative ROI
    data.cumulativeTrades = cumulativeTrades;

    // Format numbers for display
    data.pnl = formatNumberTwo(data.pnl, 0);
    data.cumulativePnL = formatNumberTwo(data.cumulativePnL, 0);
    data.roi = formatNumberTwo(roi, 0);
    data.cumulativeROI = formatNumberTwo(data.cumulativeROI, 0);
    data.trades = data.trades.toString();
    data.cumulativeTrades = data.cumulativeTrades.toString();
  });

  return monthlyData.map((data, month) => ({
    month: new Date(2024, month).toLocaleString('default', { month: 'long' }),
    capital: monthsWithTrades.has(month)
      ? `$${formatNumber(capitalsData[0]?.capitals[month], 0) || 0}`
      : 0,
    pnl: `$${data.pnl}`,
    pnlCum: `$${data.cumulativePnL}`,
    roi: `${data.roi}%`,
    roiCum: `${data.cumulativeROI}%`,
    trades: data.trades,
    tradesCum: data.cumulativeTrades,
  }));
};

const formatNumberTwo = (value, fraction = 2) => {
  return value.toLocaleString(undefined, {
    minimumFractionDigits: fraction,
    maximumFractionDigits: fraction,
  });
};

export const addCalculatedFieldsToAssignedTrades = (trades) => {
  return trades.map((trade) => {
    const shares = trade.numberOfOptions * 100;
    const marketValue = shares * (trade.currentPrice ?? 0);
    const assignedValue = shares * trade.strike;
    const currentPL = assignedValue - marketValue;
    // const sellingCallValue = trade.sellingCallStrikePrice ? shares * trade.sellingCallStrikePrice : null;
    // const potentialProfit = trade.sellingCallStrikePrice ? (trade.sellingCallStrikePrice - trade.strike) * shares : null;

    return {
      ...trade,
      shares,
      marketValue,
      assignedValue,
      currentPL,
      // sellingCallValue,
      // potentialProfit,
    };
  });
};

export const calculateAssignedTradesFooter = (trades) => {
  let assignedValueSum = 0;
  let marketValueSum = 0;
  let currentPLSum = 0;
  let potentialProfitSum = 0;

  trades.forEach((trade) => {
    const shares = trade.numberOfOptions * 100;
    const marketValue = shares * (trade.currentPrice ?? 0);
    const assignedValue = shares * trade.strike;
    const currentPL = assignedValue - marketValue;
    const potentialProfit = trade.sellingCallStrikePrice
      ? (trade.sellingCallStrikePrice - trade.strike) * shares
      : 0;
    assignedValueSum += assignedValue;
    marketValueSum += marketValue;
    currentPLSum += currentPL;
    potentialProfitSum += potentialProfit;
  });

  return { assignedValueSum, marketValueSum, currentPLSum, potentialProfitSum };
};

export const groupTradesByMonth = (trades) => {
  // Group trades by the month-year key
  const groupedByMonth = trades.reduce((acc, trade) => {
    const monthYear = new Date(trade.closingDate).toLocaleString('default', {
      month: 'long',
      year: 'numeric',
    });
    if (!acc[monthYear]) {
      acc[monthYear] = [];
    }
    acc[monthYear].push(trade);
    return acc;
  }, {});

  // Sort the grouped trades by converting the month-year string back to a date
  const sortedGroupKeys = Object.keys(groupedByMonth).sort((a, b) => {
    const aDate = new Date(a);
    const bDate = new Date(b);

    // If parsing fails (invalid date), revert to string comparison
    if (isNaN(aDate) || isNaN(bDate)) {
      return a.localeCompare(b);
    }

    return aDate - bDate;
  });

  // Convert sorted grouped trades into an array with grouping info and subRows
  const groupedTrades = sortedGroupKeys.map((groupTitle) => {
    const subRows = groupedByMonth[groupTitle];
    const pnlTotal = subRows.reduce((total, trade) => total + trade.finalPL, 0);
    return {
      id: groupTitle, // Ensure you have a unique id for each group row
      isGroup: true,
      groupTitle,
      pnlTotal,
      subRows,
    };
  });
  return groupedTrades;
};

export const groupTradesByTicker = (trades) => {
  return trades.map(trade => ({
    id: trade.trade._id,  // Unique identifier for the group
    isGroup: true,  // This is a group header
    original: {
      ticker: trade.trade.ticker,  // Display the ticker in the group header
      ...trade.trade,  // Spread all trade details for potential use in rendering
    },
    // Map each breakeven operation to subrows of this trade
    subRows: trade.breakEvenOperations
  }));
};


export const sumBreakEvenFinalPL = (subRows) => {
  return subRows.reduce((sum, row) => {
    if (row.breakEvenFinalPL !== undefined) {
      return sum + row.breakEvenFinalPL;
    }
    return sum;
  }, 0);
};

export const calculateDaysToExpiration = (
  expirationDate,
  timeZone = 'UTC',
  startDate = new Date() // Default to today's date if startDate is not provided
) => {
  if (expirationDate === null || expirationDate === undefined) {
    return null;
  }

  // Format startDate or use new Date() as today, ensuring it's at the start of the day
  const startDateFormatted =
    startDate instanceof Date ? startDate : new Date(startDate);
  const todayString = format(
    zonedTimeToUtc(startDateFormatted, timeZone),
    'yyyy-MM-dd',
    { timeZone }
  );
  const zonedToday = utcToZonedTime(
    new Date(`${todayString}T00:00:00+00:00`),
    timeZone
  );

  // Ensure expiration date is treated as occurring at the start of the day in the specified timezone
  const expirationDateString = format(new Date(expirationDate), 'yyyy-MM-dd', {
    timeZone,
  });
  const zonedExpiration = utcToZonedTime(
    new Date(`${expirationDateString}T00:00:00+00:00`),
    timeZone
  );

  // Difference in days
  const differenceInDays = differenceInCalendarDays(
    zonedExpiration,
    zonedToday
  );

  // Adjust return value based on the difference
  if (differenceInDays < 0) {
    return 'Expired';
  } else {
    return differenceInDays;
  }
};
// export const calculateDaysToExpiration = (
//   expirationDate,
//   timeZone = 'UTC',
//   startDate
// ) => {
//   if (expirationDate === null || expirationDate === undefined) {
//     return null;
//   }
//   // Get today's date as a string in the specified timezone, ensuring start of the day
//   const today = new Date();
//   // console.log(startDate) 2024-03-22T00:00:00.000Z
//   // when the startDate is available we should format it the proper way before passing it to the todayString fariable

//   const todayString = format(startDate, 'yyyy-MM-dd', { timeZone });
//   const zonedToday = utcToZonedTime(
//     new Date(`${todayString}T00:00:00+00:00`),
//     timeZone
//   );

//   // Ensure expiration date is treated as occurring at the start of the day in the specified timezone
//   const expirationDateString = format(new Date(expirationDate), 'yyyy-MM-dd', {
//     timeZone,
//   });
//   const zonedExpiration = utcToZonedTime(
//     new Date(`${expirationDateString}T00:00:00+00:00`),
//     timeZone
//   );

//   // Difference in days
//   const differenceInDays = differenceInCalendarDays(
//     zonedExpiration,
//     zonedToday
//   );

//   // Adjust return value based on the difference
//   if (differenceInDays < 0) {
//     return 'Expired';
//   } else {
//     return differenceInDays;
//   }
// };
