import React from 'react';
import { flexRender } from '@tanstack/react-table';
import { MdTrendingUp } from 'react-icons/md';

const TableBody = ({ data, table, screenSize, getTradeStatus, newRow,page }) => {
  return (
    <tbody className='bg-white divide-y divide-gray-200 table-row-group max-h-20 text-sm'>
      {data?.length > 0 ? (
        table.getRowModel().rows.map((row, index) => {
          let accumulatedWidth = 0;
          return (
            <tr
              key={row.id}
              className={`${index === 0 && newRow ? 'bg-blue-200 customPulseAnimation' : 'border-bottom'
                } ${getTradeStatus && getTradeStatus(row)?.value === 'closed' ? 'bg-[#D9D9D9]' : 'bg-white'}`}
            >
              {row.getVisibleCells().map((cell, cellIndex) => {
                const isStickyColumn = ['Options', 'delete', 'color', 'putCall', 'ticker', 'name'].includes(cell.column.id) && screenSize > 900 && page === 'portfolio';
                const leftOffset = isStickyColumn ? accumulatedWidth : undefined;
                accumulatedWidth += isStickyColumn ? cell.column.getSize() : 0;

                return (
                  
                  <td
                    key={cell.id}
                    style={{
                      minWidth: cell.column.getSize(),
                      left: ['Options', 'delete'].includes(cell.column.id) ? 0 : leftOffset ? `${leftOffset}px` : undefined,
                    }}
                    className={`mx-0 p-0 text-ellipsis table-cell bg-inherit ${isStickyColumn ? 'sticky z-10' : 'relative'}`}
                  >
                    <div
                      style={{
                        // width: cell.column.getSize(),
                        left: ['Options', 'delete'].includes(cell.column.id) ? 0 : leftOffset ? `${leftOffset}px` : undefined,
                      }}
                      className='flex items-center justify-center whitespace-nowrap'
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </div>
                  </td>
                );
              })}
            </tr>
          );
        })
      ) : (
        <tr>
          <td
            colSpan={table.getAllLeafColumns().length + 1}
            className='px-4 py-2 whitespace-nowrap text-center'
          >
            <span className='text-xs text-gray-500 flex flex-col justify-center items-center'>
              <MdTrendingUp className='w-24 h-24' />
              <h6 className='font-semibold text-lg'>
                No trades available
              </h6>
            </span>
          </td>
        </tr>
      )}
    </tbody>
  );
};

export default TableBody;
