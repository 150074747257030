import React, { useState } from 'react';
import { MdAddCircleOutline, MdCheckCircleOutline } from 'react-icons/md';
import { IoMdCloseCircleOutline } from 'react-icons/io';

const OperationControls = ({ onAdd, onSave, onCancel }) => {
  const [editMode, setEditMode] = useState(false);

  const handleAdd = (e) => {
    e.stopPropagation();  // Prevent event from bubbling up

    setEditMode(true);
    onAdd();
  };

  const handleSave = (e) => {
    e.stopPropagation();  // Prevent event from bubbling up

    setEditMode(false);
    onSave();
  };

  const handleCancel = (e) => {
    e.stopPropagation();  // Prevent event from bubbling up
    setEditMode(false);
    onCancel();
  };

  return (
    <div className='flex ' id='operationControls'>
      {editMode ? (
        <>

          <button onClick={handleSave} >
            <MdCheckCircleOutline
              className='mx-2 text-green-600 w-6 h-6 cursor-pointer'
            />
          </button>

          <button onClick={handleCancel} >
            <IoMdCloseCircleOutline
              className='mx-2 text-red-600 w-6 h-6 cursor-pointer'
            />
          </button>
        </>
      ) : (
        <button onClick={handleAdd}  >
          <MdAddCircleOutline
            className='cursor-pointer text-green-600 w-6 h-6'
          />
        </button>
      )}
    </div>
  );
};

export default OperationControls;
