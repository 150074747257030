import { MdRemoveCircleOutline } from 'react-icons/md';
import {
  DateCell,
  DropdownCell,
  NumberCell,
  TickerCell,
} from '../utils/tableHandlers';
import { useMemo } from 'react';
import { useAuth } from '../context/auth';
import { formatNumber } from '../utils/mathHelpers';

export const BreakEvenColumns = ({
  handleDelete,
  updateData,
  columnVisibility,
  footerSums,
  capitalData,
}) => {
  const { token } = useAuth();
  return useMemo(
    () => [
      {
        id: 'delete',
        accessorKey: 'delete',
        header: <h6 className='block w-full h-6'></h6>,
        cell: (info) => (
          <>
            {info.row.original._id && info.row.parentId && (
              <button
                className='px-2 py-1 w-full flex justify-center items-center'
                onClick={() => handleDelete(info.row, info.row.parentId)}
              >
                <MdRemoveCircleOutline className='w-5 h-5 fill-red-600' />
              </button>
            )}
          </>
        ),
        // width: 10,
        size: 50,
        maxSize: 100,
        minSize: 30,
        enableResizing: true,
        footer: (
          <div className='  flex flex-col  justify-start items-start  w-full'>
            <div className='bg-blue-200 px-4 py-1 w-full flex justify-end items-center text-end h-8 font-medium '>
              {` `}
            </div>
          </div>
        ),
        // disableFilters: true,
        // disableSortBy: true,
      },
      {
        id: 'breakEvenDate',
        accessorKey: 'breakEvenDate',
        header: (
          <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
            Closing Date
          </h6>
        ),
        cell: (info) => {
          return (
            <DateCell
              updateData={updateData}
              row={info.row}
              columnId={'breakEvenDate'}
              page='BreakEven'
            />
          );
        },
        size: 170,
        maxSize: 200,
        minSize: 150,
        footer: (
          <div className='  flex flex-col justify-start items-start '>
            <h2 className='bg-blue-200 pl-4 text font-bold py-2 '>FINAL P&L</h2>
          </div>
        ),
      },
      {
        id: 'breakEvenType',
        accessorKey: 'breakEvenType',
        header: (
          <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
            breakEvenType
          </h6>
        ),
        cell: (info) => {
          const options = [
            { value: 'Stock', label: 'Stock' },
            { value: 'PutEXP', label: 'Put EXP' },
            { value: 'CallEXP', label: 'Call EXP' },
            { value: 'PutBTC', label: 'Put BTC' },
            { value: 'CallBTC', label: 'Call BTC' },
          ];
          return info.row.index === 0 || info.row.index === 1 ? (
            <p className={`w-full px-4 py-1 flex items-center  `}>
              {/* make the correct label based on the value passed */}
              {info.getValue() === 'PutEXP' ? 'Put EXP' : info.getValue()}
            </p>
          ) : (
            <DropdownCell
              row={info.row}
              options={options}
              updateData={updateData}
              columnId={'breakEvenType'}
              page='breakEven'
            />
          );
        },
        size: 200,
        maxSize: 250,
        minSize: 180,
        footer: (
          <div className='  flex flex-col  justify-start items-start  w-full'>
            <div className='bg-blue-200 px-4 py-1 w-full flex justify-end items-center text-end h-8 font-medium '>
              {` `}
            </div>
          </div>
        ),
      },
      {
        id: 'breakEvenShares',
        accessorKey: 'breakEvenShares',
        header: (
          <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
            Shares
          </h6>
        ),
        cell: (info) => {
          const shares = info.getValue();
          return (
            <p
              className={`w-full px-4 py-1 flex items-center justify-center text-center  `}
            >
              {isNaN(shares) ? '' : `${formatNumber(shares, 0)}`}
            </p>
          );
        },
        size: 150,
        maxSize: 150,
        minSize: 100,
        footer: (
          <div className='  flex flex-col justify-start items-start '>
            <div className=' bg-blue-200 px-4 py-1 w-full flex justify-end items-center text-end h-8 font-medium '>
              {` `}
            </div>
          </div>
        ),
      },
      {
        id: 'breakEvenStrike',
        accessorKey: 'breakEvenStrike',
        header: (
          <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
            Strike
          </h6>
        ),
        cell: (info) => {
          const strike = info.getValue();
          return (
            <p
              className={`w-full px-4 py-1 flex items-center justify-end text-end `}
            >
              {isNaN(strike) ? '' : `$ ${formatNumber(strike)}`}
            </p>
          );
        },
        size: 150,
        maxSize: 150,
        minSize: 100,
        footer: (
          <div className='  flex flex-col  justify-start items-start  w-full'>
            <div className='bg-blue-200 px-4 py-1 w-full flex justify-end items-center text-end h-8 font-medium '>
              {` `}
            </div>
          </div>
        ),
      },

      {
        id: 'breakEvenFinalPL',
        accessorKey: 'breakEvenFinalPL',
        header: (
          <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
            Minus
          </h6>
        ),
      },
      {
        id: 'breakEvenCumulative',
        accessorKey: 'breakEvenCumulative',
        header: (
          <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
            Cumulative Minus
          </h6>
        ),
        cell: (info) => {

          const parentRow = info.row.getParentRow();
          if (!parentRow) return null; // In case there's no parent row

          // Calculate the cumulative value up to the current row
          let cumulativeValue = 0;
          let boldFont = '';
          for (let i = 0; i <= info.row.index; i++) {
            const subRow = parentRow.subRows[i];
            const finalPL = subRow.original.breakEvenFinalPL || 0;
            cumulativeValue += finalPL;
            if (i === parentRow.subRows.length - 1) {
              boldFont = 'font-bold';
            }
          }
          return (
            <p
              className={`w-full px-4 py-1 flex items-center justify-end text-end ${boldFont}`}
            >
              {isNaN(cumulativeValue) || cumulativeValue === 0
                ? ''
                : `$ ${formatNumber(cumulativeValue)}`}
            </p>
          );
        },
        size: 150,
        maxSize: 200,
        minSize: 150,
        footer: (
          <div className='  flex flex-col  justify-start items-start  w-full'>
            <div className='bg-blue-200 px-4 py-1 w-full flex justify-end items-center text-end h-8 font-medium '>
              {` `}
            </div>
          </div>
        ),
      },
      {
        id: 'breakEvenTotal',
        accessorKey: 'breakEvenTotal',
        header: (
          <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
            Total
          </h6>
        ),
        cell: (info) => {
          const parentRow = info.row.getParentRow();
          if (!parentRow) return null; // In case there's no parent row

          const strike = parentRow.original.original.strike || 0;
          const shares = parentRow.original.original.numberOfOptions * 100 || 0;
          const initialTotal = strike * shares;
          // Calculate the cumulative value up to the current subRow
          let cumulativeFinalPL = 0;
          for (let i = 0; i <= info.row.index; i++) {
            const subRow = parentRow.subRows[i];
            const finalPL = subRow.original.breakEvenFinalPL || 0;
            cumulativeFinalPL += finalPL;
          }

          const total = initialTotal - cumulativeFinalPL;
          let boldFont = '';

          if (info.row.index === parentRow.subRows.length - 1) {
            boldFont = 'font-bold';
          }

          return (
            <p
              className={`w-full px-4 py-1 flex items-center justify-end text-end ${boldFont}`}
            >
              {isNaN(total) ? '' : `$ ${formatNumber(total)}`}
            </p>
          );
        },
      },
    ],
    [handleDelete, updateData, columnVisibility, footerSums, capitalData, token]
  );
};
