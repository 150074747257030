// useTradeStatus.js

import { startOfDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

export const useTradeStatus = (row) => {
  if (row?.original?.name || row?.original?.color) {
    const getValue = row.getValue
      ? (columnId) => row.getValue(columnId)
      : (columnId) => row[columnId];

    // Automatically detect user's timezone
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const isTradeDetailsComplete = [
      'name',
      'tradeDate',
      'expirationDate',
      'strike',
      'premium',
      'numberOfOptions',
    ].every((columnId) => Boolean(getValue(columnId)));

    let currentDate = zonedTimeToUtc(startOfDay(new Date()), userTimeZone);
    let tradeDate = getValue('tradeDate')
      ? zonedTimeToUtc(
          startOfDay(new Date(getValue('tradeDate'))),
          userTimeZone
        )
      : null;
    let closingDate = getValue('closingDate')
      ? zonedTimeToUtc(
          startOfDay(new Date(getValue('closingDate'))),
          userTimeZone
        )
      : null;

    const isTradeDateReached = tradeDate && tradeDate <= currentDate;
    const isClosed =
      closingDate && (getValue('closingPremium') !== null || undefined);

    let value, color, tooltip;

    if (isClosed && isTradeDetailsComplete && isTradeDateReached) {
      value = 'closed';
      color = '#808080'; // Gray
      tooltip = 'Closed';
    } else if (isTradeDetailsComplete && isTradeDateReached) {
      value = 'active';
      color = '#4CAF50'; // Green
      tooltip = 'Active';
    } else {
      value = 'pending';
      color = '#FFEB3B'; // Yellow
      tooltip = 'Pending';
    }

    return { value, color, tooltip };
  }
  return { value: null, color: null, tooltip: null };
};
