import React from 'react';
import { flexRender } from '@tanstack/react-table';

const TableFooter = ({ footerGroups, page }) => {
  return (
    <tfoot className={`${page === 'history' ? 'bg-blue-200' : 'bg-white'} overflow-hidden`}>
      {footerGroups.map(footerGroup => (
        <tr key={footerGroup.id} >
          {footerGroup.headers.map(header => (
            <th key={header.id} className='p-0'>
              {header.isPlaceholder
                ? null
                : flexRender(header.column.columnDef.footer, header.getContext())}
            </th>
          ))}
        </tr>
      ))}
    </tfoot>
  );
};

export default TableFooter;
