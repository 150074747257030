import React from 'react';
import { BiRefresh } from 'react-icons/bi';
import { FaYoutube, FaPalette } from 'react-icons/fa';
import { ActionBar } from '../../utils/tableHelpers';
import ColorsKeyMapModal from '../ColorKeyMapModal';

const TableActionControls = ({ title, icon, newRow, handleSave, data, token, setData, setNewRow, setSort, logout, fetchData, setColorsKeyMapState, colorsKeyMapState, table, page, WheelType }) => {
  return (
    <div className="flex items-center space-x-4 ">
      {title && <ActionBar
        id='addTrade'
        icon={icon}
        title={title}
        page={page}
        newRow={newRow}
        handleSave={() => handleSave({ data, token, setData, setNewRow, setSort, logout, WheelType })}
        cancelNewRow={() => {
          setData((oldData) => oldData.slice(1));
          setNewRow(false);
        }}
        addRow={table.options.meta?.addRow}
        setSort={setSort}
      />}
      <button
        id='refreshTable'
        className="text-gray-800 bg-gray-400 p-2 rounded-lg transition-all duration-200 ease-in-out transform hover:scale-110"
        onClick={() => {
          setData([])
          fetchData()
        }}
      >
        <BiRefresh className="w-4 h-4 transition-all duration-200 ease-in-out transform hover:rotate-180" />
      </button>
      <button
        id='youtubeTutorial'
        className="text-red-700 bg-gray-400 p-2 rounded-lg transition-all duration-200 ease-in-out transform hover:scale-110"
      >
        <FaYoutube className="w-4 h-4 transition-all duration-200 ease-in-out transform hover:rotate-180" />
      </button>
      <button
        id='showColorKeyMap'
        className="text-gray-800 bg-gray-400 p-2 rounded-lg transition-all duration-200 ease-in-out transform hover:scale-110"
        onClick={() => setColorsKeyMapState(true)}
      >
        <FaPalette className="w-4 h-4 transition-all duration-200 ease-in-out transform hover:rotate-180" />
      </button>
      <ColorsKeyMapModal isOpen={colorsKeyMapState} onRequestClose={() => setColorsKeyMapState(false)} />
    </div>
  );
};

export default TableActionControls;
