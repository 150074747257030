import axios from 'axios';
import { toast } from 'react-toastify';

export const fetchAssignedTrades = async (token) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/assigned-trades`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data?.assignedTrades; // Assuming the API returns an object with an `assignedTrades` array
  } catch (error) {
    toast.error('Error fetching assigned trades');
    throw error;
  }
};
