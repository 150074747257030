import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import Logo from '../assets/logo.png';
import { useStateCtx } from '../context/appContext';

const LoadingPage = () => {
  // const { currentColor } = useStateCtx();
  return (
    <div className='h-screen w-screen absolute top-0 left-0 bg-gray-200 bg-opacity-50 z-50 flex flex-col justify-center items-center'>
      <div className='flex items-center text-3xl  justify-center space-x-2'>
        <span className='flex items-center gap-2 ml-3 text-xl font-extrabold tracking-tight dark:text-dark-text text-light-text'>
          {/* <img src={Logo} alt='logo' className='w-32  h-28' /> */}
          <img src={Logo} alt='logo' className=' object-cover h-28' />
          <span className='text-3xl font-Montserrat font-extrabold uppercase animate-bounce '>
            wheel options traders {` `}
            <span className='text-3xl font-Montserrat font-extrabold text-[#3e64de]'>
              Tools
            </span>
          </span>
        </span>
      </div>
    </div>
  );
};

export default LoadingPage;
