import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';
import { useStateCtx } from '../context/appContext';
import {
  NumberCell,
  handleDeleteConfirm,
  handlePortfolioSave,
  handleWatchListDeleteConfirm,
  handleWatchListSave,
  toggleDecayColumns,
} from '../utils/tableHandlers';
import { useAuth } from '../context/auth';
import {
  ConfirmationModalContent,
} from '../utils/tableHelpers';
import { calculateLeftOffset, calculateWidth } from '../utils/mathHelpers';
import { BsQuestionOctagon } from 'react-icons/bs';
import { guidedWatchlist } from '../constants/guidedTutorialData';
import { guidedTutorialHandler } from '../utils/GuidedTutorialFun';
import TableFooter from '../components/Table/TableFooter';
import TableBody from '../components/Table/TableBody';
import TableHeader from '../components/Table/TableHeader';
import TableActionControls from '../components/TableToolBar/TableActionControls';
import SearchAndColumnToggle from '../components/TableToolBar/SearchAndColumnToggle';
import { ImEyePlus } from 'react-icons/im';
import { fetchWatchList, fetchWatchListById } from '../services/watchListServices';
import { WatchListColumns } from '../constants/WatchListColumns';



function WatchList() {
  // CONTEXT
  const { token, logout, visitedPages, markPageAsVisited, visitedPagesLoading } = useAuth();
  const { screenSize, activeMenu, currentColor } = useStateCtx();
  // STATES
  const [data, setData] = useState([]); // use this as your data source
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [contextMenuState, setContextMenuState] = useState(false);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState([]);
  const [sort, setSort] = useState(true);
  const [newRow, setNewRow] = useState(false);
  const [footerSums, setFooterSums] = useState({
    totalOptionsValue: 0,
    totalIfAssigned: 0,
    totalFinalPL: 0,
  });




  const handleDelete = (watchListItem) => {
    setModalState({ isOpen: true, watchListItem });
  };
  const useSkipper = () => {
    const shouldSkipRef = React.useRef(true);
    const shouldSkip = shouldSkipRef.current;

    // Wrap a function with this to skip a pagination reset temporarily
    const skip = React.useCallback(() => {
      shouldSkipRef.current = false;
    }, []);

    React.useEffect(() => {
      shouldSkipRef.current = true;
    });

    return [shouldSkip, skip];
  };
  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();

  const updateData = async ({ rowIndex, rowId, columnId, value }) => {
    skipAutoResetPageIndex();

    try {
      const updateRowWithFetchedData = async () => {
        const updatedTrade = await fetchWatchListById(rowId, token);
        const fieldsToUpdate = [
          'ticker',
          'WatchListName',
          'sector',
          'industry',
          'employees',
          'marketCap',
          'peRatio',
          'averageVolume',
          'currentPrice',
          'prevPrice'
        ];

        setData(old => old.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...row,
              ...fieldsToUpdate.reduce((acc, field) => {
                if (updatedTrade[field] !== undefined) {
                  acc[field] = updatedTrade[field];
                }
                return acc;
              }, {}),
            };
          }
          return row;
        }));
      };

      if (columnId === 'ticker' && rowId) {
        updateRowWithFetchedData();
      } else {
        setData(oldData => oldData.map((row, index) => {
          if (index === rowIndex) {
            return { ...row, [columnId]: value };
          }
          return row;
        }));
      }
    } catch (error) {
      if (error.response?.data?.error === 'Invalid token.') logout();
    }
  };



  const columns = WatchListColumns({ handleDelete, updateData, columnVisibility, footerSums });
  const defaultColumn = {
    cell: (info) => {
      return (
        <NumberCell
          key={info.row.id}
          updateData={updateData}
          row={info.row}
          getValue={info.row.getValue}
          columnId={info.column.id}
        />
      );
    },
  };

  const table = useReactTable({
    data,
    defaultColumn,
    columns,
    state: {
      columnVisibility,
      columnOrder,
      sorting,
      globalFilter,
    },
    enableSorting: sort,
    onGlobalFilterChange: setGlobalFilter,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    columnResizeMode: 'onChange',
    enableColumnResizing: true,
    onColumnOrderChange: setColumnOrder,
    autoResetPageIndex,
    meta: {
      updateData,
      addRow: () => {
        const newRow = { ticker: '' };
        setData((oldData) => [newRow, ...oldData]);
        setNewRow(true);
      },
    },
  });
  const [modalState, setModalState] = useState({ isOpen: false, watchListItem: null });


  const originalDataRef = useRef([]);

  const fetchData = async () => {
    try {
      const trades = await fetchWatchList(token);
      originalDataRef.current = trades || [];
      setData(originalDataRef.current);
    } catch (error) {
      console.error('Error fetching trades:', error);
      if (error.response.data.error === 'Invalid token.') logout();
    }
  };

  useEffect(() => {

    fetchData();
  }, [token]);


  const pageName = 'WatchList'; // Define a unique name for this page
  useEffect(() => {
    if (!visitedPagesLoading) {
      // Check if the page has not been visited and trigger the tutorial
      if (!visitedPages[pageName] || visitedPages[pageName] === false) {
        guidedTutorialHandler(guidedWatchlist);
        markPageAsVisited(pageName); // Mark the page as visited
      }
    }
  }, [visitedPages, markPageAsVisited, pageName, visitedPagesLoading]); // Dependencies for useEffect




  const editableColumns = [
    'ticker',

  ]
  // Render the table
  return (
    <div
      style={{ width: calculateWidth(activeMenu, screenSize) }}
      className='flex flex-col  bg-light-background p-4  h-full mx-auto '
    >
      <div className='flex w-full items-center justify-between mb-6 mt-2 gap-4'>

        <h1 className='text-3xl font-semibold  text-light-text'>
          Watch List
        </h1>
        <button className='animate-scale-once mr-4 ' onClick={() => guidedTutorialHandler(guidedWatchlist)}><BsQuestionOctagon className='w-9 h-9 fill-orange-500' /></button>
      </div>
      <ConfirmationModalContent
        isOpen={modalState.isOpen}
        title='Delete Watched Item'
        message='Are you sure you want to unwatch this ticker? This action cannot be undone.'
        onConfirm={() =>
          handleWatchListDeleteConfirm({
            modalState,
            setModalState,
            token,
            setData,
            logout,
          })
        }
        onCancel={() => {
          setModalState({ isOpen: false, trade: null });
        }}
      />

      <div id='toolbar' className="flex flex-col lg:flex-row justify-between items-center text-md mb-4 bg-gradient-to-r from-gray-200 to-gray-300 px-4 py-2 rounded-lg shadow-md transition-all duration-300 ease-in-out" >

        <SearchAndColumnToggle
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          contextMenuState={contextMenuState}
          setContextMenuState={setContextMenuState}
          table={table}
        />
        <TableActionControls
          title={'Watch a Ticker'}
          icon={<ImEyePlus className="w-5 h-5" />}
          newRow={newRow}
          handleSave={handleWatchListSave}
          data={data}
          token={token}
          setData={setData}
          setNewRow={setNewRow}
          setSort={setSort}
          logout={logout}
          fetchData={fetchData}
          // setColorsKeyMapState={setColorsKeyMapState}
          // colorsKeyMapState={colorsKeyMapState}
          table={table}
        />

      </div>

      <div className='my-2 overflow-x-auto'>
        <div className='py-1 align-middle inline-block '>
          <div className='shadow  border-b border-gray-200  sm:rounded-2xl overflow-hidden'>
            <div className=' rounded-t-3xl'>
              <table
                id='tableContainer'
                style={{
                  width: table.getCenterTotalSize(),
                }}
                className=' divide-y  divide-gray-200 table'
              >
                <TableHeader
                  headerGroups={table.getHeaderGroups()}
                  screenSize={screenSize} // This value should come from your state or context
                  calculateLeftOffset={calculateLeftOffset} // This function should be defined or imported
                  columnVisibility={columnVisibility} // This state should be managed in the parent component
                  newRow={newRow} // This state should be managed in the parent component
                  editableColumns={editableColumns} // This array should be defined or imported
                  currentColor={currentColor} // This value should come from your state or context
                />
                <TableBody
                  data={data}
                  table={table}
                  screenSize={screenSize} // Pass the screenSize state
                  // getTradeStatus={getTradeStatus} // Pass the getTradeStatus function
                  newRow={newRow} // Pass the newRow state
                />
                <TableFooter
                  footerGroups={table.getFooterGroups()}
                />
              </table>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default WatchList;
