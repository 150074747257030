
import { flexRender } from '@tanstack/react-table';
import { MdExpandMore, MdExpandLess, MdTrendingUp } from 'react-icons/md';
import { formatNumber } from '../../utils/mathHelpers';
import { BiPencil } from 'react-icons/bi';
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { updateCapitalData } from '../../services/capitalServices';
import { useAuth } from '../../context/auth';
import { toast } from 'react-toastify';
import { NumericFormat } from 'react-number-format';
import { Fragment, useCallback, useEffect } from 'react';


const HistoryTableBody = ({ table, getTradeStatus, newRow, tradingCapitalData, setTradingCapitalData, setFooterSums }) => {
  const { token } = useAuth()
  const toggleExpanded = useCallback((rowId) => {
    table.setExpanded({
      ...table.getState().expanded,
      [rowId]: !table.getState().expanded[rowId],
    });
  }, [table]);
  // Render the group row and its detail rows if expanded
  const renderNewRow = (row, index) => {
    // const rowClasses += ' ';

    return (
      <tr key={index} className={`bg-blue-200 customPulseAnimation`}>
        {row.getVisibleCells().map(cell => (
          <td key={cell.id} className="mx-0 p-0 text-ellipsis table-cell bg-inherit relative  whitespace-nowrap">
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        ))}
      </tr>
    );
  };
  const renderGroupRow = (row, index) => {
    const isExpanded = table.getState().expanded[row.id];
    const totalFinalPL = row.subRows.reduce((sum, { original }) => sum + original.finalPL, 0);
    return (
      <tr
        key={index} // Unique key for the group row
        className="bg-blue-200 hover:bg-blue-300 cursor-pointer"
        onClick={() => toggleExpanded(row.id)}
      >
        <td colSpan={table.getAllLeafColumns().length}>
          <div className="flex items-center justify-between pl-16 pr-2 py-1">
            <h6 className='font-bold'>{row.original.groupTitle}</h6>
            <p className='flex justify-end items-center font-bold'>$ {formatNumber(totalFinalPL, 0)} {isExpanded ? <MdExpandLess /> : <MdExpandMore />}</p>

          </div>
        </td>
      </tr>
    );
  };
  // Render a single row, which can be a group or detail row
  const renderRow = (row, index, isGroupRow) => {
    let rowClasses = '';

    if (getTradeStatus && getTradeStatus(row.original)?.value === 'closed') {
      rowClasses += ' bg-[#D9D9D9]';
    }

    return (
      <tr
        key={row.id} // Ensures unique key for each row
        className={`${isGroupRow ? 'bg-gray-100' : 'bg-white'} ${rowClasses}`}
      // {...isGroupRow ? { onClick: () => toggleExpanded(row.id) } : {}}
      >
        {row.getVisibleCells().map(cell => (
          <td key={cell.id} style={{
            minWidth: cell.column.getSize()
          }}
            className="mx-0 p-0 text-ellipsis table-cell bg-inherit relative  whitespace-nowrap ">
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        ))}
      </tr>
    );
  };
  const createDynamicSchema = (tradingCapitalData) => {
    const fieldsSchema = tradingCapitalData.reduce((acc, { year, capitals }) => {
      Object.keys(capitals).forEach(monthIndex => {
        const fieldName = `tradingCapital-${monthIndex}-${year}`;
        acc[fieldName] = yup.number().required('Trading capital is required').positive('Trading capital must be positive');
      });
      return acc;
    }, {});

    return yup.object().shape(fieldsSchema);
  };

  const dynamicSchema = createDynamicSchema(tradingCapitalData);

  const { control, handleSubmit, getValues, formState: { errors }, reset, setValue } = useForm({
    resolver: yupResolver(dynamicSchema),
  });

  const onSubmit = async (formData, year, monthIndex) => {
    try {
      await updateCapitalData(year, monthIndex, formData[`tradingCapital-${monthIndex}-${year}`], token);

      // Manually update the local state with the updated data
      setTradingCapitalData(prevData => {
        const newData = [...prevData];
        const yearDataIndex = newData.findIndex(y => y.year.toString() === year);
        if (yearDataIndex > -1) {
          newData[yearDataIndex].capitals[monthIndex] = formData[`tradingCapital-${monthIndex}-${year}`];
        } else {
          newData.push({
            year: parseInt(year, 10),
            capitals: { [monthIndex]: formData[`tradingCapital-${monthIndex}-${year}`] }
          });
        }
        return newData;
      });
      toast.success('Capital updated successfully');
    } catch (error) {
      console.error("Failed to update trading capital", error);
      // Handle errors, e.g., by showing a notification to the user
    }
  };

  const handleEnterPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default action to avoid form submission
      // Blur the input field to trigger the onBlur event handler
      event.target.blur();
    }
  };

  useEffect(() => {
    // Calculate total PL from all groups and identify months with trades
    const monthsWithTrades = new Set();
    const totalPL = table.getRowModel().rows
      .filter(row => row.original.isGroup) // Ensure we're only looking at group rows
      .reduce((acc, row) => {
        const monthIndex = new Date(row.original.groupTitle + " 1").getMonth();
        monthsWithTrades.add(monthIndex); // Add month index to the set if it has trades

        return acc + row.original.subRows.reduce((sum, subRow) => sum + subRow.finalPL, 0);
      }, 0);


    // Update the footer sums
    setFooterSums({ totalPL: formatNumber(totalPL, 0) });

  }, [table, tradingCapitalData, setFooterSums]);


  const renderGroupFooter = (row) => {
    // Calculate total trading capital and ROI for the footer
    const totalFinalPL = row.subRows.reduce((sum, { original }) => sum + original.finalPL, 0);
    // Assuming groupTitle is something like 'January 2024'
    const [monthName, year] = row.original.groupTitle.split(' ');
    const monthIndex = new Date(`${monthName} 1`).getMonth(); // Convert month name to month index
    const yearData = tradingCapitalData.find(y => y.year.toString() === year);
    const totalCapital = yearData?.capitals[monthIndex] || 0;
    const roi = totalCapital > 0 ? (totalFinalPL / totalCapital) * 100 : 0;
    const onBlurHandler = ({ value }) => {
      const inputValue = value; // Direct value from NumericFormat
      const fieldName = `tradingCapital-${monthIndex}-${year}`;
      const initialValue = totalCapital;
      if (inputValue !== initialValue && inputValue) {
        const formData = { [fieldName]: inputValue };
        onSubmit(formData, year, monthIndex);
      }
    };
    return (
      <tr className="bg-blue-100">
        <td colSpan={table.getAllLeafColumns().length} className="pl-16 pr-2 py-1">
          <div className="flex justify-between items-center">
            <form onSubmit={handleSubmit(onSubmit)} className="flex items-center">
              <label htmlFor={`tradingCapital-${monthIndex}-${year}`} className="mr-2 whitespace-nowrap">Trading Capital:{` `}$ </label>

              <Controller
                id={`tradingCapital-${monthIndex}-${year}`}
                name={`tradingCapital-${monthIndex}-${year}`}
                control={control} // Make sure you have defined 'control' from useForm
                defaultValue={totalCapital}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <>
                    <NumericFormat
                      id={`tradingCapital-${monthIndex}-${year}`}
                      className="border-2 border-blue-300 bg-transparent px-2 focus:bg-white focus:bg-opacity-50 focus:border-blue-500 rounded-lg shadow-sm outline-none text-end"
                      thousandSeparator={true}
                      value={getValues(`tradingCapital-${monthIndex}-${year}`)} // Simplified to ensure a fallback value of 0

                      decimalScale={0}
                      fixedDecimalScale={true}
                      onValueChange={(values) => {
                        onChange(values.value);
                      }}
                      onBlur={() => onBlurHandler({ value: value })}
                      onKeyDown={(event) => handleEnterPress(event, year, monthIndex, onChange)}
                      getInputRef={ref}
                    />
                    <label htmlFor={`tradingCapital-${monthIndex}-${year}`} className={''}>
                      <BiPencil className='ml-2 w-6 h-6 text-blue-400' />
                    </label>
                  </>
                )}
              />
              {errors[`tradingCapital-${monthIndex}-${year}`] && <p>{errors[`tradingCapital-${monthIndex}-${year}`].message}</p>}
            </form>
            <span>ROI: {roi ? formatNumber(roi, 2) : 0}%</span>
          </div>
        </td>
      </tr >
    );
  };

  return (
    <tbody className="bg-white divide-y text-sm divide-gray-200">
      {table.getRowModel().rows.map((row, index) => {
        if (row.original.isNew) {
          return renderNewRow(row, `newRow-${index}`, false);
        } else if (row.original.isGroup) {
          const isExpanded = table.getState().expanded[row.id];
          return (
            <Fragment key={`group-${row.id}`
            }>

              {renderGroupRow(row, index)}
              {isExpanded && row.subRows.map((subRow, subIndex) => renderRow(subRow, `${row.id}-${subIndex}`, false))}
              {renderGroupFooter(row)}
            </Fragment>
          );
        }
      })}
      {
        table.getRowModel().rows.length === 0 && (
          <tr>
            <td colSpan={table.getAllLeafColumns().length} className="text-center">
              <MdTrendingUp className="w-8 h-8 mx-auto text-gray-400" />
              <div className="mt-2 text-sm text-gray-500">No trades available</div>
            </td>
          </tr>
        )
      }
    </tbody >
  );
};

export default HistoryTableBody;
