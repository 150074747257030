import axios from 'axios';
import { toast } from 'react-toastify';

export const fetchBreakEven = async (token) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/breakeventrades`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data;
  } catch (error) {
    toast.error('Error fetching trades');
    throw error;
  }
};

export const saveBreakEvenOperation = async (operation, groupId, token) => {
  try {
    // Ensure that the request body includes all necessary data
    const requestBody = {
      groupId, // Optional if you prefer to keep groupId in the URL
      operation,
    };

    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/breakeventrades/`,
      requestBody, // Use the detailed requestBody here
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    toast.success('Operation saved successfully');
    return response.data; // This would typically include the saved operation with an ID from the server
  } catch (error) {
    console.error('Failed to save operation:', error);
    toast.error('Failed to save operation');
    throw error; // Re-throw the error if you need further error handling by the caller
  }
};

export const deleteBreakEvenOperation = async (id, token) => {
  try {
    await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/breakeventrades/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    toast.success('Operation deleted successfully');
  } catch (error) {
    console.error('Failed to delete operation:', error);
    toast.error('Failed to delete operation');
    throw error;
  }
};

export const deleteTradeWithOperations = async (id, token) => {
  try {
    await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/breakeventradeWithOperation/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    toast.success('Trade and its operations deleted successfully');
  } catch (error) {
    console.error('Failed to delete trade and its operations:', error);
    toast.error('Failed to delete trade and its operations');
    throw error;
  }
};

// services/breakevenServices.js
export const updateBreakEvenOperation = async ({
  rowId,
  columnId,
  value,
  token,
}) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/breakEvenOperation/${rowId}`,
      { field: columnId, value },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error updating the operation:', error);
    throw error;
  }
};
