import { zonedTimeToUtc } from 'date-fns-tz';

export const createNewPortfolioTrade = () => {
  // Detect user's timezone if not provided
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Create a new date object and convert it to UTC using the user's timezone
  const tradeDate = zonedTimeToUtc(new Date(), timezone);

  return {
    color: '#26547C', // Default color for a new trade
    putCall: 'put', // Default option type
    ticker: '', // To be provided by the user
    tradeDate: tradeDate, // System-generated, should be timezone-aware
    expirationDate: null, // To be provided by the user
    strike: 0, // To be provided by the user
    tradeType: 'STO', // Default trade type
    premium: 0, // To be provided by the user
    numberOfOptions: 0, // To be provided by the user
    stockPriceAtTrade: 0, // To be fetched from the stock API, editable by the user
    premiumMarketPrice: 0, // To be fetched from the stock API, editable by the user
    closingDate: null, // System-generated or provided by the user
    closingTradeType: '', // To be provided by the user if the trade is closed
    closingPremium: 0, // To be provided by the user if the trade is closed
    finalPL: null, // To be calculated, can be editable by the user
  };
};

export const createNewHistoryTrade = () => {
  // Detect user's timezone if not provided
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Create a new date object and convert it to UTC using the user's timezone
  const tradeDate = zonedTimeToUtc(new Date(), timezone);

  return {
    putCall: 'put', // Default option type
    ticker: '', // To be provided by the user
    tradeDate: tradeDate, // System-generated, should be timezone-aware
    expirationDate: null, // To be provided by the user
    strike: 0, // To be provided by the user
    numberOfOptions: 0, // To be provided by the user
    closingTradeType: '', // To be provided by the user if the trade is closed
    finalPL: 0, // To be calculated, can be editable by the user
  };
};

export const createNewWheelIncomePutTrade = () => {
  // Detect user's timezone if not provided
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Create a new date object and convert it to UTC using the user's timezone
  const currentDate = zonedTimeToUtc(new Date(), timezone);

  return {
    WheelTicker: '', // To be provided by the user
    optionStrikePrice: 0, // To be provided by the user
    WheelOptionPremium: 0, // To be provided by the user
    dateSoldOption: currentDate, // System-generated, should be timezone-aware
    WheelExpDate: null, // To be provided by the user
    optionsToTrade: 0, // To be provided by the user
  };
};

export const createNewWheelIncomeCallTrade = () => {
  // Detect user's timezone if not provided
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Create a new date object and convert it to UTC using the user's timezone
  const currentDate = zonedTimeToUtc(new Date(), timezone);

  return {
    WheelIncomeCallTicker: '', // To be provided by the user
    WheelIncomeCallPriceStockWasBought: 0, // To be provided by the user
    WheelIncomeCallNumberOfShares: 0, // To be provided by the user
    WheelIncomeCallOptionStrikePrice: 0,
    WheelIncomeCallDateSoldOption: currentDate, // System-generated, should be timezone-aware
    WheelIncomeCallExpDate: null, // To be provided by the user
    WheelIncomeCallOptionsToTrade: 0, // To be provided by the user
  };
};

export const createNewBreakEvenTrade = () => {
  // Detect user's timezone if not provided
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Create a new date object and convert it to UTC using the user's timezone
  const tradeDate = zonedTimeToUtc(new Date(), timezone);

  // Initialize a new break-even trade object with default values
  return {
    // ticker: '', // Ticker symbol, to be provided by the user
    shares: 0, // Number of shares/options, to be provided by the user
    tradeType: '', // Type of the trade, e.g., 'Stock', 'Put EXP', etc.
    date: tradeDate, // System-generated trade date, should be timezone-aware
    strike: 0, // Strike price, to be provided by the user
    minus: 0, // Deductions or additional costs, to be provided by the user
    total: 0, // Total value of the trade, to be calculated
    // ... any other fields that are relevant to your break-even calculations
  };
};