import axios from 'axios';
import { toast } from 'react-toastify';

export const createWheelTrade = async (trade, token, WheelType) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/${
        WheelType === 'puts' ? 'wheelIncomePut' : 'wheelIncomeCall'
      }`,
      trade,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    toast.success('Trade created successfully');
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(`${error.response.data.message}`);
    throw error;
  }
};

export const fetchWheelTrades = async (token, sellingType) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/${
        sellingType === 'puts' ? 'wheelIncomePuts' : 'wheelIncomeCalls'
      }`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data;
  } catch (error) {
    toast.error('Error fetching trades');
    throw error;
  }
};

export const updateWheelIncomeCellByRowId = async ({
  row,
  columnId,
  newValue,
  token,
  sellingType,
}) => {
  try {
    const rowId = row.original._id;
    const payload = { columnId, value: newValue };
    // Make an HTTP request to update the specific cell within the trade identified by rowId
    const response = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/${
        sellingType === 'puts' ? 'wheelIncomePut' : 'wheelIncomeCall'
      }/${rowId}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    toast.success('Cell updated successfully');
    return response.data;
  } catch (error) {
    toast.error('Error updating cell');
    throw error;
  }
};

export const fetchWheelTradeById = async (id, token, sellingType) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/${
        sellingType === 'puts' ? 'wheelIncomePut' : 'wheelIncomeCall'
      }/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.wheelIncomePut;
  } catch (error) {
    toast.error('Error fetching trade');
    throw error;
  }
};

export const deleteWheelIncomeById = async (id, token, sellingType) => {
  try {
    await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/${
        sellingType === 'puts' ? 'wheelIncomePut' : 'wheelIncomeCall'
      }/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    toast.success('Trade deleted successfully');
  } catch (error) {
    toast.error('Error deleting trade');
    throw error;
  }
};

export const deleteAllTrades = async () => {
  try {
    await axios.delete(`${process.env.REACT_APP_BASE_URL}/trades`);
    toast.success('All trades deleted successfully');
  } catch (error) {
    toast.error('Error deleting all trades');
    throw error;
  }
};


