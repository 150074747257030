import React, { useState, useEffect } from 'react';
import { format, utcToZonedTime } from 'date-fns-tz';
import { enUS } from 'date-fns/locale';
import { FaChevronDown } from 'react-icons/fa';
import UsaFlag from '../assets/usa.svg';
import UkFlag from '../assets/uk.svg';
import HkFlag from '../assets/hk.svg';

const timeZones = [
  { label: 'Local', value: 'local', flag: null },
  { label: 'New York', value: 'America/New_York', flag: UsaFlag },
  { label: 'London', value: 'Europe/London', flag: UkFlag },
  { label: 'Hong Kong', value: 'Asia/Hong_Kong', flag: HkFlag },
];

const Clock = () => {
  const [time, setTime] = useState(new Date());
  const [timeZone, setTimeZone] = useState('local');
  const [timeZoneLabel, setTimeZoneLabel] = useState('Local'); // new state for holding the label
  const [timeZoneFlag, setTimeZoneFlag] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(interval); // cleanup interval on unmount
  }, []);

  const handleTimeZoneChange = (tzValue, tzLabel, tzFlag) => {
    setTimeZone(tzValue);
    setTimeZoneLabel(tzLabel);
    setTimeZoneFlag(tzFlag);
    setIsOpen(false);
  };

  const getTime = (tz = timeZone) => {
    if (tz === 'local') {
      return format(time, 'hh:mm:ss aa', { locale: enUS });
    } else {
      const zonedDate = utcToZonedTime(time, tz);
      return format(zonedDate, 'hh:mm:ss aa', { timeZone: tz, locale: enUS });
    }
  };

  const getDate = (tz = timeZone) => {
    if (tz === 'local') {
      return format(time, 'dd MMM yyyy', { locale: enUS });
    } else {
      const zonedDate = utcToZonedTime(time, tz);
      return format(zonedDate, 'dd MMM yyyy', { timeZone: tz, locale: enUS });
    }
  };

  return (
    <div id='timeZoneSelection' className='flex items-center text-base md:text-2xl text-center font-Tektur '>
      <div className=' md:px-6'>
        <div
          className='flex justify-between items-center px-2 py-1 rounded-md outline-none bg-light-background hover:bg-gray-200 transition-colors duration-200 text-sm md:text-base cursor-pointer'
          onClick={() => setIsOpen(!isOpen)}
        >
          {timeZoneFlag === null ? (
            ''
          ) : (
            <img src={timeZoneFlag} alt={timeZoneLabel} width='20' />
          )}
          <div className='mx-2'>
            {timeZoneLabel} - {getTime()} | {getDate()}
          </div>
          <FaChevronDown /> {/* add the chevron down icon */}
          {/* add the flag */}
        </div>
        {isOpen && (
          <div className='absolute mt-2 flex rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5'>
            <div className='rounded-md bg-light-background w-full shadow-xs'>
              <div className='py-1 '>
                {timeZones.map((tz) => (
                  <div
                    key={tz.value}
                    onClick={() =>
                      handleTimeZoneChange(tz.value, tz.label, tz.flag)
                    }
                    className='flex px-4 py-2 text-sm justify-between even:bg-gray-200 hover:bg-gray-300 cursor-pointer'
                  >
                    <span>
                      {tz.label} - {getTime(tz.value)}
                    </span>
                    <span>|</span>
                    <span>{getDate(tz.value)}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Clock;
