import React, { useEffect, useState, useRef } from 'react';
import { getBuyingPower, updateBuyingPower } from '../services/tradesServices';
import { useAuth } from '../context/auth';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { formatNumber } from '../utils/mathHelpers';
import { NumericFormat } from 'react-number-format';
import { BiPencil } from 'react-icons/bi';
import { IoMdRefreshCircle } from 'react-icons/io';

const schema = yup.object({
  wheelBuyingPower: yup
    .number()
    .required('Capital is required')
    .positive('Capital must be positive'),
});
const CashRequirements = ({ data }) => {
  const [globalBuyingPower, setGlobalBuyingPower] = useState(0);
  const [editWheelBuyingPower, setEditWheelBuyingPower] = useState(0);
  const { token } = useAuth();
  useEffect(() => {
    const fetchBuyingPower = async () => {
      const { globalBuyingPower, wheelBuyingPower } = await getBuyingPower(
        token
      );
      setGlobalBuyingPower(globalBuyingPower);
      setEditWheelBuyingPower(wheelBuyingPower);
      setValue('wheelBuyingPower', wheelBuyingPower);
    };

    fetchBuyingPower();
  }, []);

  const calculateTotalNeeded = (data) => {
    return data.reduce((total, trade) => {
      const neededForThisTrade =
        trade.optionStrikePrice * trade.optionsToTrade * 100 || 0;
      return total + neededForThisTrade;
    }, 0);
  };
  const totalNeeded = calculateTotalNeeded(data);
  const totalNBTrade = data.length;
  const spendPerTrade = editWheelBuyingPower / totalNBTrade;
  const {
    register,
    handleSubmit,
    setValue,

    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      wheelBuyingPower: editWheelBuyingPower || 0, // Use the prop or state you have for initial value
    },
  });
  register('wheelBuyingPower');
  const formRef = useRef(null);
  const onSubmit = async (formData) => {
    try {
      const { wheelBuyingPower } = formData;
      if (wheelBuyingPower === getValues('wheelBuyingPower')) return;
      if (isNaN(wheelBuyingPower)) {
        // setValue('wheelBuyingPower', 0);
        setEditWheelBuyingPower(getValues('wheelBuyingPower'));
        return;
      }
      await updateBuyingPower('wheel', wheelBuyingPower, token);

      setValue('wheelBuyingPower', wheelBuyingPower);
    } catch (error) {
      console.error('Error updating buying power:', error);
      // Optionally: Display error message
    }
  };

  const handleResetBuyingPower = async () => {
    try {
      await updateBuyingPower('wheel', globalBuyingPower, token);
      setEditWheelBuyingPower(globalBuyingPower);
    } catch {}
  };
  return (
    <div
      id='cashRequirementsSection'
      className=' w-1/2 border border-blue-500  rounded-lg  text-sm'
    >
      <div className='text-blue-800 border px-4 border-blue-500 font-bold text-lg '>
        CASH REQUIREMENTS
      </div>

      {/* Text with values */}
      <div className=' bg-blue-200 text-red-600 font-bold text-base flex justify-between items-center'>
        <span className='pl-4'>
          TOTAL $ NEEDED TO BUY ALL IF TRADES ASSIGNED:
        </span>
        <span className=' w-1/3 rounded-l pr-4 text-end bg-blue-300'>
          $ {formatNumber(totalNeeded, 0)}
        </span>
      </div>

      {/* Text only */}
      <div className='text-blue-800 px-4 text-xs font-semibold '>
        Total payable if all stocks are assigned to you
      </div>

      {/* Text with values */}
      <div className='  bg-red-200 text-red-600 font-bold text-base flex justify-between items-center'>
        <span className='pl-4'>MUST BE LESS OR EQUAL TO:</span>
        <div
          id='buyingPowerInput'
          className='w-1/3  flex  justify-between rounded-l overflow-hidden bg-red-300'
        >
          <div className='flex justify-between'>
            <button
              id='resetBuyingPowerButton'
              // disabled={buyingPower === editableBuyingPower}
              className={`p-1 text-blue-600 focus:outline-none focus:ring focus:border-blue-300`}
              onClick={handleResetBuyingPower}
              title='Reset to original buying power'
            >
              <IoMdRefreshCircle className='w-6 h-6' />
            </button>
            <label
              htmlFor={`CashRequirementsBuyingPower`}
              className={`p-1 text-red-600`}
            >
              <BiPencil className='w-6 h-6' />
            </label>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className='w-full flex justify-end'
          >
            <div className=' w-full inline-flex items-center'>
              <NumericFormat
                id={`CashRequirementsBuyingPower`}
                value={editWheelBuyingPower}
                type='text'
                prefix={`$ `}
                // decimalScale={2}
                // fixedDecimalScale={true}
                allowNegative={false}
                thousandSeparator=','
                className='w-full border-2 border-red-300 bg-transparent px-4 focus:bg-white focus:bg-opacity-50 focus:border-red-600 rounded shadow-sm  text-end outline-none'
                onBlur={() =>
                  // if( editWheelBuyingPower !==  )
                  onSubmit({
                    wheelBuyingPower: editWheelBuyingPower,
                  })
                }
                onKeyDown={(e) => {
                  // Check if the key pressed is Enter
                  if (e.key === 'Enter') {
                    e.preventDefault(); // Prevent the default action
                    onSubmit({
                      wheelBuyingPower: editWheelBuyingPower,
                    });
                  }
                }}
                onValueChange={(values) => {
                  const { floatValue: value } = values;
                  // Reset to the last valid number or empty if there's no valid numeric input
                  // if (value === editWheelBuyingPower) return;
                  if (value === '') {
                    setEditWheelBuyingPower(0);
                  } else {
                    setEditWheelBuyingPower(value);
                    // setValue('wheelBuyingPower', value);
                  }
                }}
              />
            </div>
            {errors.wheelBuyingPower && (
              <p className='text-red-500'>{errors.wheelBuyingPower.message}</p>
            )}
            <button
              type='submit'
              style={{ display: 'none' }}
              ref={formRef}
            ></button>
          </form>
        </div>
      </div>

      {/* Text only */}
      <div className='text-blue-800 px-4 text-xs font-semibold'>
        Your total buying power
      </div>

      {/* Text with values */}
      <div className='  bg-blue-200 text-red-600  font-bold text-base flex justify-between items-center'>
        <span className='pl-4'>YOU CAN SPEND UP TO ON EACH TRADE:</span>
        <span className='w-1/3 rounded-l pr-4 text-end bg-blue-300'>
          $ {totalNBTrade > 0 ? formatNumber(spendPerTrade, 0) : 0}
        </span>
      </div>

      {/* Text only */}
      <div className='text-blue-800 px-4 font-semibold text-xs'>
        Buying power / the number of stocks selected
      </div>
    </div>
  );
};

export default CashRequirements;
