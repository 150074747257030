import axios from 'axios';
import { formatISO, parseISO } from 'date-fns';
import { toast } from 'react-toastify';

// Fetch closed trades from the history portfolio
export const fetchClosedTrades = async (token, dateRange) => {
  let { startDate, endDate } = dateRange;

  // Check if startDate and endDate are strings, and convert them to Date objects if they are
  if (typeof startDate === 'string') {
    startDate = parseISO(startDate);
  }
  if (typeof endDate === 'string') {
    endDate = parseISO(endDate);
  }

  // Use formatISO to convert Date objects to ISO strings in UTC
  const startDateString = formatISO(startDate, { representation: 'date' });
  const endDateString = formatISO(endDate, { representation: 'date' });

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/HistoryPortfolioClosedTrades`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          startDate: startDateString,
          endDate: endDateString,
        },
      }
    );
    return response?.data?.trades;
  } catch (error) {
    toast.error('Error fetching closed trades');
    throw error;
  }
};

// Fetch all manual history trades
export const fetchManualTrades = async (token) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/historyTrades`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data?.historyTrades;
  } catch (error) {
    toast.error('Error fetching manual trades');
    throw error;
  }
};

// Fetch a specific manual history trade by ID
export const fetchManualTradeById = async (id, token) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/historyTrade/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.historyTrade;
  } catch (error) {
    toast.error('Error fetching manual trade');
    throw error;
  }
};

// Create a new manual history trade
export const addManualTrade = async (trade, token) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/historyTrade`,
      trade,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    toast.success('Manual trade added successfully');
    return response.data.manualTrade;
  } catch (error) {
    console.log(error);
    toast.error(`${error.response.data.message}`);
    throw error;
  }
};

// Update a specific manual history trade
export const updateManualTrade = async (id, trade, token) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/historyTrade/${id}`,
      trade,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    toast.success('Manual trade updated successfully');
    return response.data;
  } catch (error) {
    toast.error('Error updating manual trade');
    throw error;
  }
};

// Delete a specific manual history trade by ID
export const deleteManualTradeById = async (id, token) => {
  try {
    await axios.delete(`${process.env.REACT_APP_BASE_URL}/historyTrade/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    toast.success('Manual trade deleted successfully');
  } catch (error) {
    toast.error('Error deleting manual trade');
    throw error;
  }
};

// Optional: Delete all manual history trades
export const deleteAllManualTrades = async (token) => {
  try {
    await axios.delete(`${process.env.REACT_APP_BASE_URL}/historyTrades`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    toast.success('All manual trades deleted successfully');
  } catch (error) {
    toast.error('Error deleting all manual trades');
    throw error;
  }
};
