import { useEffect, useState } from "react";
import ActiveTrades from "../components/Dashboard/ActiveTrades";
import ActiveTradesData from "../components/Dashboard/ActiveTradesData";
import AssignedStock from "../components/Dashboard/AssignedStock";
import CumulativePLChart from "../components/Dashboard/CumulativePLChart";
import TradeHistory from "../components/Dashboard/TradeHistory";
import TradesByMarketIndustryChart from "../components/Dashboard/TradesByMarketIndustryChart";
import WatchList from "../components/Dashboard/WatchList";
import { fetchDashboardData, fetchDashboardWatchListData, fetchPortfolioData } from "../services/dashboardServices";
import { useAuth } from "../context/auth";
import { fetchClosedTrades } from "../services/historyTradesSevices";
import { fetchCapitalByDateRange } from "../services/capitalServices";
import { calculateData } from "../utils/mathHelpers";
import { eachYearOfInterval, endOfYear, startOfYear } from "date-fns";
import PLCharts from "../components/Dashboard/CumulativePLChart";
import { guidedTutorialHandler } from "../utils/GuidedTutorialFun";
import { guidedDashboard } from "../constants/guidedTutorialData";
import { BsQuestionOctagon } from "react-icons/bs";

const Dashboard = () => {
  const { token, visitedPages, markPageAsVisited, visitedPagesLoading, isLoggedIn } = useAuth()
  const [watchListData, setWatchListData] = useState([]);
  const [portfolioData, setPortfolioData] = useState(
    {
      industryData: {},
      sectorData: {},
      activeTrades: [],

    }
  )

  useEffect(() => {
    const fetchWatchListData = async () => {
      try {
        const data = await fetchDashboardWatchListData(token);
        setWatchListData(data);
      } catch (error) {
        console.error('Error fetching WatchList data:', error);
        // Handle the error appropriately
      }
    };

    fetchWatchListData();
  }, [token]);

  const [dateRange, setDateRange] = useState({
    startDate: startOfYear(new Date()),
    endDate: endOfYear(new Date()),
  });

  const [data, setData] = useState([]);
  const [yearlyCapital, setYearlyCapital] = useState(0);
  // State for date range based on the selected year
  const fetchData = async () => {
    try {
      const tradesData = await fetchClosedTrades(token, dateRange);
      const capitalsData = await fetchCapitalByDateRange(
        dateRange.startDate,
        dateRange.endDate,
        token
      );

      const {
        industryData,
        sectorData,
        activeTrades,

      } = await fetchPortfolioData(token)

      setPortfolioData({
        industryData,
        sectorData,
        activeTrades,
      })
      // Perform your calculations here and update the table data
      const calculatedData = calculateData(tradesData, capitalsData);
      setData(calculatedData);
      const capitalsObject =
        capitalsData.length > 0 && capitalsData[0]?.capitals;
      const yearlyCapital = Object.values(capitalsObject).reduce(
        (acc, curr) => acc + curr,
        0
      );
      setYearlyCapital(yearlyCapital);

      // setYearlyCapital(capitalsData[0]?.capitals.reduce((acc, curr) => acc + curr.capital, 0));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [token, dateRange]);
  const handleCapitalUpdate = async () => {
    // Simply call fetchData to refresh data based on the current dateRange
    await fetchData();
  };




  const pageName = 'dashboard'; // Define a unique name for this page
  useEffect(() => {
    if (!visitedPagesLoading && isLoggedIn) {
      // Check if the page has not been visited and trigger the tutorial
      if (!visitedPages[pageName] || visitedPages[pageName] === false) {



        guidedTutorialHandler(guidedDashboard);
        markPageAsVisited(pageName); // Mark the page as visited
      }
    }
  }, [visitedPages, markPageAsVisited, pageName, visitedPagesLoading]); // Dependencies for useEffect


  return (
    <div className='p-8  '>
      <div className='flex w-full items-center justify-between mb-6 mt-2 gap-4'>

        <h1 className='text-3xl font-semibold  text-light-text'>
          Dashboard
        </h1>
        <button className='animate-scale-once mr-4 ' onClick={() => guidedTutorialHandler(guidedDashboard)}><BsQuestionOctagon className='w-9 h-9 fill-orange-500' /></button>
      </div>
      <TradeHistory
        data={data}
        handleCapitalUpdate={handleCapitalUpdate}
        yearlyCapital={yearlyCapital}
        page='dashboard'
      />
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-8 gap-4'>
        <div className="bg-white col-span-4 md:col-span-4 lg:col-span-8 p-4 rounded-lg shadow-lg overflow-hidden hover:bg-opacity-60 transition duration-300 ease-in-out ">
          <ActiveTradesData trades={portfolioData.activeTrades} />
        </div>
        <div className="bg-white col-span-4 md:col-span-2 lg:col-span-2 p-4 rounded-lg shadow-lg overflow-hidden hover:bg-opacity-60 transition duration-300 ease-in-out ">
          <AssignedStock />
        </div>
        <div className="bg-white col-span-4 md:col-span-2  lg:col-span-2 p-4 rounded-lg shadow-lg overflow-hidden hover:bg-opacity-60 transition duration-300 ease-in-out ">
          <WatchList watchListData={watchListData} />
        </div>
        <div className="bg-white col-span-4 md:col-span-2 lg:col-span-4 p-4 rounded-lg shadow-lg overflow-hidden hover:bg-opacity-60 transition duration-300 ease-in-out ">
          <PLCharts
            title='Cumulative P&L with Monthly Number of Trades (YTD)'
            id='cumulative-pl-chart'
            data={data}
            gradientColors={{
              bar: { start: '#51a0ff', end: '#217aff' },
              line: { start: '#14b8a6', end: '#22c55e' },
            }}
          />
        </div>
        <div className="bg-white col-span-4 md:col-span-2 lg:col-span-2 p-4 rounded-lg shadow-lg overflow-hidden hover:bg-opacity-60 transition duration-300 ease-in-out ">
          <ActiveTrades activeTrades={portfolioData.sectorData} />
        </div>
        <div className="bg-white col-span-4 md:col-span-4 lg:col-span-4 p-4 rounded-lg shadow-lg overflow-hidden hover:bg-opacity-60 transition duration-300 ease-in-out ">
          <TradesByMarketIndustryChart industryData={portfolioData.industryData} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
