import { Link, NavLink } from 'react-router-dom';
import Logo from '../assets/logo.png';
import { MdBalance, MdHistory } from 'react-icons/md';
import { FiEye } from 'react-icons/fi';
import { AiOutlineInfoCircle, AiOutlineStock } from 'react-icons/ai';
import { LuLayoutDashboard } from 'react-icons/lu';
import { useStateCtx } from '../context/appContext';
import { RiLineChartLine } from 'react-icons/ri';
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import { BsChevronLeft } from 'react-icons/bs';
import * as PATHS from '../constants/routes';

export const links = [
  {
    title: 'Overview',
    links: [
      {
        name: 'Dashboard',
        link: PATHS.DASHBOARD,
        icon: <LuLayoutDashboard className='w-5 h-5' />,
      },
    ],
  },
  {
    title: 'Live tracking',
    links: [
      {
        name: 'Portfolio',
        link: PATHS.PORTFOLIO,
        icon: <RiLineChartLine className='w-5 h-5' />,
      },
      {
        name: 'Watch list',
        link: PATHS.WATCH_LIST,
        icon: <FiEye className='w-5 h-5' />,
      },
    ],
  },
  {
    title: 'Trading Calculators',
    links: [
      {
        name: 'Wheel calculator',
        link: PATHS.WHEEL_INCOME,
        icon: <FaRegMoneyBillAlt className='w-5 h-5' />,
      },
      {
        name: 'Breakevens calculator',
        link: PATHS.BREAKEVEN,
        icon: <MdBalance className='w-5 h-5' />,
      },
      {
        name: 'Assigned stock calculator',
        link: PATHS.ASSIGNED_STOCK,
        icon: <AiOutlineStock className='w-5 h-5' />,
      },
    ],
  },
  {
    title: 'History',
    links: [
      {
        name: 'Trading history',
        link: PATHS.TRADING_HISTORY,
        icon: <MdHistory className='w-5 h-5' />,
      },
    ],
  },
  {
    title: 'references',
    links: [
      {
        name: 'About Us',
        link: PATHS.ABOUT_US,
        icon: <AiOutlineInfoCircle className='w-5 h-5' />,
      },
    ],
  },
];

const Sidebar = () => {
  const { activeMenu, setActiveMenu, screenSize, currentColor } = useStateCtx();
  // const currentCdolor = 'bg-blue-600';
  const handleCloseSideBar = () => {
    if (activeMenu && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  return (
    <div
      id='sidebarNavigation'
      className='h-full  custom-scrollbar md:overflow-hidden overflow-auto md:hover:overflow-auto dark:bg-dark-background-90 bg-light-background shadow-xl'
    >
      {activeMenu && (
        <aside className='h-full flex flex-col '>
          <div className='flex justify-between  items-center'>
            <Link
              to={'/'}
              onClick={handleCloseSideBar}
              className='flex items-center h-[70px] gap-2 ml-3 text-xl font-extrabold tracking-tight dark:text-dark-text text-light-text'
            >
              <img src={Logo} alt='logo' className=' object-cover h-12' />
              <span className='text-sm font-Montserrat font-extrabold uppercase'>
                wheel options traders
              </span>
            </Link>
            <button
              type='button'
              style={{ color: currentColor }}
              className={`relative text-3xl font-bold rounded-full p-2 lg:hidden hover:bg-light-gray`}
              onClick={() => setActiveMenu((prevState) => !prevState)}
            >
              <BsChevronLeft strokeWidth='1' />
            </button>
          </div>
          <div className='flex flex-col flex-1 justify-between '>
            <div className='mt-10'>
              {links.map((item) => (
                <div key={item.title}>
                  <p className='m-3 mt-4 uppercase dark:text-dark-text-light text-light-text-light'>
                    {item.title}
                  </p>
                  {item.links.map((Link) => (
                    <NavLink
                      key={Link.name}
                      to={Link.link}
                      onClick={handleCloseSideBar}
                      // activeClassName='bg-gradient-to-r from-light-primary to-light-secondary dark:from-dark-primary dark:to-dark-secondary'
                      className={`flex items-center gap-4 pl-4 pt-3 pb-2.5 rounded-lg text-md m-2  text-light-text  dark:text-dark-text hover:text-black dark:hover:text-dark-text-light hover:bg-dark-background hover:bg-opacity-20 hover:dark:bg-light-background hover:dark:bg-opacity-20`}
                      style={({ isActive }) => {
                        return {
                          background: isActive ? `${currentColor}` : '',
                          color: isActive ? '#fff' : '',
                          fontWeight: isActive ? '600' : '',
                        };
                      }}
                    >
                      {Link.icon}
                      <span className='capitalize'>{Link.name}</span>
                    </NavLink>
                  ))}
                </div>
              ))}
            </div>
            <p className='w-full text-center py-2 text-sm'>
              Copyright &copy; wheel options traders
            </p>
          </div>
        </aside>
      )}
    </div>
  );
};

export default Sidebar;
