import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';

import { BsCurrencyDollar } from 'react-icons/bs';
import { BiPencil } from 'react-icons/bi';
import { LuPercent, LuPlus } from 'react-icons/lu';
// import TradingCapitalModal from '../TradingCapitalModal';
import {
  getBuyingPower,
  updateBuyingPower,
} from '../../services/tradesServices';
import { useAuth } from '../../context/auth';
import { formatNumber } from '../../utils/mathHelpers';

// Validation schema
const schema = yup
  .object({
    capital: yup
      .number()
      .required('Capital is required')
      .positive('Capital must be positive'),
  })
  .required();

const TradeHistory = ({
  data,
  yearlyCapital,
  handleCapitalUpdate,
  year,
  page,
  initialBuyingPower,
}) => {
  const [editMode, setEditMode] = useState(false);
  const { token } = useAuth();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    // defaultValues: {
    //   buyingPower: initialBuyingPower || 0, // Use the prop or state you have for initial value
    // },
  });
  const formRef = useRef(null);

  const onEditClick = () => {
    if (page === 'dashboard') {
      if (editMode) {
        onSubmit({ buyingPower: getValues('buyingPower') }); // Correctly triggers form submission
      }
      setEditMode(!editMode);
    }
  };
  const fetchBuyingPower = async () => {
    try {
      const { globalBuyingPower } = await getBuyingPower(token);
      setValue('buyingPower', globalBuyingPower);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (page === 'dashboard') {
      fetchBuyingPower();
    }
  }, [page, token]);

  const onSubmit = async (formData) => {
    try {
      const { buyingPower } = formData;
      await updateBuyingPower('global', buyingPower, token);
      // SetEditMode and any other state updates here
      setEditMode(false);
      // Optionally: Display success message
    } catch (error) {
      console.error('Error updating buying power:', error);
      // Optionally: Display error message
    }
  };

  // Extract the latest month data (assuming data is ordered by month)
  const latestMonthData = data.length > 0 && data[data.length - 1];

  return (
    <div
      className={` ${
        page === 'dashboard' ? 'lg:grid-cols-4 ' : 'lg:grid-cols-3'
      } grid grid-cols-1 md:grid-cols-2  gap-4 mb-8`}
    >
      {/* Repeated for each box */}
      <div
        id='pnlCard'
        className='bg-white group relative p-4 rounded-lg shadow-lg overflow-hidden hover:bg-opacity-60 transition duration-300 ease-in-out flex flex-col justify-between'
      >
        <div className='flex flex-col justify-center after:content-[""] after:block after:absolute after:bg-green-500 after:h-1 after:w-2/3 after:bottom-0 after:left-0 after:group-hover:w-full after:group-hover:left-0 after:transition-all'>
          <div className='flex w-full flex-col justify-between'>
            <p className='text-lg font-semibold text-gray-600 uppercase'>
              P/L ({year ? year : 'YTD'})
            </p>
            <p className='text-3xl flex items-end justify-between font-bold text-end text-gray-600'>
              <BsCurrencyDollar className='text-4xl font-normal text-end  text-green-500 ' />
              {latestMonthData.pnlCum || 0}
            </p>
          </div>
        </div>
      </div>

      <div
        id='roiCard'
        className='bg-white group relative p-4 rounded-lg shadow-lg overflow-hidden hover:bg-opacity-60 transition duration-300 ease-in-out flex flex-col justify-between'
      >
        <div className='flex flex-col justify-center after:content-[""] after:block after:absolute after:bg-teal-500 after:h-1 after:w-2/3 after:bottom-0 after:left-0 after:group-hover:w-full after:group-hover:left-0 after:transition-all'>
          <div className='flex w-full flex-col justify-between'>
            <p className='text-lg font-semibold text-gray-600 uppercase'>
              ROI ({year ? year : 'YTD'})
            </p>
            <p className='text-3xl flex items-center justify-between font-bold text-end text-gray-600'>
              <LuPercent className='text-4xl font-normal text-end  text-teal-500 ' />
              {latestMonthData.roiCum || 0}
            </p>
          </div>
        </div>
      </div>

      <div
        id='tradesCounter'
        className='bg-white group relative p-4 rounded-lg shadow-lg overflow-hidden hover:bg-opacity-60 transition duration-300 ease-in-out flex flex-col justify-between'
      >
        <div className='flex flex-col justify-center after:content-[""] after:block after:absolute after:bg-blue-500 after:h-1 after:w-2/3 after:bottom-0 after:left-0 after:group-hover:w-full after:group-hover:left-0 after:transition-all'>
          <div className='flex w-full flex-col justify-between'>
            <p className='text-lg font-semibold text-gray-600 uppercase'>
              Trades ({year ? year : 'YTD'})
            </p>
            <p className='text-3xl flex items-center justify-between font-bold text-end text-gray-600'>
              <LuPlus className='text-4xl font-normal text-end  text-blue-500 ' />
              {latestMonthData.tradesCum || 0}
            </p>
          </div>
        </div>
      </div>
      {page === 'dashboard' && (
        <div
          id='buyingPower'
          className='bg-white group relative p-4 rounded-lg shadow-lg overflow-hidden hover:bg-opacity-60 transition duration-300 ease-in-out flex flex-col justify-between'
        >
          <div className='flex flex-col justify-center after:content-[""] after:block after:absolute after:bg-yellow-500 after:h-1 after:w-2/3 after:bottom-0 after:left-0 after:group-hover:w-full after:group-hover:left-0 after:transition-all'>
            <div className='flex w-full flex-col justify-between'>
              <div className='flex justify-between items-center'>
                <p className='text-lg font-semibold whitespace-nowrap text-gray-600 uppercase'>
                  ADD YOUR BUYING POWER HERE
                  {year ? `(${year})` : ''}
                </p>
                <button
                  id='buyingPowerEdit'
                  onClick={onEditClick}
                  className='ml-4'
                >
                  <BiPencil className='h-5 w-5 text-gray-500 hover:text-blue-300' />
                </button>
              </div>
              <div className='flex justify-between items-center'>
                <BsCurrencyDollar className='inline-block text-4xl text-yellow-500' />

                {editMode ? (
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className='w-full flex justify-end'
                  >
                    <input
                      {...register('buyingPower')}
                      className='w-full px-2 py-0 rounded-md border text-3xl font-bold text-gray-600 text-end border-gray-300 focus:outline-none focus:border-blue-500'
                      autoFocus
                      onKeyDown={(e) => {
                        // Check if the key pressed is Enter
                        if (e.key === 'Enter') {
                          e.preventDefault(); // Prevent the default action
                          onEditClick(); // Call the onEditClick function
                        }
                      }}
                      onBlur={onEditClick}
                      // type='number'
                      // min={0}
                    />
                    {errors.buyingPower && (
                      <p className='text-red-500'>
                        {errors.buyingPower.message}
                      </p>
                    )}
                    <button
                      type='submit'
                      style={{ display: 'none' }}
                      ref={formRef}
                    ></button>
                  </form>
                ) : (
                  <span className='text-3xl flex items-center  justify-end w-full font-bold text-gray-600'>
                    {page === 'dashboard' ? (
                      <div className='flex justify-end items-center'>
                        {/* <p className=' text-ellipsis whitespace-nowrap font-light text-sm'>
                        Add your current buying power here
                      </p> */}
                        ${formatNumber(getValues('buyingPower'), 0) || 0}
                      </div>
                    ) : (
                      `${formatNumber(yearlyCapital, 0)}`
                    )}
                    {/* <BsCurrencyDollar className='inline-block text-4xl text-yellow-500' /> */}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TradeHistory;
