import React, { useEffect, useRef, useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getExpandedRowModel,
} from '@tanstack/react-table';
import { useStateCtx } from '../context/appContext';
import {
  NumberCell,
  handleDeleteConfirm,
  handleHistorySave,
  handlePortfolioSave,
  toggleDecayColumns,
} from '../utils/tableHandlers';
import { fetchTradeById, fetchTrades } from '../services/tradesServices';
import { useAuth } from '../context/auth';
import {
  ConfirmationModalContent,
  getTradeStatus,
} from '../utils/tableHelpers';
import { calculateFinalPL, calculateHistoryFinalPL, calculateLeftOffset, calculateTradeSums, calculateWidth, formatNumber, groupTradesByMonth } from '../utils/mathHelpers';
import { BsQuestionOctagon } from 'react-icons/bs';
import { guidedHistory, guidedPortfolio } from '../constants/guidedTutorialData';
import { guidedTutorialHandler, guidedTutorialHistoryHandler } from '../utils/GuidedTutorialFun';
import { createNewHistoryTrade, createNewPortfolioTrade } from '../constants/newRowsData';
import TableFooter from '../components/Table/TableFooter';
import TableBody from '../components/Table/TableBody';
import TableHeader from '../components/Table/TableHeader';
import TableActionControls from '../components/TableToolBar/TableActionControls';
import SearchAndColumnToggle from '../components/TableToolBar/SearchAndColumnToggle';
import DateRangePicker from '../components/TableToolBar/DateRangePicker';
import { MdHistory } from 'react-icons/md';
import { fetchClosedTrades } from '../services/historyTradesSevices';
import { TradingHistoryColumns } from '../constants/TradingHistoryColumns';
import { endOfYear, startOfYear } from 'date-fns';
import HistoryChartsDrawer from '../components/HistoryChartsDrawer';
import { fetchCapitalByDateRange } from '../services/capitalServices';
import HistoryTableBody from '../components/Table/HistoryTablebody';

function TradingHistory() {

  // CONTEXT
  // const { } = useAuth();
  const { token, logout, dateRange, setDateRange, expanded, setExpanded, visitedPages, markPageAsVisited, visitedPagesLoading } = useAuth();
  const { screenSize, activeMenu, currentColor } = useStateCtx();
  // STATES
  const [data, setData] = useState([]); // use this as your data source
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [contextMenuState, setContextMenuState] = useState(false);
  const [colorsKeyMapState, setColorsKeyMapState] = useState(false)
  const [columnVisibility, setColumnVisibility] = useState({});

  const [capitalData, setCapitalData] = useState([]);

  const [columnOrder, setColumnOrder] = useState([]);
  const [sort, setSort] = useState(true);
  const [newRow, setNewRow] = useState(false);
  const [footerSums, setFooterSums] = useState({
    totalPL: 0,
  });
  const [isOpenDrawer, setIsOpenDrawer] = useState(false)
  const pageName = 'history'; // Define a unique name for this page

  useEffect(() => {
    if (!visitedPagesLoading) {
      // Check if the page has not been visited and trigger the tutorial
      if (!visitedPages[pageName] || visitedPages[pageName] === false) {


        guidedTutorialHistoryHandler(isOpenDrawer, setIsOpenDrawer);
        markPageAsVisited(pageName); // Mark the page as visited
      }
    }
  }, [visitedPages, markPageAsVisited, pageName, visitedPagesLoading]); // Dependencies for useEffect


  const handleDelete = (trade) => {
    setModalState({ isOpen: true, trade });
  };
  const useSkipper = () => {
    const shouldSkipRef = React.useRef(true);
    const shouldSkip = shouldSkipRef.current;

    // Wrap a function with this to skip a pagination reset temporarily
    const skip = React.useCallback(() => {
      shouldSkipRef.current = false;
    }, []);

    useEffect(() => {
      shouldSkipRef.current = true;
    });

    return [shouldSkip, skip];
  };
  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();

  const updateData = React.useCallback(({ rowId, rowIndex, columnId, value }) => {
    // Skip page index reset until after next rerender
    skipAutoResetPageIndex();
    setData(old => old.map((row, index) => {
      // If it's a grouped row, update the subRows
      if (row.isGroup && rowId) {
        const subRows = row.subRows.map((subRow, index) => {
          if (subRow._id === rowId) {
            return { ...subRow, [columnId]: value };
          }
          return subRow;
        });
        return { ...row, subRows };
      }

      // If it's a regular row, update it directly
      else if (!rowId && rowIndex === 0 && !row.isGroup) {
        return { ...row, [columnId]: value };
      }
      return row;
    }));
  }, [setData, skipAutoResetPageIndex]);

  const columns = TradingHistoryColumns({ handleDelete, updateData, columnVisibility, footerSums, capitalData });
  const defaultColumn = {
    cell: (info) => {
      return (
        <NumberCell
          key={info.row.id}
          updateData={updateData}
          row={info.row}
          getValue={info.row.getValue}
          columnId={info.column.id}
        />
      );
    },
  };


  const [modalState, setModalState] = useState({ isOpen: false, trade: null });

  const originalDataRef = useRef([]);

  // Function to load capital data
  const loadCapitalData = async () => {
    try {
      const fetchedData = await fetchCapitalByDateRange(dateRange.startDate, dateRange.endDate, token);
      setCapitalData(fetchedData); // Set the fetched data to state
    } catch (error) {
      // Handle error (e.g. show a notification to the user)
    }
  };
  useEffect(() => {
    // Function to filter subRows based on the globalFilter
    const filterSubRows = (subRows, filterValue) => {
      if (!filterValue) return subRows;
      const lowerCaseFilterValue = filterValue.toLowerCase();

      return subRows.filter(subRow =>
        Object.values(subRow).some(value =>
          String(value).toLowerCase().includes(lowerCaseFilterValue)
        )
      );
    };

    // Function to filter data (including subRows) based on the globalFilter
    const filterData = (data, filterValue) => {
      if (!filterValue) return data;

      return data.map(row => {
        // Check if row has subRows
        if (row.subRows) {
          // Filter subRows
          const filteredSubRows = filterSubRows(row.subRows, filterValue);

          // Return a new row object with filtered subRows
          return { ...row, subRows: filteredSubRows };
        }

        // Return the row unchanged if it doesn't have subRows
        return row;
      });
    };

    // Apply filter to the original data if globalFilter is not empty, otherwise, reset to original data
    const filteredData = globalFilter ? filterData(originalDataRef.current, globalFilter) : originalDataRef.current;
    setData(filteredData); // Update the state with filtered or original data
  }, [globalFilter, originalDataRef]);

  const table = useReactTable({
    data,
    defaultColumn,
    columns,
    state: {
      columnVisibility,
      columnOrder,
      sorting,
      globalFilter,
      expanded,
    },
    getSubRows: (row) => row.subRows || [],
    onExpandedChange: setExpanded,
    enableSorting: sort,
    onGlobalFilterChange: setGlobalFilter,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    columnResizeMode: 'onChange',
    enableColumnResizing: true,
    onColumnOrderChange: setColumnOrder,
    autoResetPageIndex,
    meta: {
      updateData,
      addRow: () => {
        const newTrade = createNewHistoryTrade();
        const newRow = { ...newTrade, isNew: true };

        // Use setData to update the state instead of modifying the data directly.
        setData(currentData => {
          // Prepend the new row to the data array, ensuring it's not inadvertently modifying other groups
          return [newRow, ...currentData];
        });
        setNewRow(true);
      },


    },

  });
  const fetchData = async () => {
    try {
      const trades = await fetchClosedTrades(token, dateRange);
      // Call the function to load data
      originalDataRef.current = groupTradesByMonth(trades) || [];
      setData(originalDataRef.current);
    } catch (error) {
      console.error('Error fetching trades:', error);
      if (error.response.data.error === 'Invalid token.') logout();
    }
  };

  useEffect(() => {
    if (!isOpenDrawer) {
      fetchData();
      loadCapitalData();
    }
  }, [token, dateRange, logout, isOpenDrawer]);
  useEffect(() => {
    // fetchData();
    loadCapitalData();
  }, [token, data]);

  const editableColumns = [
    'color',
    'putCall',
    'ticker',
    'tradeDate',
    'expirationDate',
    'strike',
    'numberOfOptions',
    'closingTradeType',
    'finalPL',
  ];

  // Render the table
  return (
    <div
      style={{ width: calculateWidth(activeMenu, screenSize) }}
      className='flex flex-col  bg-light-background p-4  h-full mx-auto '
    >
      <div className='flex w-full items-center justify-between mb-6 mt-2 gap-4'>

        <h1 className='text-3xl font-semibold  text-light-text'>
          Trading History
        </h1>
        <button className='animate-scale-once mr-4 ' onClick={() => guidedTutorialHistoryHandler(isOpenDrawer, setIsOpenDrawer)}><BsQuestionOctagon className='w-9 h-9 fill-orange-500' /></button>
      </div>
      <ConfirmationModalContent
        isOpen={modalState.isOpen}
        title='Delete Trade'
        message='Are you sure you want to delete this trade? This action cannot be undone.'
        onConfirm={() =>
          handleDeleteConfirm({
            modalState,
            setModalState,
            token,
            setData,
            logout,
            page: 'history',
          })
        }
        onCancel={() => {
          setModalState({ isOpen: false, trade: null });
        }}
      />
      <HistoryChartsDrawer isOpenDrawer={isOpenDrawer} setIsOpenDrawer={setIsOpenDrawer} />
      <div id='toolbar' className="flex flex-col md:flex-row flex-wrap justify-between items-center mb-4 gap-2  bg-gray-200  px-4 py-2 rounded-lg shadow-md " >

        <SearchAndColumnToggle
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          contextMenuState={contextMenuState}
          setContextMenuState={setContextMenuState}
          table={table}
          page={'history'}
        />
        <DateRangePicker
          dateRange={dateRange}
          setDateRange={setDateRange}
        />
        <TableActionControls
          title={'Add History trade'}
          icon={<MdHistory className="w-5 h-5" />}
          newRow={newRow}
          handleSave={handleHistorySave}
          data={data}
          token={token}
          setData={setData}
          setNewRow={setNewRow}
          setSort={setSort}
          logout={logout}
          fetchData={fetchData}
          setColorsKeyMapState={setColorsKeyMapState}
          colorsKeyMapState={colorsKeyMapState}
          table={table}
        />

      </div>

      <div className='my-2 overflow-x-auto'>
        <div className='py-1 align-middle  inline-block '>
          <div className=' shadow  border-b border-gray-200  rounded-2xl'>
            <div className=' rounded-t-3xl '>
              <table
                id='tableContainer'
                style={{
                  width: table.getCenterTotalSize(),
                }}
                className=' divide-y  divide-gray-200 table rounded-2xl '
              >
                <TableHeader
                  headerGroups={table.getHeaderGroups()}
                  screenSize={screenSize} // This value should come from your state or context
                  calculateLeftOffset={calculateLeftOffset} // This function should be defined or imported
                  columnVisibility={columnVisibility} // This state should be managed in the parent component
                  newRow={newRow} // This state should be managed in the parent component
                  editableColumns={editableColumns} // This array should be defined or imported
                  currentColor={currentColor} // This value should come from your state or context
                />
                <HistoryTableBody
                  data={data}
                  page='history'
                  tradingCapitalData={capitalData}
                  setTradingCapitalData={setCapitalData}
                  table={table}
                  setFooterSums={setFooterSums}
                  screenSize={screenSize} // Pass the screenSize state
                  // getTradeStatus={getTradeStatus} // Pass the getTradeStatus function
                  newRow={newRow} // Pass the newRow state
                />
                <TableFooter
                  footerGroups={table.getFooterGroups()}
                  page='history'
                />
              </table>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default TradingHistory;