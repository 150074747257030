import {
  MdArrowDropDown,
  MdArrowDropUp,
  MdRemoveCircleOutline,
} from 'react-icons/md';
import {
  ColorCell,
  DateCell,
  DropdownCell,
  FinalPLCell,
  MarketCapCell,
  StatusCell,
  TickerCell,
  TickerInput,
} from '../utils/tableHandlers';
import { useMemo } from 'react';
import { useAuth } from '../context/auth';
import Tooltip from '../components/Tooltip';
import { formatNumber } from '../utils/mathHelpers';
import { HiQuestionMarkCircle } from 'react-icons/hi';
import { getBgColor, getTradeStatus, useBgColor } from '../utils/tableHelpers';
import { differenceInCalendarDays, startOfToday } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { ImArrowDownLeft, ImArrowUpLeft, ImArrowUpRight } from 'react-icons/im';

export const WatchListColumns = ({
  handleDelete,
  updateData,
  isDecayVisible,
  footerSums,
}) => {
  const { token } = useAuth();


  return useMemo(() => [
    {
      id: 'delete',
      accessorKey: 'delete',
      header: <h6 className='block w-full h-6'></h6>,
      cell: (info) => (
        <>
          {info.row.original._id && (
            <button
              className='px-2 py-1 w-full flex justify-center items-center'
              onClick={() => handleDelete(info.row)}
            >
              <MdRemoveCircleOutline className='w-5 h-5 fill-red-600' />
            </button>
          )}
        </>
      ),
      // width: 10,
      size: 50,
      maxSize: 100,
      minSize: 30,
      enableResizing: true,
      // disableFilters: true,
      // disableSortBy: true,
    },
    {
      id: 'WatchListName',
      header: (
        <h6 className='px-4 py-1 w-full text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
          Company Name
        </h6>
      ),
      accessorKey: 'WatchListName',
      cell: (info) => {
        return (
          <div className={`px-4 py-1 flex justify-between space-x-2 w-full`}>
            <h6
              className={` ${info.row.getValue('WatchListName') === 'Pending...'
                ? 'text-gray-500 italic'
                : info.row.getValue('WatchListName')
                  ? ''
                  : 'text-red-500 italic'
                } flex-1 text-left `}
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                maxWidth: '100%', // Ensure max-width is set to 100% to enforce truncation
              }}
            >
              {info.row.getValue('WatchListName')
                ? info.row.getValue('WatchListName')
                : info.row.original._id
                  ? 'Not found'
                  : ''}
            </h6>
            <Tooltip
              icon={
                <HiQuestionMarkCircle
                  className={`w-5 h-5 ${info.row.getValue('WatchListName') === 'Pending...'
                    ? 'fill-[#ffc954]'
                    : info.row.getValue('WatchListName')
                      ? 'fill-[#3e63decc]'
                      : info.row.original._id
                        ? 'fill-[#ff3737]'
                        : 'fill-[#949494]'
                    }`}
                />
              }
              content={
                info.row.getValue('WatchListName') === 'Pending...'
                  ? 'Pending...'
                  : !info.row.original._id
                    ? 'Please type a Ticker'
                    : info.row.getValue('WatchListName')
                      ? info.row.original.description
                      : 'Not found'
              }
              type='text'
              color={`${info.row.getValue('WatchListName') === 'Pending...'
                ? 'bg-[#ffc954]'
                : info.row.getValue('WatchListName')
                  ? 'bg-[#6582e5]'
                  : info.row.original._id
                    ? 'bg-red-600'
                    : 'bg-gray-400'
                }`}
              tooltipClass='max-w-md whitespace-normal break-words'
              tooltipStyle={{
                border: `3px solid  ${info.row.getValue('WatchListName') === 'Pending...'
                  ? '#ffc924'
                  : info.row.getValue('WatchListName')
                    ? '#3e64de'
                    : info.row.original._id
                      ? '#ff3737'
                      : '#949494'
                  }`,
                width: '300px',
                maxHeight: '200px',
                overflowY: 'auto',
                zIndex: '999',
              }}
            />
          </div>
        );
      },
      size: 150,
      maxSize: 250,
      minSize: 120,
    },
    {
      id: 'ticker',
      header: (
        <h6 id='tickerHeader' className='px-4 py-1 w-full sticky text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
          ticker
        </h6>
      ),
      accessorKey: 'ticker',
      cell: (info) => {
        return (
          <TickerCell
            updateData={updateData}
            row={info.row}
            columnId={'ticker'}
            page='watchList'
          />
        );
      },
      size: 100,
      maxSize: 150,
      minSize: 90,
    },
    {
      id: 'currentPrice',
      accessorKey: 'currentPrice',
      header: (
        <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
          Current Price
        </h6>
      ),
      cell: (info) => (
        <span className='w-full px-4 py-1 flex items-center justify-end text-end'>
          {info.getValue() && `$ ${formatNumber(info.getValue())}`}
        </span>
      ),
      size: 125,
      maxSize: 150,
      minSize: 100,
      Footer: 'Current Stock Price',
    },
    {
      id: 'prevPrice',
      accessorKey: 'prevPrice',
      header: (
        <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
          Change
        </h6>
      ),
      cell: (info) => {
        const currentPrice = info.row.getValue('currentPrice');
        const prevPrice = info.row.getValue('prevPrice');

        // Calculate the change in price
        const priceChange = currentPrice - prevPrice;
        const isPositive = priceChange > 0;
        const textStyle = isPositive ? { color: 'green' } : { color: 'red' };
        return (
          <span
            style={textStyle}
            className='w-full px-4 py-1 flex items-center justify-end text-end font-medium'
          >
            {priceChange
              ? priceChange !== 0
                ? `$ ${formatNumber(priceChange.toFixed(2))}`
                : '-'
              : ''}
          </span>
        );
      },
      size: 125,
      maxSize: 150,
      minSize: 100,
    },
    {
      id: 'Change%',
      accessorKey: 'Change%',
      header: (
        <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
          Change %
        </h6>
      ),
      cell: (info) => {
        const currentPrice = info.row.getValue('currentPrice');
        const prevPrice = info.row.original.prevPrice;

        // Calculate percentage change
        let changePercent = 0;
        if (prevPrice && currentPrice) {
          changePercent = ((currentPrice - prevPrice) / prevPrice) * 100;
        }

        // Determine the style and icon based on the change
        const isPositive = changePercent > 0;
        const textStyle = isPositive ? { color: 'green' } : { color: 'red' };
        const Icon = isPositive ? ImArrowUpRight : ImArrowDownLeft;

        return (
          <span
            style={textStyle}
            className='w-full px-4 py-1 flex items-center justify-end text-end font-semibold'
          >
            {`${formatNumber(changePercent.toFixed(4))} %`}
            {changePercent !== 0 && (
              <Icon className='font-normal ml-2 text-sm' />
            )}
          </span>
        );
      },
      size: 125,
      maxSize: 150,
      minSize: 100,
    },
    {
      id: 'sector',
      accessorKey: 'sector',
      header: (
        <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
          sector
        </h6>
      ),
      cell: (info) => (
        <span className='px-4 py-1 w-full text-start'>{info.getValue()}</span>
      ), // ====================> API
      Footer: 'Sector',
    },
    {
      id: 'industry',
      accessorKey: 'industry',
      header: (
        <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
          industry
        </h6>
      ),
      cell: (info) => (
        <span className='px-4 py-1 w-full text-start'>{info.getValue()}</span>
      ), // ====================> API
      Footer: 'Industry',
    },
    {
      id: 'marketCap',
      accessorKey: 'marketCap',
      header: (
        <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
          market Cap
        </h6>
      ),
      cell: (info) =>
        info.getValue() ? <MarketCapCell value={info.getValue()} /> : null, // ====================> API
      Footer: 'Market Cap',
    },
    {
      id: 'peRatio',
      accessorKey: 'peRatio',
      header: (
        <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
          P/e Ratio
        </h6>
      ),
      cell: (info) => (
        <span className='w-full px-4 py-1 flex items-center justify-end text-end'>
          {Math.floor(info.getValue()) || null}
        </span>
      ),
      size: 100,
      maxSize: 200,
      minSize: 50,
      Footer: 'P/E Ratio',
    },
    {
      id: 'employees',
      accessorKey: 'employees',
      header: (
        <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
          employees
        </h6>
      ),
      cell: (info) => {
        const value = info.getValue() || 0;
        const percentage = (value / 500000) * 100;
        return (
          <div className='px-4 py-1 relative h-full w-full rounded overflow-hidden table-cell'>
            <div className='relative h-6 bg-gray-400 flex rounded'>
              <div
                className='absolute left-0 h-6 bg-blue-500 rounded'
                style={{ width: `${percentage}%` }}
              ></div>
              <div className='absolute w-full inset-0 flex justify-center  items-center'>
                <span className='w-full pr-2 text-end text-white'>
                  {value.toLocaleString()}
                </span>
              </div>
            </div>
          </div>
        );
      }, // ====================> API
      Footer: 'Number of Employees',
    },
    {
      id: 'averageVolume',
      accessorKey: 'averageVolume',
      header: (
        <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
          average Volume
        </h6>
      ),
      cell: (info) => {
        const value = info.getValue() || 0;
        const percentage = (value / 100000000) * 100;
        return (
          <div className='px-4 py-1 relative h-full w-full rounded overflow-hidden table-cell'>
            <div className='relative h-6 bg-gray-400 flex rounded'>
              <div
                className='absolute left-0 h-6 bg-blue-500 rounded'
                style={{ width: `${percentage}%` }}
              ></div>
              <div className='absolute w-full inset-0 flex justify-center  items-center'>
                <span className='w-full pr-2 text-end text-white '>
                  {value.toLocaleString()}
                </span>
              </div>
            </div>
          </div>
        );
      }, // ====================> API
      Footer: 'Average Volume',
    },
  ]);
};
