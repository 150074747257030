// components/ConfirmationModal.js
import React from 'react';
import { MdOutlineCancel } from 'react-icons/md';

const ConfirmationModal = ({ isOpen, title, message, onConfirm, onCancel }) => {
  if (!isOpen) return null;

  return (
    <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50'>
      <div className='relative bg-white rounded-lg w-full max-w-md p-6'>
        <button
          className='absolute top-2 right-2 text-gray-400 hover:text-red-600'
          onClick={onCancel}
        >
          <MdOutlineCancel className='w-5 h-5' />
        </button>
        <h2 className='text-2xl font-semibold mb-4'>{title}</h2>
        <p className='text-base text-gray-700'>{message}</p>
        <div className='flex justify-center mt-6'>
          <button
            className='bg-red-500 text-white px-4 py-2 rounded mr-2 hover:bg-red-600'
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className='bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600'
            onClick={onConfirm}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
