import {
  useReactTable,
  flexRender,
  getCoreRowModel,
} from '@tanstack/react-table';
import DashboardHeader from './Dashboard/DashboardHeader';
import { useMemo } from 'react';

const ROITable = ({ setDateRange, setData, data, year }) => {
  // State for selected year

  const columns = useMemo(
    () => [
      {
        accessorKey: 'month', // accessor is the "key" in the data
        header: 'Month',
      },
      {
        accessorKey: 'capital',
        header: 'capital',
      },
      {
        accessorKey: 'pnl',
        header: 'P&L',
      },
      {
        accessorKey: 'pnlCum',
        header: 'P&L Cum',
      },
      {
        accessorKey: 'roi',
        header: 'ROI',
      },
      {
        accessorKey: 'roiCum',
        header: 'ROI Cum',
      },
      {
        accessorKey: 'trades',
        header: '# Trades',
      },
      {
        accessorKey: 'tradesCum',
        header: '# Trades Cum',
      },
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div id='plSummaryTable' className='p-4'>
      <DashboardHeader title={`P&L summary for (${year ? year : 'YTD'})`} />

      <table className='min-w-full'>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className='px-6 py-3 bg-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className='even:bg-gray-100'>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className='px-6 py-2 whitespace-nowrap text-right first:text-left text-sm text-gray-700'
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ROITable;
