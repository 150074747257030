import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/auth';
import { useStateCtx } from '../context/appContext';

const UserNavDropDown = () => {
  const { logout } = useAuth();
  const { setIsClicked } = useStateCtx();
  const dropdownRef = useRef(); // Create a reference to the dropdown

  const handleClick = () => {
    setIsClicked({
      userProfile: false,
      notification: false,
    });
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu, then close the menu
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        handleClick();
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    // Cleanup
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return (
    <div
      className='absolute right-0 mt-2 w-80 rounded-md shadow-lg bg-light-background dark:bg-dark-background-90 ring-1 ring-black ring-opacity-5 overflow-hidden'
      role='menu'
      ref={dropdownRef}
      aria-orientation='vertical'
      aria-labelledby='user-menu'
    >
      <div className='' role='none'>
        {/* <Link
          to={`/profile`}
          href='#'
          className='block px-4 py-2 text-sm text-light-text dark:text-dark-text-light dark:hover:text-dark-text hover:bg-gray-200 hover:dark:bg-dark-background hover:text-gray-900'
          role='menuitem'
          onClick={handleClick}
        >
          Profile
        </Link> */}
        {/* <Link
          href='#'
          className='block px-4 py-2 text-sm text-light-text dark:text-dark-text-light dark:hover:text-dark-text hover:bg-gray-200 hover:dark:bg-dark-background hover:text-gray-900'
          role='menuitem'
        >
          Settings
        </Link> */}
        <button
          type='button'
          className='block px-4 py-2 text-sm w-full text-left text-light-text dark:text-dark-text-light dark:hover:text-dark-text hover:bg-gray-200 hover:dark:bg-dark-background hover:text-gray-900'
          role='menuitem'
          onClick={() => {
            logout();
            handleClick();
          }}
        >
          Sign out
        </button>
      </div>
    </div>
  );
};

export default UserNavDropDown;
