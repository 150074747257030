// services/userService.js
import axios from 'axios';


export const fetchVisitedPagesService = async (token) => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/get-visited-pages`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const updateVisitedPagesService = async (pageName, token) => {
  await axios.post(
    `${process.env.REACT_APP_BASE_URL}/check-first-visit`,
    { pageName },
    { headers: { Authorization: `Bearer ${token}` } }
  );
};
