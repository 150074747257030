import { createContext, useContext, useEffect, useState } from 'react';

const StateCtx = createContext();

const initialState = {
  userProfile: false,
  notification: false,
};

export const ContextProvider = ({ children }) => {
  const [activeMenu, setActiveMenu] = useState(false);
  const [isClicked, setIsClicked] = useState(initialState);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [currentColor, setCurrentColor] = useState();
  const [currentMode, setCurrentMode] = useState();
  const [themeSettings, setThemeSettings] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight); // new state

  useEffect(() => {
    const localTheme = localStorage.getItem('themeMode');
    const localColor = localStorage.getItem('themeColor');
    if (localTheme) {
      setCurrentMode(localTheme);
    } else {
      setCurrentMode('Light');
    }
    if (localColor) {
      setCurrentColor(localColor);
    } else {
      setCurrentColor('#3e64de');
    }
  }, []);

  const setMode = (e) => {
    setCurrentMode(e.target.value);
    localStorage.setItem('themeMode', e.target.value);
    setThemeSettings(false);
  };

  const setColor = (color) => {
    setCurrentColor(color);
    localStorage.setItem('themeColor', color);
    setThemeSettings(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
      setWindowHeight(window.innerHeight); // update windowHeight state
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [screenSize]);

  useEffect(() => {
    if (screenSize && screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize]);

  const handleClick = (clicked) => {
    setIsClicked((prevState) => {
      return { ...initialState, [clicked]: !prevState[clicked] };
    });
  };

  return (
    <StateCtx.Provider
      value={{
        activeMenu,
        setActiveMenu,
        isClicked,
        setIsClicked,
        handleClick,
        screenSize,
        setScreenSize,
        currentColor,
        currentMode,
        setMode,
        setColor,
        themeSettings,
        setThemeSettings,
        windowHeight, // expose windowHeight
      }}
    >
      {children}
    </StateCtx.Provider>
  );
};

export const useStateCtx = () => useContext(StateCtx);
