export const LOGIN = '/login';
export const DASHBOARD = '/';
export const PORTFOLIO = '/portfolio';
export const WATCH_LIST = '/watch-list';
export const WHEEL_INCOME = '/wheel-income';
export const BREAKEVEN = '/breakeven';
export const ASSIGNED_STOCK = '/assigned-stock';
export const NOTFOUND = '/notFound';
export const TRADING_HISTORY = '/trading-history';
export const ABOUT_US = '/about-us';
