import React, { useState, useEffect, useRef } from 'react';
import { FaCircle } from 'react-icons/fa';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const ToolBar = ({
  handleDecay,
  selectedDecay,
  setStatusFilter,
  statusOptions,
  columns,
}) => {
  const [isDecayDropdownOpen, setIsDecayDropdownOpen] = useState(false);
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('all');
  const wrapperRef = useRef(null);

  const toggleDropdown = (type) => {
    if (type === 'decay') setIsDecayDropdownOpen(!isDecayDropdownOpen);
    else setIsStatusDropdownOpen(!isStatusDropdownOpen);
  };

  const handleOutsideClick = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsDecayDropdownOpen(false);
      setIsStatusDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);
  let color;
  switch (selectedStatus) {
    case 'active':
      color = '#4CAF50';
      break;
    case 'closed':
      color = '#808080';
      break;
    case 'pending':
      color = '#FFEB3B';
      break;
    default:
      color = 'black';
  }
  
  return (
    <div
      ref={wrapperRef}
      className='flex flex-col lg:flex-row justify-between items-center px-4 py-2 lg:gap-20'
      
    >
      {/* Decay Levels */}
      <div id='decayToggle' className='relative flex flex-col space-y-2 md:space-y-0 md:flex-row md:space-x-4 items-center'>
        <button
          className='flex items-center text-black font-semibold'
          onClick={() => toggleDropdown('decay')}
        >
          Decay Levels
          {isDecayDropdownOpen ? (
            <IoIosArrowUp className='w-5 h-5 ml-2' />
          ) : (
            <IoIosArrowDown className='w-5 h-5 ml-2' />
          )}
        </button>
        {isDecayDropdownOpen && (
          <div className='absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white z-50'>
            <div className='py-1'>
              {['60', '70', '80', '90', '100'].map((decay) => (
                <label
                  key={decay}
                  className='block cursor-pointer hover:bg-gray-100 p-2'
                >
                  <input
                    type='checkbox'
                    className='form-checkbox'
                    checked={selectedDecay.includes(decay)}
                    onChange={() => handleDecay(decay, columns)}
                  />
                  <span className='ml-2'>{decay}</span>
                </label>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Status Filters */}
      <div id='tradesFilter' className='relative flex items-center mt-4 md:mt-0 text-black font-semibold '>
        Show
        <button
          className='flex items-center   mx-2  '
          onClick={() => toggleDropdown('status')}
        >
          <FaCircle className='w-3 h-3 mr-1' style={{ color: color }} />
          <span className={`first-letter:uppercase `}>{selectedStatus}</span>
          {isStatusDropdownOpen ? (
            <IoIosArrowUp className='w-5 h-5 ml-1' />
          ) : (
            <IoIosArrowDown className='w-5 h-5 ml-1' />
          )}
        </button>
        {isStatusDropdownOpen && (
          <div className='absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white z-50 '>
            {statusOptions?.map((status, index) => (
              <div
                key={index}
                onClick={() => {
                  setSelectedStatus(status);
                  setStatusFilter(status);
                  toggleDropdown('status');
                }}
                className='block cursor-pointer hover:bg-gray-100 p-2 first-letter:uppercase '
              >
                {status}
              </div>
            ))}
          </div>
        )}
        Trades
      </div>
    </div>
  );
};

export default ToolBar;
