import React from 'react';
import { BiFilter, BiSortDown, BiSortUp } from 'react-icons/bi';

const DashboardHeader = ({
  title,
  sortOptions,
  sortField,
  sortAscending,
  handleSortFieldChange,
  toggleSortOrder,
}) => (
  <div className='flex justify-between items-center pb-2'>
    <div className='flex-grow'>
      <h2 className='text-lg font-bold text-gray-700 py-2 uppercase'>
        {title}
      </h2>
    </div>
    {sortOptions && handleSortFieldChange && toggleSortOrder && (
      <div className='flex items-center space-x-2'>
        <BiFilter className='text-xl cursor-pointer' />
        <select
          onChange={(e) => handleSortFieldChange(e.target.value)}
          className='cursor-pointer bg-transparent border-none text-md'
        >
          {sortOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {title === 'Active Trades' || title === 'Trades by Market Industry'  ? (
          ''
        ) : (
          <button
            onClick={toggleSortOrder}
            className='text-gray-700 hover:text-gray-900 transition-colors duration-300'
          >
            {sortAscending ? (
              <BiSortUp className='text-xl' />
            ) : (
              <BiSortDown className='text-xl' />
            )}
          </button>
        )}
      </div>
    )}
  </div>
);

export default DashboardHeader;
