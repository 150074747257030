import ToolBar from "../ToolBar";


const FilterControls = ({ handleDecay, selectedDecay, columns, statusOptions, setStatusFilter }) => {
  return (
    <div className="flex items-center space-x-4 relative">
      <ToolBar
        handleDecay={handleDecay}
        selectedDecay={selectedDecay}
        columns={columns}
        setStatusFilter={setStatusFilter}
        statusOptions={statusOptions}
      />
    </div>
  );
};

export default FilterControls;
