import axios from 'axios';
import { toast } from 'react-toastify';

export const fetchTrades = async (token) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/trades`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data?.trades;
  } catch (error) {
    toast.error('Error fetching trades');
    throw error;
  }
};

export const fetchTradeById = async (id, token) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/trade/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.trade;
  } catch (error) {
    toast.error('Error fetching trade');
    throw error;
  }
};

export const createTrade = async (trade, token) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/trade`,
      trade,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    toast.success('Trade created successfully');
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(`${error.response.data.message}`);
    throw error;
  }
};

export const updateTradeCellByRowId = async ({
  row,
  columnId,
  newValue,
  token,
}) => {
  try {
    const rowId = row.original._id;
    const payload = { columnId, value: newValue };
    // Make an HTTP request to update the specific cell within the trade identified by rowId
    const response = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/${
        row.original.isManualEntry ? 'manualTrade' : 'trade'
      }/${rowId}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    toast.success('Cell updated successfully');
    return response.data.trade;
  } catch (error) {
    toast.error('Error updating cell');
    throw error;
  }
};

export const deleteTradeById = async (id, token) => {
  try {
    await axios.delete(`${process.env.REACT_APP_BASE_URL}/trade/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    toast.success('Trade deleted successfully');
  } catch (error) {
    toast.error('Error deleting trade');
    throw error;
  }
};

export const deleteAllTrades = async () => {
  try {
    await axios.delete(`${process.env.REACT_APP_BASE_URL}/trades`);
    toast.success('All trades deleted successfully');
  } catch (error) {
    toast.error('Error deleting all trades');
    throw error;
  }
};

export const getBuyingPower = async (token) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/buyingPower`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { globalBuyingPower, wheelBuyingPower } = response.data;

    return { globalBuyingPower, wheelBuyingPower };
  } catch (error) {
    toast.error('Error fetching buying power');
    console.error('Error fetching buying power:', error);
    throw error;
  }
};

// Update the buyingPower
// export const updateBuyingPower = async (buyingPower, token) => {
//   try {
//     const response = await axios.put(
//       `${process.env.REACT_APP_BASE_URL}/buyingPower`,
//       { buyingPower },
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );
//     toast.success('Buying power updated successfully');
//     return response.data;
//   } catch (error) {
//     toast.error('Error updating buying power');
//     console.error('Error updating buying power:', error);
//     throw error;
//   }
// };
export const updateBuyingPower = async (type, value, token) => {
  try {
    // Specify the type of buying power to update with its new value
    const payload = type === 'global' ? { globalBuyingPower: value } : { wheelBuyingPower: value };

    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/buyingPower`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    toast.success('Buying power updated successfully');
    return response.data;
  } catch (error) {
    toast.error('Error updating buying power');
    console.error('Error updating buying power:', error);
    throw error;
  }
};
