import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import LoadingPage from '../pages/LoadingPage';
import { endOfYear, format, startOfYear } from 'date-fns';
import {
  fetchVisitedPagesService,
  updateVisitedPagesService,
} from '../services/guidedTutorial';

const AuthContext = createContext({
  isLoggedIn: false,
  loginHandler: () => {},
  logout: () => {},
  user: null,
  setUser: () => {},
  token: null,
  dateRange: null, // Add a state to store the date range
  setDateRange: () => {}, // Function to update the date range
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(
    JSON.parse(localStorage.getItem('user'))?.token || null
  );
  const [dateRange, setDateRange] = useState(() => {
    // Check if there's a stored dateRange in localStorage
    const storedDateRange = localStorage.getItem('dateRange');
    if (storedDateRange && JSON.parse(storedDateRange) !== null) {
      // If stored dateRange exists, parse it
      const parsedRange = JSON.parse(storedDateRange);
      return {
        startDate: parsedRange.startDate,
        endDate: parsedRange.endDate,
      };
    } else {
      // Otherwise, default to start and end of the current year, formatted as yyyy-mm-dd
      const currentYear = new Date();
      return {
        startDate: format(startOfYear(currentYear), 'yyyy-MM-dd'),
        endDate: format(endOfYear(currentYear), 'yyyy-MM-dd'),
      };
    }
  });
  // Add new state for tracking expanded/collapsed groups
  const [expanded, setExpanded] = useState(() => {
    // Attempt to retrieve the state from localStorage or default to an empty object
    const storedExpandedGroups = localStorage.getItem('expanded');
    return storedExpandedGroups ? JSON.parse(storedExpandedGroups) : {};
  });
  // AuthProvider component inside AuthContext file

  const [visitedPages, setVisitedPages] = useState({});

  const [visitedPagesLoading, setVisitedPagesLoading] = useState(false);

  const fetchVisitedPages = async () => {
    if (token) {
      setVisitedPagesLoading(true);
      try {
        const response = await fetchVisitedPagesService(token);
        setVisitedPages(response.visitedPages);
        localStorage.setItem('visitedPages', JSON.stringify(response.visitedPages));
      } catch (error) {
        console.error('Error fetching visited pages:', error);
      } finally {
        setVisitedPagesLoading(false);
      }
    }
  };

  // Function to mark a page as visited
  const markPageAsVisited = async (pageName) => {
    const updatedVisitedPages = { ...visitedPages, [pageName]: true };
    setVisitedPages(updatedVisitedPages);
    localStorage.setItem('visitedPages', JSON.stringify(updatedVisitedPages));

    // Update the backend asynchronously
    try {
      await updateVisitedPagesService(pageName, token);
    } catch (error) {
      console.error('Error updating visited pages:', error);
    }
  };

  useEffect(() => {
    fetchVisitedPages();
  }, [token]); // Fetch visited pages when the token is set

  const toggleAllMonthGroups = useCallback(() => {
    setExpanded((prevExpanded) => {
      // Determine if we are expanding or collapsing all groups
      // We expand all if not all groups are expanded (some might be missing or explicitly set to false)
      const shouldExpand =
        Object.keys(prevExpanded).length < 12 ||
        Object.values(prevExpanded).some((value) => !value);

      const newState = {};
      for (let i = 0; i < 12; i++) {
        newState[i.toString()] = shouldExpand;
      }

      localStorage.setItem('expanded', JSON.stringify(newState));
      return newState;
    });
  }, []);

  // Handler to reset the expanded groups state
  const resetExpandedGroups = useCallback(() => {
    setExpanded({});
    localStorage.removeItem('expanded'); // Clear from localStorage
  }, []);

  useEffect(() => {
    // Reset expanded when the date range changes
    resetExpandedGroups();
  }, [dateRange, resetExpandedGroups]);

  useEffect(() => {
    // Update localStorage whenever expanded changes
    localStorage.setItem('expanded', JSON.stringify(expanded));
  }, [expanded]);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setCurrentUser(JSON.parse(storedUser));
      setToken(JSON.parse(storedUser).token);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    // Format the dateRange as yyyy-mm-dd before saving
    const formattedDateRange = {
      startDate: format(new Date(dateRange.startDate), 'yyyy-MM-dd'),
      endDate: format(new Date(dateRange.endDate), 'yyyy-MM-dd'),
    };

    // Persist the formatted date range in localStorage
    localStorage.setItem('dateRange', JSON.stringify(formattedDateRange));
  }, [dateRange]);
  const logoutHandler = useCallback(() => {
    localStorage.removeItem('user');
    localStorage.removeItem('dateRange');
    setCurrentUser(null);
    setToken(null);
    resetExpandedGroups(); // Reset expanded groups upon logout

    // Reset dateRange to default values instead of null
    const currentYear = new Date();
    setDateRange({
      startDate: format(startOfYear(currentYear), 'yyyy-MM-dd'),
      endDate: format(endOfYear(currentYear), 'yyyy-MM-dd'),
    });
    toast.success('Logged out successfully!');
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  const loginHandler = useCallback(async ({ username, password }) => {
    const handleOrderStatus = (status) => {};

    const errorHandler = (error) => {};

    try {
      const response = await axios.post(
        'https://wheeloptionstraders.com/wp-json/jwt-auth/v1/token',
        {
          username,
          password,
        }
      );

      if (response.status === 200 && response.data.token) {
        const user = {
          token: response.data.token,
          user_display_name: response.data.user_display_name,
          user_email: response.data.user_email,
        };

        // Handle the order status
        const orderStatusError = handleOrderStatus(response.data.order_status);
        if (orderStatusError) {
          setError({ message: orderStatusError });
          return;
        }

        localStorage.setItem('user', JSON.stringify(user));
        setCurrentUser(user);
        setToken(response.data.token);

        toast.success('Login successful!');
      } else {
        setError({ message: 'Invalid credentials' });
      }
    } catch (error) {
      setError({ message: errorHandler(error) });
    }
  }, []);

  const value = useMemo(
    () => ({
      isLoggedIn: Boolean(currentUser),
      loginHandler,
      logout: logoutHandler,
      user: currentUser,
      setUser: setCurrentUser,
      token,
      dateRange, // Add dateRange to the context value
      setDateRange, // Add setDateRange to the context value
      expanded, // Add expanded to the context value
      setExpanded,
      toggleAllMonthGroups, // Function to toggle a group's expansion
      resetExpandedGroups, // Function to reset all groups' expansion states
      visitedPages,
      markPageAsVisited,visitedPagesLoading
    }),
    [
      currentUser,
      loginHandler,
      logoutHandler,
      token,
      dateRange,
      expanded,
      setExpanded,
      toggleAllMonthGroups,
      resetExpandedGroups,
      visitedPages,
      markPageAsVisited,visitedPagesLoading
    ]
  );

  return (
    <AuthContext.Provider value={value}>
      {loading ? <LoadingPage /> : children}
    </AuthContext.Provider>
  );
};
