import { MdRemoveCircleOutline } from 'react-icons/md';
import {
  DateCell,
  DropdownCell,
  FinalPLCell,
  TickerCell,
} from '../utils/tableHandlers';
import { useMemo } from 'react';
import { useAuth } from '../context/auth';

export const TradingHistoryColumns = ({
  handleDelete,
  footerSums,
  updateData,
  capitalData,
}) => {
  const { token } = useAuth();
  return useMemo(
    () => [
      {
        id: 'TradeDetails',
        header: (
          <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
            Trade Details
          </h6>
        ),
        columns: [
          {
            id: 'delete',
            accessorKey: 'delete',
            header: <h6 className='block w-full h-6'></h6>,
            cell: (info) => (
              <>
                {info.row.original._id && info.row.parentId && (
                  <button
                    className='px-2 py-1 w-full flex justify-center items-center'
                    onClick={() => handleDelete(info.row)}
                  >
                    <MdRemoveCircleOutline className='w-5 h-5 fill-red-600' />
                  </button>
                )}
              </>
            ),
            // width: 10,
            size: 50,
            maxSize: 100,
            minSize: 30,
            enableResizing: true,
            footer: (
              <div className='  flex flex-col  justify-start items-start  w-full'>
                <div className='bg-blue-200 px-4 py-1 w-full flex justify-end items-center text-end h-8 font-medium '>
                  {` `}
                </div>
              </div>
            ),
            // disableFilters: true,
            // disableSortBy: true,
          },
          {
            id: 'closingDate',
            accessorKey: 'closingDate',
            header: (
              <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
                Closing Date
              </h6>
            ),
            cell: (info) => {
              return (
                <DateCell
                  updateData={updateData}
                  row={info.row}
                  columnId={'closingDate'}
                  page='history'
                />
              );
            },
            size: 150,
            maxSize: 200,
            minSize: 130,
            footer: (
              <div className='  flex flex-col justify-start items-start '>
                <h2 className='bg-blue-200 pl-4 text font-bold py-2 '>
                  FINAL P&L
                </h2>
              </div>
            ),
          },
          {
            id: 'ticker',
            accessorKey: 'ticker',
            header: (
              <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
                Ticker
              </h6>
            ),
            cell: (info) => {
              return (
                <TickerCell
                  updateData={updateData}
                  row={info.row}
                  columnId={'ticker'}
                  page='history'
                  token={token}
                />
              );
            },
            size: 140,
            maxSize: 170,
            minSize: 120,
            footer: (
              <div className='  flex flex-col  justify-start items-start  w-full'>
                <div className='bg-blue-200 px-4 py-1 w-full flex justify-end items-center text-end h-8 font-medium '>
                  {` `}
                </div>
              </div>
            ),
          },
          {
            id: 'expirationDate',
            accessorKey: 'expirationDate',
            header: (
              <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
                Exp Date
              </h6>
            ),
            cell: (info) => (
              <DateCell
                updateData={updateData}
                row={info.row}
                columnId={'expirationDate'}
                page='history'
              />
            ),
            size: 150,
            maxSize: 200,
            minSize: 130,
            footer: (
              <div className='  flex flex-col  justify-start items-start  w-full'>
                <div className='bg-blue-200 px-4 py-1 w-full flex justify-end items-center text-end h-8 font-medium '>
                  {` `}
                </div>
              </div>
            ),
          },
          {
            id: 'strike',
            accessorKey: 'strike',
            header: (
              <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
                Strike/Price
              </h6>
            ),
            // Add cell rendering logic here
            size: 120,
            maxSize: 150,
            minSize: 100,
            footer: (
              <div className='  flex flex-col  justify-start items-start  w-full'>
                <div className='bg-blue-200 px-4 py-1 w-full flex justify-end items-center text-end h-8 font-medium '>
                  {` `}
                </div>
              </div>
            ),
          },
          {
            id: 'putCall',
            accessorKey: 'putCall',
            header: (
              <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
                Security
              </h6>
            ),
            cell: (info) => (
              <DropdownCell
                row={info.row}
                options={[
                  { value: 'put', label: 'Put' },
                  { value: 'call', label: 'Call' },
                  { value: 'stock', label: 'Stock' }, // this should only be possible if a new trade is created
                ]}
                updateData={updateData}
                columnId={'putCall'}
                page='history'
              />
            ),
            size: 130,
            maxSize: 150,
            minSize: 100,
            footer: (
              <div className='  flex flex-col  justify-start items-start  w-full'>
                <div className='bg-blue-200 px-4 py-1 w-full flex justify-end items-center text-end h-8 font-medium '>
                  {` `}
                </div>
              </div>
            ),
          },
          {
            id: 'closingTradeType',
            accessorKey: 'closingTradeType',
            header: (
              <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
                Trade
              </h6>
            ),
            cell: (info) => {
              const options =
                info.row.getValue('putCall') === 'put'
                  ? [
                    { value: 'BTC', label: 'BTC' },
                    { value: 'BTC(rolled)', label: 'BTC (rolled)' },
                    { value: 'EXP', label: 'EXP' },
                    { value: 'EXP(assigned)', label: 'EXP (assigned)' },
                    { value: 'soldStock', label: 'Sold STOCK' },
                  ]
                  : [
                    { value: 'BTC', label: 'BTC' },
                    { value: 'BTC(rolled)', label: 'BTC (rolled)' },
                    { value: 'EXP', label: 'EXP' },
                    { value: 'EXP(exercised)', label: 'EXP (exercised)' },
                    { value: 'soldStock', label: 'Sold STOCK' },
                  ];
              return (
                <DropdownCell
                  row={info.row}
                  options={options}
                  updateData={updateData}
                  columnId={'closingTradeType'}
                  page='history'
                />
              );
            },
            size: 180,
            maxSize: 250,
            minSize: 100,
            footer: (
              <div className='  flex flex-col  justify-start items-start  w-full'>
                <div className='bg-blue-200 px-4 py-1 w-full flex justify-end items-center text-end h-8 font-medium '>
                  {` `}
                </div>
              </div>
            ),
          },
          {
            id: 'numberOfOptions',
            accessorKey: 'numberOfOptions',
            header: (
              <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
                QTY
              </h6>
            ),
            // Add cell rendering logic here
            size: 120,
            maxSize: 150,
            minSize: 100,
            footer: (
              <div className='  flex flex-col justify-start items-start '>
                <div className=' bg-blue-200 px-4 py-1 w-full flex justify-end items-center text-end h-8 font-medium '>
                  {` `}
                </div>
              </div>
            ),
          },
          {
            id: 'finalPL',
            accessorKey: 'finalPL',
            header: (
              <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
                Profit/Loss
              </h6>
            ),
            cell: (info) => (
              <FinalPLCell
                updateData={updateData}
                row={info.row}
                columnId={'finalPL'}
                page='history'
              />
            ),
            size: 150,
            maxSize: 200,
            minSize: 150,
            footer: (
              <div className='  flex flex-col justify-start items-start '>
                <h2 className='bg-blue-200 px-4 py-1  w-full text-end font-bold px-auto'>
                  {`$ ${footerSums.totalPL}`}
                </h2>
              </div>
            ),
          },
          // {
          //   id: 'ManualTrade',
          //   accessorKey: 'ManualTrade',
          //   header: (
          //     <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
          //       Source
          //     </h6>
          //   ),
          //   cell: (info) =>
          //     info.row.original.isManualEntry ? 'manually' : 'portfolio',
          //   size: 180,
          //   maxSize: 250,
          //   minSize: 100,
          //   footer: (
          //     <div className='  flex flex-col  justify-start items-start  w-full'>
          //       <div className='bg-blue-200 px-4 py-1 w-full flex justify-end items-center text-end h-8 font-medium '>
          //         {` `}
          //       </div>
          //       <div className='bg-white px-4 py-1 w-full flex justify-end items-center text-end h-8 font-medium '>
          //         {` `}
          //       </div>
          //     </div>
          //   ),
          // },
        ],
      },
    ],
    [footerSums]
  );
};
