import {
  MdArrowDropDown,
  MdArrowDropUp,
  MdRemoveCircleOutline,
} from 'react-icons/md';
import {
  ColorCell,
  DateCell,
  DropdownCell,
  FinalPLCell,
  MarketCapCell,
  NumberCell,
  StatusCell,
  TickerCell,
  TickerInput,
} from '../utils/tableHandlers';
import { useMemo } from 'react';
import { useAuth } from '../context/auth';
import Tooltip from '../components/Tooltip';
import { formatNumber } from '../utils/mathHelpers';
import { HiQuestionMarkCircle } from 'react-icons/hi';
import { getTradeStatus } from '../utils/tableHelpers';
import {
  differenceInCalendarDays,
  format,
  parseISO,
} from 'date-fns';
import { getBgColor } from '../utils/stylingHelpers';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { BiPencil } from 'react-icons/bi';

export const AssignedStockColumns = ({
  handleDelete,
  updateData,
  isDecayVisible,
  footerSums,
}) => {
  const { token } = useAuth();

  return useMemo(() => [
    {
      id: 'StockOwned',
      accessorKey: 'StockOwned',
      header: (
        <h6
          className={`px-4 py-1 w-full text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider  ${getBgColor(
            'finalPL'
          )}`}
        >
          Stock you currently own after PUT assignment
        </h6>
      ),

      columns: [
        {
          id: 'name',
          header: (
            <h6 className='px-4 py-1 w-full text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
              Company Name
            </h6>
          ),
          accessorKey: 'name',
          cell: (info) => {
            return (
              <span className='w-full px-4 py-1 flex items-center justify-start text-start'>
                {info.row.original.companyId.name}
              </span>
            );
          },
          size: 150,
          maxSize: 250,
          minSize: 120,
        },
        {
          id: 'ticker',
          header: (
            <h6
              id='tickerHeader'
              className='px-4 py-1 w-full sticky text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '
            >
              ticker
            </h6>
          ),
          accessorKey: 'ticker',
          cell: (info) => {
            return (
              <span
                className='w-full px-4 py-1 flex items-center justify-center
                uppercase'
              >
                {info.getValue()}
              </span>
            );
          },
          size: 100,
          maxSize: 150,
          minSize: 90,
        },
        {
          id: 'stockAssignedDate',
          accessorKey: 'stockAssignedDate',
          header: (
            <h6
              className={` px-4 py-1 text-center whitespace-nowrap text-ellipsis w-full  text-xs font-medium text-gray-500 uppercase tracking-wider`}
            >
              stock Assigned Date
            </h6>
          ),
          cell: (info) => {
            const dateValue = info.row.original.closingDate;

            // Check if the dateValue exists and is not null
            if (dateValue) {
              // Parse the ISO string to a Date object
              const date = parseISO(dateValue);
              // Format the date to the desired format
              const formattedDate = format(date, "d MMM ''yy");

              return (
                <span className='w-full px-4 py-1 flex items-center justify-center'>
                  {formattedDate}
                  <FaRegCalendarAlt className='ml-2' />
                </span>
              );
            } else {
              // Return placeholder text when date is null
              return (
                <span className='w-full px-4 py-1 flex items-center justify-center text-gray-400'>
                  DD-MM-YY
                  <FaRegCalendarAlt className='ml-2' />
                </span>
              );
            }
          },
          size: 130,
          maxSize: 200,
          minSize: 130,
        },
        {
          id: 'shares',
          accessorKey: 'shares',
          header: (
            <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
              Qty
            </h6>
          ),
          cell: (info) => (
            <span className='w-full px-4 py-1 flex items-center justify-center'>
              {info.row.original.numberOfOptions &&
                `${info.row.original.numberOfOptions * 100}`}
            </span>
          ),
          size: 125,
          maxSize: 150,
          minSize: 100,
        },
        {
          id: 'currentPrice',
          accessorKey: 'currentPrice',
          header: (
            <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
              Current Price
            </h6>
          ),
          cell: (info) => (
            <span className='w-full px-4 py-1 flex items-center justify-end text-end'>
              {info.getValue() && `$ ${formatNumber(info.getValue())}`}
            </span>
          ),
          size: 125,
          maxSize: 150,
          minSize: 100,
          Footer: 'Current Stock Price',
        },
        {
          id: 'marketValue',
          accessorKey: 'marketValue',
          header: (
            <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
              Market Value
            </h6>
          ),
          cell: (info) => (
            <span className='w-full px-4 py-1 flex items-center justify-end text-end'>
              {info.getValue() && `$ ${formatNumber(info.getValue(), 0)}`}
            </span>
          ),
          footer: (info) => (
            <span className='w-full px-4 py-1 flex items-center whitespace-nowrap justify-end text-end'>
              {`$ ${formatNumber(footerSums.marketValue, 0)}`}
            </span>
          ),
          size: 125,
          maxSize: 150,
          minSize: 100,
        },
        {
          id: 'strike',
          accessorKey: 'strike',
          header: (
            <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
              Assigned price
            </h6>
          ),
          cell: (info) => (
            <span className='w-full px-4 py-1 flex items-center justify-end text-end'>
              {info.getValue() && `$ ${formatNumber(info.getValue())}`}
            </span>
          ),
          size: 125,
          maxSize: 150,
          minSize: 100,
        },

        {
          id: 'assignedValue',
          accessorKey: 'assignedValue',
          header: (
            <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
              Assigned Value
            </h6>
          ),
          cell: (info) => {
            return (
              <span className='w-full px-4 py-1 flex items-center justify-end text-end'>
                {info.getValue() && `$ ${formatNumber(info.getValue(), 0)}`}
              </span>
            );
          },
          footer: (info) => (
            <span className='w-full px-4 py-1 flex items-center justify-end whitespace-nowrap text-end'>
              {`$ ${formatNumber(footerSums.assignedValue, 0)}`}
            </span>
          ),
          size: 125,
          maxSize: 150,
          minSize: 100,
        },
        {
          id: 'currentPL',
          accessorKey: 'currentPL',
          header: (
            <h6
              className={`px-4 py-1 w-full text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider  ${getBgColor(
                'finalPL'
              )}`}
            >
              current P&L
            </h6>
          ),
          cell: (info) => {
            const value = info.getValue();
            let formattedValue = value && `${formatNumber(Math.abs(value), 0)}`;

            if (value < 0) {
              // For negative values, display in red and within parentheses
              return (
                <span className='w-full px-4 py-1 flex items-center justify-end text-end text-red-500 font-bold'>
                  {value && `$ (${formattedValue})`}
                </span>
              );
            } else {
              // For positive values, display in green and bold
              return (
                <span className='w-full px-4 py-1 flex items-center justify-end text-end text-green-500 font-bold'>
                  {`$ ${formattedValue}`}
                </span>
              );
            }
          },

          footer: (info) => {
            const isProfitable = footerSums.currentPL >= 0;
            const formattedValue = isProfitable
              ? `$ ${formatNumber(footerSums.currentPL, 0)}`
              : `$ (${formatNumber(-footerSums.currentPL, 0)})`;
            return (
              <span
                className={`w-full px-4  py-1 ${isProfitable ? '' : 'text-red-600'
                  } flex items-center justify-end whitespace-nowrap text-end`}
              >
                {formattedValue}
              </span>
            );
          },
          size: 125,
          maxSize: 150,
          minSize: 100,
        },
      ],
    },

    {
      id: 'StockYouCanSellCALLSAgainst',
      header: (
        <h6
          className={` px-4 py-1 text-center whitespace-nowrap text-ellipsis w-full ${getBgColor(
            'totalValueIfAssigned'
          )} text-xs font-medium border-l-[6px] border-gray-400   text-gray-500 uppercase tracking-wider`}
        >
          Stock You Can Sell CALLS Against
        </h6>
      ),
      columns: [
        {
          id: 'sellingCallStrikePrice',
          accessorKey: 'sellingCallStrikePrice',
          header: (
            <h6
              className={` px-4 py-1 text-center flex items-center gap-2 border-l-[6px] border-gray-400  whitespace-nowrap text-ellipsis w-full  text-xs font-medium text-gray-500 uppercase tracking-wider`}
            >
              Selling Call Strike Price{' '}
              <BiPencil className='w-4 h-4 text-blue-400' />
            </h6>
          ),
          cell: (info) => {
            return (
              <div className='border-l-[6px] border-gray-400  w-full'>
                <NumberCell
                  key={info.row.id}
                  updateData={updateData}
                  getValue={info.row.getValue}
                  row={info.row}
                  columnId={info.column.id}
                />
              </div>
            );
          },
          size: 100,
          maxSize: 150,
          minSize: 50,
          footer: (
            <div className='  flex flex-col border-l-[6px] border-gray-400 justify-start items-start '>
              <div className='  px-4 py-1 w-full flex justify-end items-center text-end h-8 font-medium '>
                {` `}
              </div>
            </div>
          ),
        },
        {
          id: 'sellingCallValue',
          accessorKey: 'sellingCallValue',
          header: (
            <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
              selling Call Value
            </h6>
          ),
          cell: (info) => (
            <span className='w-full px-4 py-1 flex items-center justify-end text-end'>
              {info.row.original.numberOfOptions &&
                info.row.getValue('shares') &&
                info.row.getValue('sellingCallStrikePrice')
                ? `$ ${formatNumber(
                  info.row.getValue('sellingCallStrikePrice') *
                  info.row.getValue('shares')
                )} `
                : ''}
            </span>
          ),
          size: 125,
          maxSize: 150,
          minSize: 100,
        },
        {
          id: 'PotentialProfit',
          accessorKey: 'PotentialProfit',
          header: (
            <h6
              className={`px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider ${getBgColor(
                'finalPL'
              )}`}
            >
              Potential Profit
            </h6>
          ),
          cell: (info) => {
            const sellingCallStrikePrice = info.row.getValue(
              'sellingCallStrikePrice'
            );
            const shares = info.row.getValue('shares');
            const assignedValue = info.row.getValue('assignedValue');
            if (
              sellingCallStrikePrice &&
              shares &&
              assignedValue !== undefined
            ) {
              const potentialProfit =
                sellingCallStrikePrice * shares - assignedValue;
              const isProfitable = potentialProfit >= 0;
              const formattedValue = isProfitable
                ? formatNumber(potentialProfit, 0)
                : formatNumber(-potentialProfit, 0);

              return (
                <span
                  className={`w-full px-4 py-1 flex items-center justify-end text-end ${isProfitable ? '' : 'text-red-500'
                    } font-bold`}
                >
                  {isProfitable
                    ? `$ ${formattedValue}`
                    : `($ ${formattedValue})`}
                </span>
              );
            } else {
              // Return a placeholder or empty span if required values are missing
              return (
                <span className='w-full px-4 py-1 flex items-center justify-end text-end'>
                  -
                </span>
              );
            }
          },
          footer: (info) => {
            const { potentialProfit } = footerSums;
            if (potentialProfit !== undefined) {
              const isProfitable = potentialProfit >= 0;
              const formattedValue = isProfitable
                ? formatNumber(potentialProfit, 0)
                : formatNumber(-potentialProfit, 0);

              return (
                <span
                  className={`w-full px-4 py-1 flex items-center justify-end text-end whitespace-nowrap ${isProfitable ? '' : 'text-red-500'
                    } font-bold`}
                >
                  {isProfitable
                    ? `$ ${formattedValue}`
                    : `$ (${formattedValue})`}
                </span>
              );
            } else {
              // Return a placeholder or empty span if the potentialProfit is undefined
              return (
                <span className='w-full px-4 py-1 flex items-center justify-end whitespace-nowrap text-end'>
                  -
                </span>
              );
            }
          },
          size: 125,
          maxSize: 150,
          minSize: 100,
        },
      ],
    },
  ]);
};
