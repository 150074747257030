export const guidedPortfolio = [
  // Highlight the entire table
  {
    // element: '#tableContainer', // Replace with the actual selector for the table
    popover: {
      title: 'Interactive Table',
      description:
        'Welcome to your PORTFOLIO screen!\n\nThis is your trade hub - it allows you to quickly and easily track the performance of your trades. The tool helps you make decisions on when to close trades and shows when you are getting close to exceeding your broker account limits.',
      popoverClass:
        'bg-white text-gray-700 border border-gray-300 shadow-lg max-w-xs rounded-lg p-4', // Example Tailwind CSS classes
      side: 'center', // or 'bottom' depending on the layout and what you want to emphasize
      align: 'center', // centers the popover with respect to the element
    },
  },
  // Step 1: Highlight the entire toolbar
  {
    element: '#toolbar', // Replace with the actual selector for the toolbar
    popover: {
      title: 'Toolbar Overview',
      description:
        'This toolbar contains all the tools you need to interact with the table below: searching, column visibility, decay levels, trade filters, adding new trades, and refreshing data.',
      side: 'bottom',
      align: 'start',
    },
  },
  // Step 2: Highlight the search bar within the toolbar
  {
    element: '#searchBar', // Replace with the actual selector for the search bar
    popover: {
      title: 'Search Bar',
      description: 'Use this to quickly find entries in the table by keyword.',
      side: 'bottom',
      align: 'start',
    },
  },
  // Step 3: Highlight the columns toggle button
  {
    element: '#columnsToggle', // Replace with the actual selector for the columns toggle button
    popover: {
      title: 'Columns Toggle',
      description: 'Select which columns you want to display in the table.',
      side: 'bottom',
      align: 'start',
    },
  },
  // Step 4: Highlight the decay levels toggle
  {
    element: '#decayToggle', // Replace with the actual selector for the decay levels dropdown
    popover: {
      title: 'Decay Levels',
      description:
        'Toggle the decay columns in the table to show or hide them.',
      side: 'bottom',
      align: 'start',
    },
  },
  // Step 5: Highlight the show trades filter
  {
    element: '#tradesFilter', // Replace with the actual selector for the trades filter
    popover: {
      title: 'Trade Filter',
      description:
        'Filter the trades displayed in the table based on their type.',
      side: 'bottom',
      align: 'start',
    },
  },
  // Step 6: Highlight the add new trade button
  {
    element: '#addTrade', // Replace with the actual selector for the add trade button
    popover: {
      title: 'Add New Trade',
      description: 'Click here to add a new trade entry to the table.',
      side: 'bottom',
      align: 'start',
    },
  },
  // Step 7: Highlight the refresh table button
  {
    element: '#refreshTable', // Replace with the actual selector for the refresh button
    popover: {
      title: 'Refresh Table',
      description:
        'Refresh the data in the table to see the most recent updates.',
      side: 'bottom',
      align: 'start',
    },
  },
  {
    element: '#youtubeTutorial', // Replace with the actual selector for the YouTube tutorial
    popover: {
      title: 'Video Guide',
      description: `Explore the features of your portfolio page with our video guide. This tutorial will walk you through each aspect of trade management, from tracking your performance to understanding key financial metrics. Discover how to leverage the tools at your disposal for effective trade analysis.`,
      side: 'top', // or 'bottom' depending on the layout and what you want to emphasize
      align: 'center', // centers the popover with respect to the element
    },
  },
  {
    element: '#showColorKeyMap', // The selector for the Color Key Map button
    popover: {
      title: 'Color Key Map',
      description: `Get a quick overview of what each color signifies in your trade table. This guide will help you understand the visual cues for trade status such as active, pending, expired, and closed trades. Click here to view the color explanations and make more informed decisions about your trades.`,
      position: 'bottom', // Position the popover below the button
      align: 'center', // Center the popover align with respect to the button
    },
  },
  {
    element: '#tableContainer', // Replace with the actual selector for the portfolio table
    popover: {
      title: 'Portfolio Performance',
      description:
        'This is your portfolio section, where you can keep track of your trades. You can view detailed performance metrics of your options here, including market value, assigned value, and your current profit or loss. Use this data to assess your portfolio health and make strategic decisions.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#tickerHeader', // Replace with the actual selector for the table
    popover: {
      title: 'column header',
      description:
        'This table is really dynamic allowing sorting, resizing columns, and edit the entries as needed.',
      side: 'top', // or 'bottom' depending on the layout and what you want to emphasize
      align: 'center', // centers the popover with respect to the element
    },
  },
  {
    element: '#premiumMarketPrice', // Replace with the actual selector for the table
    popover: {
      title: 'Premium Market Price',
      description: `Update the market price of the ticker's premium (shown on your broker's portal) here.`,
      side: 'top', // or 'bottom' depending on the layout and what you want to emphasize
      align: 'center', // centers the popover with respect to the element
    },
  },
  {
    element: '#currentPL', // Replace with the actual selector for the table
    popover: {
      title: 'Current P&L',
      description: `Track the profit or loss of your trade after updating the premium market price.Use the price decays sections to decide when to exit your trade.`,
      side: 'top', // or 'bottom' depending on the layout and what you want to emphasize
      align: 'center', // centers the popover with respect to the element
    },
  },
  {
    element: '#totalValueIfAssigned', //Replace with the actual selector for the table
    popover: {
      title: 'Payable IF all PUT positions assigned',
      description: `Keep track of how much you will need to pay if ALL of your active put trades are assigned to you.`,
      side: 'top', // or 'bottom' depending on the layout and what you want to emphasize
      align: 'center', // centers the popover with respect to the element
    },
  },
];

export const guidedWatchlist = [
  // Highlight the entire table
  {
    // element: '#tableContainer', // Replace with the actual selector for the watchlist table
    popover: {
      title: 'Watchlist Overview',
      description:
        'Welcome to your WATCHLIST screen!\n\nThis is where you can keep an eye on potential trades and monitor the market for opportunities. Use this tool to organize your watchlist items and make quick decisions on which stocks to focus on.',
      popoverClass:
        'bg-white text-gray-700 border border-gray-300 shadow-lg max-w-xs rounded-lg p-4',
      side: 'center',
      align: 'center',
    },
  },
  // Step 1: Highlight the toolbar
  {
    element: '#toolbar', // Use the actual selector for the toolbar
    popover: {
      title: 'Toolbar Features',
      description:
        'This toolbar includes all the functionalities you need to manage your watchlist: searching, managing visibility of columns, and more.',
      side: 'bottom',
      align: 'start',
    },
  },
  {
    element: '#searchBar', // Replace with the actual selector for the search bar
    popover: {
      title: 'Search Bar',
      description: 'Use this to quickly find entries in the table by keyword.',
      side: 'bottom',
      align: 'start',
    },
  },
  // Step 3: Highlight the columns toggle button
  {
    element: '#columnsToggle', // Replace with the actual selector for the columns toggle button
    popover: {
      title: 'Columns Toggle',
      description: 'Select which columns you want to display in the table.',
      side: 'bottom',
      align: 'start',
    },
  },
  {
    element: '#addTrade', // Replace with the actual selector for the add symbol button
    popover: {
      title: 'Add New Symbol',
      description: 'Click here to add a new symbol to your watchlist.',
      side: 'bottom',
      align: 'start',
    },
  },
  {
    element: '#refreshTable', // Replace with the actual selector for the refresh button
    popover: {
      title: 'Refresh Data',
      description:
        'Refresh your watchlist to see the latest stock prices and updates.',
      side: 'bottom',
      align: 'start',
    },
  },
  {
    element: '#youtubeTutorial', // Replace with the actual selector for the video tutorial
    popover: {
      title: 'Watchlist Tutorial',
      description:
        'Watch our detailed tutorial to learn how to maximize the use of your watchlist for effective trading strategies.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#showColorKeyMap', // Replace with the actual selector for the color key map
    popover: {
      title: 'Color Guide',
      description:
        'Understand the color coding of your watchlist for quick visual cues on market trends and stock statuses.',
      position: 'bottom',
      align: 'center',
    },
  },
  {
    element: '#tableContainer', // Replace with the actual selector for the symbol performance column
    popover: {
      title: 'Symbol Performance',
      description:
        'Monitor the performance metrics of each symbol in your watchlist here.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#tickerHeader', // Replace with the actual selector for the table
    popover: {
      title: 'column header',
      description:
        'This table is really dynamic allowing sorting, resizing columns, and edit the entries as needed.',
      side: 'top', // or 'bottom' depending on the layout and what you want to emphasize
      align: 'center', // centers the popover with respect to the element
    },
  },
];

export const guidedAssignedStocks = [
  // Highlight the entire table
  {
    // element: '#assignedStockTable', // Replace with the actual selector for the assigned stocks table
    popover: {
      title: 'Assigned Stocks Overview',
      description:
        'This is your ASSIGNED STOCKS screen. Here you can see all the stocks currently assigned to you as a result of option exercises. Use this area to assess the assigned stock’s performance and decide on your next move, whether it’s to hold, sell, or create a covered call.',
      popoverClass:
        'bg-white text-gray-700 border border-gray-300 shadow-lg max-w-xs rounded-lg p-4',
      side: 'center',
      align: 'center',
    },
  },
  // Step 1: Highlight the toolbar
  {
    element: '#toolbar', // Use the actual selector for the toolbar
    popover: {
      title: 'Toolbar Utilities',
      description:
        'The toolbar provides tools to search, filter, and sort through your assigned stocks for better management.',
      side: 'bottom',
      align: 'start',
    },
  },
  // Highlight the search bar
  {
    element: '#searchBar', // Replace with the actual selector for the search bar
    popover: {
      title: 'Search Assigned Stocks',
      description:
        'Quickly locate a specific assigned stock using the search feature.',
      side: 'bottom',
      align: 'start',
    },
  },
  {
    element: '#columnsToggle', // Replace with the actual selector for the columns toggle button
    popover: {
      title: 'Columns Toggle',
      description: 'Select which columns you want to display in the table.',
      side: 'bottom',
      align: 'start',
    },
  },

  // Highlight the refresh data button
  {
    element: '#refreshTable', // Replace with the actual selector for the refresh data button
    popover: {
      title: 'Refresh Data',
      description:
        'Keep your assigned stock information up-to-date with the latest market data by refreshing regularly.',
      side: 'bottom',
      align: 'start',
    },
  },
  // Highlight the tutorial link
  {
    element: '#youtubeTutorial', // Replace with the actual selector for the tutorial link
    popover: {
      title: 'Assigned Stock Tutorial',
      description:
        'Need help? Watch the tutorial to learn how to manage your assigned stocks effectively.',
      side: 'top',
      align: 'center',
    },
  },
  // Highlight the color key
  {
    element: '#showColorKeyMap', // Replace with the actual selector for the color key
    popover: {
      title: 'Visual Guide',
      description:
        'The color key helps you to visually differentiate stocks based on their status, such as profitable or at a loss.',
      position: 'bottom',
      align: 'center',
    },
  },
  {
    element: '#tableContainer', // Replace with the actual selector for the symbol performance column
    popover: {
      title: 'Assigned Stock Performance',
      description:
        'Here you can view and analyze the performance of your assigned stocks. This section provides insights into current market values, assigned values, and the profit/loss of each stock, helping you make informed decisions on your next steps.',
      side: 'top',
      align: 'center',
    },
  },
  {
    element: '#tickerHeader', // Replace with the actual selector for the table
    popover: {
      title: 'column header',
      description:
        'This table is really dynamic allowing sorting, resizing columns, and edit the entries as needed.',
      side: 'top', // or 'bottom' depending on the layout and what you want to emphasize
      align: 'center', // centers the popover with respect to the element
    },
  },
];
export const guidedHistory = [
  // Highlight the entire table
  {
    element: '#assignedStockTable', // Replace with the actual selector for the history table
    popover: {
      title: 'Trading History Overview',
      description:
        'This is your TRADING HISTORY screen. Here you can review all past trades and analyze your trading patterns over time. It is an essential tool for reflecting on your trading strategies and outcomes.',
      popoverClass:
        'bg-white text-gray-700 border border-gray-300 shadow-lg max-w-xs rounded-lg p-4',
      side: 'center',
      align: 'center',
    },
  },
  // Step 1: Highlight the toolbar
  {
    element: '#toolbar', // Replace with the actual selector for the toolbar
    popover: {
      title: 'Toolbar Functions',
      description:
        'The toolbar offers several functionalities to enhance your experience: search through history records, adjust column visibility, and sort data.',
      side: 'bottom',
      align: 'start',
    },
  },
  // Highlight the search bar
  {
    element: '#searchBar', // Replace with the actual selector for the search bar
    popover: {
      title: 'Search Your History',
      description:
        'Utilize the search function to find specific trades quickly.',
      side: 'bottom',
      align: 'start',
    },
  },
  // Step 3: Highlight the columns toggle button
  {
    element: '#columnsToggle', // Replace with the actual selector for the columns toggle button
    popover: {
      title: 'Customize Columns',
      description: 'Customize your view by choosing which columns to display.',
      side: 'bottom',
      align: 'start',
    },
  },
  // Step 4: Highlight the refresh data button
  {
    element: '#refreshTable', // Replace with the actual selector for the refresh button
    popover: {
      title: 'Update Historical Data',
      description:
        'Use this button to refresh and get the most recent updates to your trading history.',
      side: 'bottom',
      align: 'start',
    },
  },
  // Step 5: Highlight the tutorial link
  {
    element: '#youtubeTutorial', // Replace with the actual selector for the tutorial link
    popover: {
      title: 'Learn with Tutorials',
      description:
        'Click here for tutorials that provide detailed instructions and tips for using the history feature effectively.',
      side: 'top',
      align: 'center',
    },
  },
  // Step 6: Highlight the color key
  {
    element: '#showColorKeyMap', // Replace with the actual selector for the color key map
    popover: {
      title: 'Color Key Map',
      description:
        'The color key map is a visual aid for quickly understanding the status and results of your past trades.',
      position: 'bottom',
      align: 'center',
    },
  },
  // Step 7: Highlight the performance section
  {
    element: '#tableContainer', // Replace with the actual selector for the performance section
    popover: {
      title: 'Performance Insights',
      description:
        'This section displays the performance of your trades, helping you to assess your strategy’s effectiveness and make data-driven decisions.',
      side: 'top',
      align: 'center',
    },
  },
  // Step 8: Highlight the dynamic table functionality
  {
    element: '#tickerHeader', // Replace with the actual selector for the dynamic table functionality
    popover: {
      title: 'Interactive Table Features',
      description:
        'Explore the dynamic features of the table such as sorting, resizing columns, and editing entries for an optimized review of your trade history.',
      side: 'top',
      align: 'center',
    },
  },

  {
    element: '#drawer',
    popover: {
      title: 'Analytics Drawer',
      description:
        'Use this button to toggle the analytics drawer for additional insights and historical data analysis.',
      position: 'right', // Assuming the button is on the left and the drawer slides from the left
      align: 'center',
      onNextClick: (el) => {
        // Programmatically click the drawer toggle button to open the drawer
        document.querySelector('#drawerToggleButton').click();
      },
    },
  },

  // Step to highlight the overview inside the drawer
  {
    element: '#drawerOverview', // Replace with the actual selector for the overview section inside the drawer
    popover: {
      title: 'Overview Section',
      description:
        'This section gives you a summary of your trading history and an overview of your performance metrics.',
      position: 'right',
      align: 'top',
      onPrevClick: (el) => {
        // Programmatically click the drawer toggle button to open the drawer
        document.querySelector('#drawerToggleButton').click();
      },
    },
  },

  // Step to highlight the year dropdown inside the drawer
  {
    element: '#yearDropdown', // Replace with the actual selector for the year dropdown
    popover: {
      title: 'Year Selection',
      description:
        'Select the year to filter your trading history data within the drawer.',
      position: 'right',
      align: 'top',
    },
  },

  // Steps for each chart inside the drawer
  {
    element: '#monthly-pl-chart', // Replace with the actual selector for the monthly P&L chart
    popover: {
      title: 'Monthly P&L Chart',
      description:
        'This chart displays your profit and loss distribution on a monthly basis for the selected year.',
      position: 'right',
      align: 'top',
    },
  },
  {
    element: '#cumulative-pl-chart', // Replace with the actual selector for the cumulative P&L chart
    popover: {
      title: 'Cumulative P&L Chart',
      description:
        'Here you can see your cumulative profit and loss over the months for the selected year.',
      position: 'right',
      align: 'top',
    },
  },

  // Step to highlight the P&L summary table inside the drawer
  {
    element: '#plSummaryTable', // Replace with the actual selector for the P&L summary table
    popover: {
      title: 'P&L Summary Table',
      description:
        'This table summarizes your profit and loss, ROI, and other important metrics for each month.',
      position: 'right',
      align: 'top',
    },
  },
];

export const guidedDashboard = [
  {
    element: '#headerToggle', // Replace with actual selector for the header toggle button
    popover: {
      title: 'Header Toggle',
      description:
        'Click here to expand or collapse the main menu, giving you more space on your screen to view the details of your trades and other important information.',
      position: 'bottom',
    },
  },
  {
    element: '#timeZoneSelection', // Replace with actual selector for the time zone selection dropdown
    popover: {
      title: 'Time Zone Selection',
      description:
        'Adjust the displayed times on your dashboard according to your preferred time zone. Whether you’re local or tracking markets abroad, keep your times in sync.',
      position: 'bottom', // Adjust based on your layout and where there is space available
    },
  },
  {
    element: '#sidebarNavigation', // Replace with actual selector for the sidebar navigation
    popover: {
      title: 'Sidebar Navigation',
      description:
        'The sidebar lets you move between different sections of the application like Dashboard, Portfolio, Watch List, and more.',
      position: 'right',
    },
  },

  {
    element: '#dashboardWelcomeMessage', // Replace with the actual selector for the welcome message
    popover: {
      title: 'Welcome to Your Dashboard',
      description:
        'This introductory message provides a brief overview of the dashboard. As you add and close trades, or update your watchlist, this screen will reflect those changes, providing up-to-date insights into your portfolio’s performance.',
      position: 'left', // Adjust the position accordingly
    },
  },
  {
    element: '#pnlCard', // Replace with actual selector for the P&L card
    popover: {
      title: 'Profit & Loss',
      description:
        'This section shows your total profit or loss for the year to date. It’s an essential quick look at your trading performance.',
      position: 'bottom',
    },
  },
  {
    element: '#roiCard', // Replace with actual selector for the ROI card
    popover: {
      title: 'Return on Investment',
      description:
        'Here you can see the return on investment percentage for the year. It helps you measure the efficiency of your trades.',
      position: 'bottom',
    },
  },
  {
    element: '#tradesCounter', // Replace with actual selector for the trades counter
    popover: {
      title: 'Trades Counter',
      description:
        'This counter indicates the total number of trades you’ve made this year, giving you a sense of your activity level.',
      position: 'bottom',
    },
  },
  {
    element: '#buyingPower', // Selector for the buying power display
    popover: {
      title: 'Buying Power',
      description:
        'This is your current available buying power. It’s essential for planning your trades and managing your portfolio effectively.',
      position: 'left',
    },
  },
  {
    element: '#buyingPowerEdit', // Replace with actual selector for the edit pencil icon next to buying power
    popover: {
      title: 'Edit Buying Power',
      description:
        'Click this pencil icon to update your available buying power. Keeping this number up to date is important for tracking your investment capacity and planning new trades.',
      position: 'left', // or 'top', 'right', 'bottom', depending on the icon's position
    },
  },
  {
    element: '#activeTradesData', // Replace with the actual selector for the Active Trades Data section
    popover: {
      title: 'Active Trades Data',
      description:
        'This crucial part of your dashboard provides a comprehensive view of your active trades. It’s divided into Puts and Calls, each visually representing the current stock price in relation to the strike price, helping you track the performance of your options at a glance.',
      position: 'top', // Adjust the position based on actual UI layout
    },
  },
  {
    element: '#putsSection', // Replace with the actual selector for the Puts section
    popover: {
      title: 'Puts Overview',
      description:
        'The Puts section visualizes your put options. The green bars represent the current stock prices, and the red lines mark the strike prices. This helps you assess the status of your puts quickly.',
      position: 'left', // Adjust the position based on actual UI layout
    },
  },
  {
    element: '#callsSection', // Replace with the actual selector for the Calls section
    popover: {
      title: 'Calls Overview',
      description:
        'The Calls section similarly shows your call options with brown bars for current stock prices and red lines for strike prices. Monitor your calls to determine the right action to take as market conditions change.',
      position: 'left', // Adjust the position based on actual UI layout
    },
  },
  {
    element: '#assignedStock', // Replace with actual selector for assigned stock section
    popover: {
      title: 'Assigned Stock',
      description:
        'This section lists the stocks that have been assigned to you, along with their current value and profit or loss.',
      position: 'left',
    },
  },
  {
    element: '#watchList', // Replace with actual selector for watch list section
    popover: {
      title: 'Watch List',
      description:
        'Keep an eye on potential trades! Add stocks to your watch list and track their price and performance.',
      position: 'left',
    },
  },
  {
    element: '#cumulativePnLChart', // Replace with actual selector for cumulative P&L chart
    popover: {
      title: 'Cumulative P&L Chart',
      description:
        'Visualize your profit and loss over time with this chart. It helps you understand your trading results in a graphical representation.',
      position: 'top',
    },
  },
  {
    element: '#activeTradesBySector', // Replace with actual selector for trades by sector chart
    popover: {
      title: 'Active Trades by Market Sector',
      description:
        'This pie chart breaks down your active trades by market sector, allowing you to see your portfolio’s diversification at a glance.',
      position: 'top',
    },
  },
  {
    element: '#activeTradesByIndustry', // Replace with actual selector for trades by industry chart
    popover: {
      title: 'Active Trades by Market Industry',
      description:
        'Understand the spread of your trades across different industries with this bar chart.',
      position: 'top',
    },
  },
];

export const guidedWheelIncome = [
  {
    element: '#wheelIncomeTable', // replace with the actual selector for the Wheel Income table
    popover: {
      title: 'Wheel Income Calculator',
      description: `This table is your strategic planner for options trading. Use it to gauge how much of your capital may be tied up if your puts are assigned, ensuring you stay within safe financial bounds before initiating any trades. 
        You can also see the returns you are likely to earn on each option trade, allowing you to compare different trades to help you choose the most profitable.`,
      position: 'top',
    },
  },
  {
    element: '#toolbar', // Replace with the actual selector for the toolbar
    popover: {
      title: 'Efficient Toolbar',
      description:
        'Navigate with ease using this toolbar. Search for trades, modify the visibility of columns, and swiftly switch between the Selling Puts and Selling Calls views.',
      side: 'bottom',
      align: 'start',
    },
  },
  {
    element: '#sellingPutsAndCallsTabs', // replace with the actual selector for the tabs
    popover: {
      title: 'Trade Types',
      description:
        'Quickly toggle between viewing your Puts and Calls to manage your options effectively.',
      position: 'bottom',
    },
  },
  {
    element: '#addTrade', // replace with the actual selector for the 'Add new trade' button
    popover: {
      title: 'Add Options',
      description:
        'Eager to increase your trades? Hit this button to see how adding new Puts or Calls to your strategy affects your position.',
      position: 'left',
    },
  },

  {
    element: '#cashRequirementsSection', // replace with the actual selector for the cash requirements section
    popover: {
      title: 'Monitor Your Capital',
      description:
        'Here you can monitor the required capital against your trades. Stay informed about the cash needed to fulfill potential assignments and manage your capital efficiently.',
      position: 'top',
    },
  },
  {
    element: '#buyingPowerInput', // replace with the actual selector for the buying power input
    popover: {
      title: 'Check Your Buying Power',
      description:
        'Experiment with your buying power input here. A vital feature allowing you to test different capital scenarios safely.',
      position: 'left',
    },
  },
  {
    element: '#resetBuyingPowerButton', // replace with the actual selector for the reset buying power button
    popover: {
      title: 'Quick Reset',
      description:
        'Made changes? One click here and your buying power is back to its original figure. No refresh needed.',
      position: 'left',
    },
  },
  // Additional steps can be added here if there are more interactive elements specific to the Wheel Income screen as indicated in the screenshot.
];

export const guidedBreakEven = [
  {
    element: '#breakEvenTable', // replace with the actual selector for the Break Even table
    popover: {
      title: 'Break Even Table',
      description: `This table helps you track your options trades and calculate the break-even point. It's crucial for ensuring your trading strategy remains profitable.`,
      position: 'top',
    },
  },
  {
    element: '#toolbar', // Replace with the actual selector for the toolbar
    popover: {
      title: 'Toolbar',
      description:
        'Use this toolbar to search for specific trades, manage column visibility, and perform various other actions.',
      position: 'bottom',
    },
  },
  {
    element: '#operationControls', // replace with the actual selector for the operation controls
    popover: {
      title: 'Operation Controls',
      description:
        'Use these controls to add a new operation, save changes, or cancel an operation. This helps in managing your trades effectively.',
      position: 'left',
    },
  },
  
  {
    element: '#deleteTradeButton', // replace with the actual selector for the delete trade button
    popover: {
      title: 'Delete Trade',
      description:
        'Use this button to delete an existing trade along with all its associated operations. Be cautious, as this action is irreversible.',
      position: 'left',
    },
  },

  {
    element: '#expendAllGroups', // replace with the actual selector for the expand/collapse button
    popover: {
      title: 'Expand/Collapse',
      description:
        'Expand or collapse rows to get a detailed view of each trade and its operations. This feature helps in better data visualization.',
      position: 'left',
    },
  },
  // Additional steps can be added here if there are more interactive elements specific to the Break Even page.
];
