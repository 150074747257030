import { useEffect, useState } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { MdExitToApp, MdKeyboardArrowDown, MdLogout } from 'react-icons/md';
import { useAuth } from '../context/auth';
import { useStateCtx } from '../context/appContext';
import UserNavDropDown from './UserNavDropDown';
import Clock from './Clock';
const NavButton = ({ title, customFunc, icon, color, dotColor }) => {
  return (
    <button
      type='button'
      style={{ color: color }}
      className={`relative text-3xl font-bold rounded-full p-3 hover:bg-light-gray`}
      onClick={customFunc}
    >
      {title !== 'Menu' && (
        <span
          style={{ backgroundColor: color }}
          className={`absolute inline-flex rounded-full h-2 w-2 right-2 top-2`}
        />
      )}
      {icon}
    </button>
  );
};

const Navbar = () => {
  const {
    activeMenu,
    setActiveMenu,
    isClicked,
    handleClick,
    screenSize,
    setScreenSize,
    currentColor,
  } = useStateCtx();
  const { user } = useAuth();

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [setScreenSize]);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize, setActiveMenu]);

  const handleActiveMenu = () => setActiveMenu(!activeMenu);
  return (
    <nav  className='flex justify-between items-center p-2 relative z-10 shadow-md bg-light-background bg-opacity-90 dark:bg-dark-background-90  dark:bg-opacity-90'>
      <NavButton
        
        title='Menu'
        customFunc={handleActiveMenu}
        color={currentColor}
        icon={<AiOutlineMenu  id='headerToggle' />}
      />
      <Clock />
      {/* add the date in the format of : 12 OCT 2022 */}

      <div className='flex items-center'>
        <div
          onClick={() => handleClick('userProfile')}
          className='hidden lg:flex items-center  gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg'
        >
          <p>
            <span className='text-gray-700 flex items-center gap-2'>
              <span className='font-semibold'>
                Hi,{` `}
                {user.user_display_name}
              </span>
            </span>
          </p>
          <MdKeyboardArrowDown
            style={{
              color: currentColor,
            }}
            className='w-7 h-7'
          />
        </div>
        <MdLogout
          onClick={() => handleClick('userProfile')}
          style={{
            color: currentColor,
          }}
          className='w-7 h-7  block lg:hidden'
        />
        <div className='relative bottom-0'>
          {isClicked.userProfile && <UserNavDropDown />}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
