import axios from 'axios';
import { toast } from 'react-toastify';

export const fetchWatchList = async (token) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/watchlist`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data;
  } catch (error) {
    toast.error('Error fetching trades');
    throw error;
  }
};
export const fetchWatchListById = async (id, token) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/watchlist/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    toast.error('Error fetching trade');
    throw error;
  }
};
export const addToWatchList = async (tickerData, token) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/watchlist`,
      tickerData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    toast.success('Ticker added to watchList successfully');
    return response.data;
  } catch (error) {
    console.error(error);
    toast.error(error.response?.data?.message || 'An error occurred');
    throw error;
  }
};

export const updateWatchCellByRowId = async ({
  rowId,
  columnId,
  newValue,
  token,
}) => {
  try {
    const payload = { ticker: newValue };

    // Make an HTTP request to update the specific cell within the trade identified by rowId
    const response = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/watchlist/${rowId}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    toast.success('Cell updated successfully');
    return response.data.trade;
  } catch (error) {
    toast.error('Error updating cell');
    throw error;
  }
};

export const deleteWatchListById = async (id, token) => {
  try {
    await axios.delete(`${process.env.REACT_APP_BASE_URL}/watchlist/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    toast.success('Trade deleted successfully');
  } catch (error) {
    toast.error('Error deleting trade');
    throw error;
  }
};

export const deleteAllTrades = async () => {
  try {
    await axios.delete(`${process.env.REACT_APP_BASE_URL}/trades`);
    toast.success('All trades deleted successfully');
  } catch (error) {
    toast.error('Error deleting all trades');
    throw error;
  }
};
