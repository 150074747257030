import React, { useEffect, useState } from 'react';
import { MdChevronRight, MdClose } from 'react-icons/md';
import TradeHistory from './Dashboard/TradeHistory';
import ROITable from './ROITable';
import { eachYearOfInterval, endOfYear, parseISO, startOfYear } from 'date-fns';
import { fetchClosedTrades } from '../services/historyTradesSevices';
import { fetchCapitalByDateRange } from '../services/capitalServices';
import { calculateData } from '../utils/mathHelpers';
import { useAuth } from '../context/auth';
import PLCharts from './Dashboard/CumulativePLChart';

const HistoryChartsDrawer = ({ isOpenDrawer, setIsOpenDrawer }) => {
  const { token, dateRange } = useAuth();
  const startDate = parseISO(dateRange?.startDate);
  const endDate = parseISO(dateRange?.endDate);
  const [localDateRange, setLocalDateRange] = useState({
    startDate: startOfYear(startDate),
    endDate: endOfYear(endDate),
  });

  const [data, setData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(endDate.getFullYear());

  const [yearlyCapital, setYearlyCapital] = useState(0);
  useEffect(() => {
    // When dateRange changes, update selectedYear accordingly
    setSelectedYear(endDate.getFullYear());
    setLocalDateRange({
      startDate: startOfYear(startDate),
      endDate: endOfYear(endDate),
    });
  }, [dateRange, isOpenDrawer]);

  // Handle changes in the year dropdown
  const handleYearChange = (event) => {
    const year = parseInt(event.target.value);
    setSelectedYear(year);
    // Update dateRange based on the selected year
    setLocalDateRange({
      startDate: startOfYear(new Date(year, 0)),
      endDate: endOfYear(new Date(year, 11)),
    });
  };
  const toggleDrawer = () => {
    setIsOpenDrawer(!isOpenDrawer);
  };
  useEffect(() => {
    fetchData();
  }, [token, selectedYear, dateRange, isOpenDrawer]); // Ensuring fetchData runs when needed.

  const currentYear = new Date().getFullYear();
  const yearOptions = eachYearOfInterval({
    start: new Date(currentYear - 10, 0),
    end: new Date(),
  }).sort((a, b) => b - a);
  const fetchData = async () => {
    try {
      const tradesData = await fetchClosedTrades(token, localDateRange);
      const capitalsData = await fetchCapitalByDateRange(
        localDateRange.startDate,
        localDateRange.endDate,
        token
      );
      const calculatedData = calculateData(tradesData, capitalsData);
      setData(calculatedData);

      // Update yearly capital calculation
      if (capitalsData.length > 0 && capitalsData[0]?.capitals) {
        const yearlyCapital = Object.values(capitalsData[0].capitals).reduce(
          (acc, curr) => acc + curr,
          0
        );
        setYearlyCapital(yearlyCapital);
      } else {
        setYearlyCapital(0);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleCapitalUpdate = async () => {
    // Simply call fetchData to refresh data based on the current localDateRange
    await fetchData();
  };

  return (
    <>
      <div
        id='drawerOverview'
        className={`fixed inset-y-0 right-0 w-full  lg:w-3/5  bg-light-background shadow-xl z-50 border-l-2 border-blue-600 rounded-l-xl transform transition-transform duration-300 ease-in-out ${
          isOpenDrawer ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        {/* Button to close the drawer */}
        <button
          onClick={toggleDrawer}
          className='absolute top-4 right-4 text-gray-600 hover:text-gray-800 transition-colors z-10'
        >
          <MdClose size={24} />
        </button>
        {/* Button to open/close the drawer */}
        <button
          onClick={toggleDrawer}
          className={`absolute top-1/2 -left-6 -translate-y-1/2 transform bg-blue-600 hover:bg-blue-700 text-white py-10 rounded-l-xl focus:outline-none transition-transform duration-300 ease-in-out z-50`}
          aria-label='Toggle Drawer'
          id='drawerToggleButton'
        >
          <MdChevronRight
            className={`transform ${isOpenDrawer ? '' : 'rotate-180'}`}
            size={24}
          />
        </button>
        {/* Content of the drawer */}
        <div className='p-4 overflow-y-auto h-full'>
          <div className='mb-4'>
            <h2 className='text-xl font-semibold text-gray-800 mb-4'>
              Trading Analytics
            </h2>
            <label
              htmlFor='year-select'
              className='block text-sm font-medium text-gray-700 mb-1'
            >
              Select Year:
            </label>
            <select
              id='yearDropdown'
              className='block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md'
              value={selectedYear}
              onChange={handleYearChange}
            >
              {yearOptions.map((year) => (
                <option key={year.getFullYear()} value={year.getFullYear()}>
                  {year.getFullYear()}
                </option>
              ))}
            </select>
          </div>

          {/* Placeholders for charts */}
          <TradeHistory
            data={data}
            handleCapitalUpdate={handleCapitalUpdate}
            yearlyCapital={yearlyCapital}
            year={selectedYear}
          />

          <div className='mb-8 grid grid-cols-1 lg:grid-cols-2 gap-2'>
            <div className='bg-white  p-4 rounded-lg shadow-lg overflow-hidden hover:bg-opacity-60 transition duration-300 ease-in-out '>
              <PLCharts
                title={`Monthly P&L with Number of Trades (${
                  selectedYear ? selectedYear : 'YTD'
                })`}
                id='monthly-pl-chart'
                data={data}
                gradientColors={{
                  bar: { start: '#ff9f40', end: '#ff6f00' },
                  line: { start: '#14b8a6', end: '#22c55e' },
                }}
              />
            </div>

            <div className='bg-white  p-4 rounded-lg shadow-lg overflow-hidden hover:bg-opacity-60 transition duration-300 ease-in-out '>
              <PLCharts
                title={`Cumulative P&L with Monthly Number of Trades (${
                  selectedYear ? selectedYear : 'YTD'
                })`}
                id='cumulative-pl-chart'
                data={data}
                gradientColors={{
                  bar: { start: '#51a0ff', end: '#217aff' },
                  line: { start: '#14b8a6', end: '#22c55e' },
                }}
              />
            </div>
          </div>
          {/* Placeholder for the table */}
          <div className='bg-white  p-4 rounded-lg shadow-lg overflow-hidden hover:bg-opacity-60 transition duration-300 ease-in-out '>
            <ROITable
              year={selectedYear}
              setDateRange={setLocalDateRange}
              setData={setData}
              data={data}
              dateRange={localDateRange}
            />
          </div>
        </div>
      </div>
      {/* Overlay to close the drawer */}
      {isOpenDrawer && (
        <div
          className='fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300 z-[45]'
          onClick={toggleDrawer}
        ></div>
      )}
    </>
  );
};

export default HistoryChartsDrawer;
