import React from 'react';
import Modal from 'react-modal';

// Custom styles for the modal
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    padding: '20px',
    animation: 'fadeIn 0.5s'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 1000,
    animation: 'fadeInOverlay 0.5s'
  }
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

const ColorExplanationItem = ({ color, title, description }) => (
  <div className="color-explanation-item flex items-center gap-2 mb-4 p-2">
    <span className={`h-8 w-8 rounded-full`} style={{ backgroundColor: color }}></span>
    <div>
      <div className="font-semibold">{title}</div>
      <div className="text-sm">{description}</div>
    </div>
  </div>
);

const ColorsKeyMapModal = ({ isOpen, onRequestClose }) => {
  const colorMappings = [
    {
      categoryTitle: "Trade Status",
      categorySubtitle: "Colors based on the trade's current status.",
      colorExplanations: [
        {
          status: 'closed',
          color: '#808080', // Gray
          title: 'Closed Trade',
          description: 'All closing cells are filled, indicating the trade is closed.'
        },
        {
          status: 'active',
          color: '#FFFFFF', // White (used instead of Green)
          title: 'Active Trade',
          description: 'Trade details are complete and the trade date has been reached.'
        },
        {
          status: 'pending',
          color: '#FFEB3B', // Yellow
          title: 'Pending Trade',
          description: 'Certain details need to be filled to activate the trade.'
        },
        // ... other statuses if there are more
      ]
    },
    {
      categoryTitle: "Expiration Date",
      categorySubtitle: "Colors based on the proximity of the expiration date.",
      colorExplanations: [
        {
          status: 'expired',
          color: '#A9A9A9', // Dark Gray
          title: 'Expired',
          description: 'The expiration date has passed.'
        },
        {
          status: 'expiring_soon',
          color: '#FFA500', // Orange
          title: 'Expiring Soon',
          description: 'Less than 7 days remain until expiration.'
        },
        {
          status: 'expires_today',
          color: '#FF4500', // Red
          title: 'Expires Today',
          description: 'The trade expires today.'
        },
        // ... other expiration related statuses if there are more
      ]
    },
    // ... other categories if there are more
  ];


  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Color Key Map"
    >
      <div className="flex flex-col">
        <div className="flex items-center justify-between">
          <h2 className="text-center text-2xl font-bold mb-4">Color Key Map</h2>
          <button
            onClick={onRequestClose}
            className="self-start text-sm  bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          >
            Close
          </button>
        </div>
        {colorMappings.map((category, catIndex) => (
          <div key={catIndex} className="mb-5 border p-4 rounded-lg">
            <h3 className="text-lg font-semibold mb-2">{category.categoryTitle}</h3>
            <h4 className="font-normal mb-3">{category.categorySubtitle}</h4>
            <div className="flex flex-row flex-wrap">
              {category.colorExplanations.map((item, index) => (
                <div key={index} className="flex items-center m-2">
                  <div
                    style={{ backgroundColor: item.color }}
                    className="w-5 h-5 mr-2 border border-gray-300 rounded"
                  />
                  <div>
                    <strong className="font-medium">{item.title}</strong>
                    <p className="text-sm text-gray-600">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

    </Modal>
  );
};

export default ColorsKeyMapModal;
