import React from 'react';
import { AiOutlineAppstore, AiOutlineSearch, AiOutlineUnorderedList } from 'react-icons/ai';
import { BiColumns } from 'react-icons/bi';
import { ContextMenu, SearchCell } from '../../utils/tableHelpers';
import { useAuth } from '../../context/auth';
import { MdExpand } from 'react-icons/md';
import { BsArrowsCollapse, BsArrowsExpand } from 'react-icons/bs';

const SearchAndColumnToggle = ({ globalFilter, setGlobalFilter, contextMenuState, setContextMenuState, table, page }) => {
  const { toggleAllMonthGroups, expanded } = useAuth()
  const areAllGroupsExpanded = Object.keys(expanded).length === 12 && Object.values(expanded).every(value => value);

  return (
    <div className="flex items-center space-x-4 lg:mb-0">
      <div id='searchBar' className='flex gap-1 items-center'>
        <AiOutlineSearch className="text-gray-800 text-lg transition-all duration-200 ease-in-out transform hover:scale-110" />
        <SearchCell
          value={globalFilter}
          onChange={(value) => setGlobalFilter(String(value))}
          className="p-2 w-full lg:w-auto rounded-lg outline-none font-lg shadow-sm transition-all duration-200 ease-in-out transform hover:scale-105"
          placeholder="Search..."
        />
      </div>
      <button
        id='columnsToggle'
        type="button"
        className="text-gray-800 bg-gray-300 p-2 rounded-lg outline-none shadow-sm transition-all duration-200 ease-in-out transform hover:scale-105"
        onClick={() => setContextMenuState((prev) => !prev)}
      >
        <BiColumns className="w-4 h-4 transition-all duration-200 ease-in-out transform hover:rotate-180" />
      </button>
      <ContextMenu
        isVisible={contextMenuState}
        onClose={() => setContextMenuState(false)}
        allColumnsVisible={table.getIsAllColumnsVisible()}
        toggleAllColumnsVisibility={table.getToggleAllColumnsVisibilityHandler()}
        columns={table.getAllColumns()}
      />
      {(page === 'history' || page === 'BreakEven') &&
        <button
          id='expendAllGroups'
          type="button"
          className="text-gray-800 bg-gray-300 p-2 rounded-lg outline-none shadow-sm transition-all duration-200 ease-in-out transform hover:scale-105"
          onClick={toggleAllMonthGroups}
        >
          {areAllGroupsExpanded ? <BsArrowsCollapse className="w-4 h-4" /> : <BsArrowsExpand className="w-4 h-4" />}
        </button>
      }
    </div>
  );
};

export default SearchAndColumnToggle;
