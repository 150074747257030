import { getTradeStatus } from './tableHelpers';

export const getBgColor = (columnId) => {
  const columnColors = {
    // 'Options': 'bg-[#E1F7FF]',
    TradeDetails: 'bg-[#FCE4E8]',
    premiumDecay: 'bg-yellow-200',
    CloseTradesDetails: 'bg-[#E1F7FF]',
    Fundamentals: 'bg-purple-200',
    // Sub-columns for 'Options'
    // 'delete': 'bg-[#E1F7FF]',
    // 'color': 'bg-[#E1F7FF]',
    // 'putCall': 'bg-[#E1F7FF]',
    // 'ticker': 'bg-[#E1F7FF]',
    // 'name': 'bg-[#E1F7FF]',
    // Sub-columns for 'TradeDetails'
    tradeDate: 'bg-[#E1F7FF]',
    expirationDate: 'bg-[#E1F7FF]',
    strike: 'bg-[#E1F7FF]',
    tradeType: 'bg-[#E1F7FF]',
    premium: 'bg-[#E1F7FF]',
    numberOfOptions: 'bg-[#E1F7FF]',
    totalValueIfAssigned: 'bg-[#ECDFF5]',
    premiumMarketPrice: 'bg-[#FFF2CC]',
    closingDate: 'bg-[#E1F7FF]',
    closingTradeType: 'bg-[#E1F7FF]',
    closingPremium: 'bg-[#E1F7FF]',
    finalPL: 'bg-[#E3F2D9]',
    WheelIncomeName: 'bg-green-100',
    WheelTicker: 'bg-green-200',
    WheelCurrentPrice: 'bg-green-100',
    WheelSector: 'bg-green-100',
    WheelIndustry: 'bg-green-100',
    optionStrikePrice: 'bg-green-100',
    dropPercent: 'bg-green-100',
    WheelOptionPremium: 'bg-green-100',
    dateSoldOption: 'bg-green-100',
    WheelExpDate: 'bg-green-100',
    wheelDaysToExpiration: 'bg-green-100',
    optionsToTrade: 'bg-green-100',
    PremiumCollected: 'bg-green-100',
    PremiumPerDay: 'bg-green-200',
    editableCell: 'bg-yellow-300',
    neededToBuyStock: 'bg-red-300',
    PremiumAnnualized: 'bg-green-100',
    // ====================== //
    WheelIncomeCallCompanyName: 'bg-red-100',
    WheelIncomeCallTicker: 'bg-red-200',
    WheelIncomeCallCurrentPrice: 'bg-red-100',
    WheelIncomeCallSector: 'bg-red-100',
    WheelIncomeCallIndustry: 'bg-red-100',
    WheelIncomeCallPriceStockWasBought: 'bg-red-100',
    WheelIncomeCallNumberOfShares: 'bg-red-100',
    WheelIncomeCallOptionStrikePrice: 'bg-red-100',
    WheelIncomeCallRisePercent: 'bg-red-100',
    WheelIncomeCallOptionPremium: 'bg-red-100',
    WheelIncomeCallDateSoldOption: 'bg-red-100',
    WheelIncomeCallExpDate: 'bg-red-100',
    WheelIncomeCallDaysToExpiration: 'bg-red-100',
    WheelIncomeCallOptionsToTrade: 'bg-red-100',
    WheelIncomeCallPremiumCollected: 'bg-red-100',
    WheelIncomeCallPremiumPerDay: 'bg-green-300',
    // WheelIncomeCallEditableCell: 'bg-red-300',
    WheelIncomeCallValueAtStrike: 'bg-red-100',
    WheelIncomeCallPremiumAnnualized: 'bg-red-100',
    WheelIncomeCallTotalGained: 'bg-green-100',
    WheelIncomeCallStockGainsIfExercised: 'bg-green-100',

    // Sub-columns for 'Fundamentals'
    breakEvenFinalPL: 'bg-yellow-300',
    breakEvenType: 'bg-yellow-300',
    // 'marketCap': 'bg-purple-300',
    // 'peRatio': 'bg-purple-300',
    // 'employees': 'bg-purple-300',
    // 'averageVolume': 'bg-purple-300'
  };
  return columnColors[columnId] || '';
};

export const getFontColor = (columnId, tradeType) => {
  if (columnId === 'putCall') {
    return tradeType === 'put'
      ? 'text-[#006100] font-bold'
      : tradeType === 'call'
      ? 'text-[#9C0006] font-bold'
      : 'text-[#D8BFD8] font-bold';
  }
  return '';
};

export const getTickerStyles = (page, columnId, row) => {
  if (page === 'portfolio') {
    const { value: tradeStatus } = getTradeStatus(row); // Get trade status

    if (tradeStatus === 'pending') {
      return 'bg-yellow-300'; // Apply yellow background for pending trades
    }
    return getBgColor(columnId); // Apply color based on columnId
  } else if (page === 'wheelIncome') {
    // Define styles for 'history' page
    return getBgColor('editableCell'); // Replace with actual styles for the history page
  }
  // Default style for other pages
  return 'default-styles'; // Generic default styling
};
// Style helper function
export const getDropdownStyles = (
  page,
  columnId,
  value,
  tradeStatus = null
) => {
  if (page === 'portfolio') {
    if (
      tradeStatus &&
      tradeStatus !== 'closed' &&
      ['tradeType', 'closingTradeType', 'putCall'].includes(columnId)
    ) {
      if (columnId === 'putCall') {
        return value === 'put'
          ? 'bg-[#E3F2D9]'
          : value === 'call'
          ? 'bg-[#FFC7CE]'
          : 'bg-[]';
      }
      return getBgColor(columnId); // Assuming getBgColor is defined elsewhere
    }
    return 'bg-transparent';
  } else if (page === 'history') {
    if (columnId === 'putCall') {
      return value === 'put'
        ? 'bg-[#E3F2D9]'
        : value === 'call'
        ? 'bg-[#FFC7CE]'
        : 'bg-[#6A0DAD]';
    }
    // Define styles specific to 'history' page
    // Example: return different styles or simply 'bg-transparent' if no specific styling is needed
    return getBgColor(columnId); // Replace with actual styles for the history page
  }
  // Default style or more conditions for other pages
  return 'default-styles';
};

// stylingHelpers.js
export const getDateCellStyles = (columnId, row, page) => {
  if (page === 'portfolio') {
    const { value: tradeStatus } = getTradeStatus(row); // Get trade status for the portfolio page

    if (
      tradeStatus === 'pending' &&
      ['tradeDate', 'expirationDate'].includes(columnId)
    ) {
      return 'bg-yellow-300 border-r-gray-200';
    } else if (
      tradeStatus === 'active' &&
      ['tradeDate', 'expirationDate', 'closingDate'].includes(columnId)
    ) {
      return getBgColor(columnId);
    } else if (tradeStatus !== 'closed' && ['closingDate'].includes(columnId)) {
      return getBgColor(columnId);
    }
  }
  // Add more conditions for other pages or return a default style
  return ''; // Default style
};

export const getFinalPLStyles = (page, columnId, row) => {
  // Only apply special styling if we're on the portfolio page
  if (page === 'portfolio') {
    const { value: tradeStatus } = getTradeStatus(row); // Get trade status

    // Apply styles based on trade status
    // if (tradeStatus === 'pending') {
    //   return 'bg-yellow-300'; // Apply yellow background for pending trades
    // } else if (tradeStatus === 'active') {
    //   return getBgColor(columnId); // Apply color based on columnId for active trades
    // } else if (tradeStatus === 'closed') {
    //   // For closed trades, you may want a different style or the default finalPL color
    // }
    return getBgColor(columnId);
  } else if (page === 'history') {
    // Apply history page specific styles, or reuse the portfolio logic if appropriate
    // This is where you would handle the history page's specific style logic
  }
  // Return the default finalPL background color if no specific conditions are met
  return getBgColor(columnId);
};
